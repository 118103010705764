import * as Yup from "yup";

//all forms validations

// validation for sign up form
const SignUpValidation = Yup.object().shape(
  {
    username: Yup.string().required("Username is required"),

    email: Yup.string()
      .required("Email is required")
      .email("Email is invalid")
      .matches(
        /^[^\s+]+@[^\s+]+\.[^\s+]+$/,
        "Email cannot contain a plus sign",
      ),
    pass: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
    confirmPass: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("pass"), null], "Confirm Password does not match"),
    terms: Yup.boolean().oneOf([true], "Please accept terms and conditions"),
    // .required('Please accept terms and conditions')
    referralValue: Yup.string()
      .nullable()
      .notRequired()
      .when("referralValue", {
        is: (value) => value?.length,
        then: (rule) =>
          rule
            .min(10, "Minimum length is 10 characters")
            .max(10, "Maximum length is 10 characters")
            .matches(
              /^[A-Z0-9]+$/,
              "Only uppercase letters and numbers are allowed",
            ),
      }),
  },
  [
    // Add cyclic deps here because when requires itself
    ["referralValue", "referralValue"],
  ],
);

// signup validation in mini app
const miniAppSignUpValidation = Yup.object().shape(
  {
    email: Yup.string()
      .required("Email is required")
      .email("Email is invalid")
      .matches(
        /^[^\s+]+@[^\s+]+\.[^\s+]+$/,
        "Email cannot contain a plus sign",
      ),
    pass: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(
        /[@$!%*?&#]/,
        "Password must contain at least one special character",
      ),
    confirmPass: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("pass"), null], "Confirm Password does not match"),
    terms: Yup.boolean().oneOf([true], "Please accept terms and conditions"),
    referralValue: Yup.string()
      .nullable()
      .notRequired()
      .when("referralValue", {
        is: (value) => value?.length,
        then: (rule) =>
          rule
            .min(10, "Minimum length is 10 characters")
            .max(10, "Maximum length is 10 characters")
            .matches(
              /^[A-Z0-9]+$/,
              "Only uppercase letters and numbers are allowed",
            ),
      }),
  },
  [["referralValue", "referralValue"]],
);

// validation for login form
const logInValidation = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),

  pass: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  // recaptcha: Yup.string()
  //     .required('recaptcha is required')
});

// validation for confirmation code form
const confirmationCodeValidation = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),

  confirmationCode: Yup.string().required("Confirmation code is required"),
});

const miniAppConfirmationCodeValidation = Yup.object().shape({
  confirmationCode: Yup.string().required("Confirmation code is required"),
});

// validation for otp step 1
const otpEmailValidation = Yup.object().shape({
  email: Yup.string()
    .required("Username is required")
    .email("Email is invalid"),
});

// validation for otp step 2
const otpCodeValidation = Yup.object().shape({
  code: Yup.string()
    .required("Code is required")
    .matches(/^\d{6}$/, "Code must be exactly 6 digits"),
});

// validation for forgot password step1 form
const ForgotPasswordStep1Validation = Yup.object().shape({
  email: Yup.string()
    .required("Username is required")
    .email("Email is invalid"),
});

// validation for forgot password step2 form
const ForgotPasswordStep2Validation = Yup.object().shape({
  pass: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  confirmPass: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("pass"), null], "Confirm Password does not match"),
  confirmationCode: Yup.string().required("Confirmation code is required"),
});

//export all
export {
  SignUpValidation,
  logInValidation,
  confirmationCodeValidation,
  ForgotPasswordStep1Validation,
  ForgotPasswordStep2Validation,
  miniAppSignUpValidation,
  miniAppConfirmationCodeValidation,
  otpEmailValidation,
  otpCodeValidation,
};
