import { Modal, Spin, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import FeatureListItem from "./features-modal/FeatureListItem";
import styles from "../../styles/Components/new-insight/FeatureCategoriesModal.module.css";
import { FiInfo } from "react-icons/fi";
import { categoryTooltip } from "../../utility/data";
import { IoIosArrowBack } from "react-icons/io";
import { HiLockClosed } from "react-icons/hi";
import { useNavigate } from "react-router";
import { insightProFeatureWarning, isUnique } from "../../utility/helper";
import { updateSelectedFeaturesList } from "../../redux/new-insight/actions";
import { FaCaretRight } from "react-icons/fa";

function FeatureCategoriesModal({ show, closeHandler, type }) {
  const {
    newInsightState: {
      categories,
      categoriesFeatureTree,
      selectedFeatures,
      getInitialDataLoading,
    },
    profile: { profileData },
  } = useSelector((state) => state);
  const [step, setStep] = useState(1);
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [feature, setFeature] = useState("");
  const [selectedCoin, setSelectedCoin] = useState("");

  // const isFreePlan = profileData?.pricing_plan === "Free-tier";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModal = () => {
    closeHandler();
    setStep(1);
    setCategory("");
    setSubcategory("");
    setFeature("");
    setSelectedCoin("");
  };

  const handleCategorySelection = (ctg) => {
    setCategory(ctg);
    setStep(2);
  };

  const handleSubcategorySelection = (subctg) => {
    setSubcategory(subctg);
    setStep(3);
  };

  const handleFeatureSelection = (feature) => {
    // todo: it's about accessibility and free plan
    // if (
    //   categoriesFeatureTree[category][subcategory][feature]?.accessibility ===
    //     "Pro" &&
    //   isFreePlan
    // ) {
    //   return insightProFeatureWarning(() => navigate("/pricing-billing"));
    // }

    setFeature(feature);
    if (type === "selectFeature") {
      // const newFeature = {
      //   category: category,
      //   feature: feature,
      //   subcategory: subcategory,
      //   symbol: "",
      // };
      if (
        categoriesFeatureTree[category][subcategory][feature]?.symbol_list
          ?.length
      ) {
        setStep(4);
      }

      // if (
      //   categoriesFeatureTree[category][subcategory][feature]?.symbol_list
      //     ?.length === 0
      // ) {
      //   newFeature.symbol = "";
      //   const filteredFeatures = selectedFeatures.filter(
      //     (item) =>
      //       item.category !== category &&
      //       item.feature !== feature &&
      //       item.subcategory !== subcategory &&
      //       item.symbol !== selectedCoin,
      //   );
      //
      //
      //   setStep(1);
      // } else {
      //   setStep(4);
      // }
    }
  };

  const handleSelectCoin = (coin) => {
    setSelectedCoin(coin);
  };

  const handleSaveSelectedFeature = () => {
    const newFeature = {
      category: category,
      feature: feature,
      subcategory: subcategory,
      symbol: selectedCoin,
    };

    const uniqueFeatures = [...selectedFeatures, newFeature].filter(
      (obj, index, self) => isUnique(self.slice(0, index), obj),
    );

    dispatch(updateSelectedFeaturesList(uniqueFeatures));
    closeModal();
  };

  return (
    <Modal open={show} onCancel={closeModal} centered footer={null}>
      {getInitialDataLoading &&
      Object.keys(categoriesFeatureTree).length === 0 ? (
        <div className={styles.loader}>
          <Spin tip={"Fetching features list..."} />
        </div>
      ) : (
        <>
          {step > 1 && (
            <ul className={styles.selectionDetails}>
              <li onClick={() => setStep(1)}>{category}</li>
              {step > 2 && (
                <li onClick={() => setStep(2)}>
                  <FaCaretRight />
                  {subcategory}
                </li>
              )}
              {step > 3 && (
                <li onClick={() => setStep(3)}>
                  <FaCaretRight />
                  {feature}
                </li>
              )}
            </ul>
          )}
          <ul
            className={`${styles.list} ${step === 4 ? styles.coinsList : ""}`}
          >
            {step === 1 &&
              categories.map((ctg) => (
                <FeatureListItem
                  key={ctg}
                  text={ctg}
                  clickHandler={handleCategorySelection}
                  className={`${styles.categoryItem} ${
                    category === ctg ? styles.selected : ""
                  }`}
                  icon={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_category_icons/${ctg}.png`}
                >
                  <Tooltip
                    title={categoryTooltip[ctg]}
                    color={"geekblue"}
                    placement={"bottom"}
                  >
                    <FiInfo />
                  </Tooltip>
                </FeatureListItem>
              ))}

            {step === 2 &&
              Object.keys(categoriesFeatureTree[category]).map((subctg) => (
                <FeatureListItem
                  key={subctg}
                  text={subctg}
                  clickHandler={handleSubcategorySelection}
                  className={`${styles.categoryItem} ${
                    subcategory === subctg ? styles.selected : ""
                  }`}
                  icon={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_subcategory_icons/${subctg}.png`}
                  style={{ justifyContent: "center" }}
                />
              ))}

            {step === 3 &&
              Object.keys(categoriesFeatureTree[category][subcategory]).map(
                (ftr) => (
                  <FeatureListItem
                    key={ftr}
                    text={ftr}
                    clickHandler={handleFeatureSelection}
                    className={`${styles.categoryItem} ${
                      feature === ftr ? styles.selected : ""
                    }`}
                    style={{ justifyContent: "center", textAlign: "center" }}
                  >
                    {/*{categoriesFeatureTree[category][subcategory][ftr]*/}
                    {/*  ?.accessibility === "Pro" &&*/}
                    {/*  isFreePlan && <HiLockClosed />}*/}
                  </FeatureListItem>
                ),
              )}

            {step === 4 &&
              categoriesFeatureTree[category][subcategory][
                feature
              ]?.symbol_list.map((coin) => (
                <FeatureListItem
                  key={coin}
                  text={coin}
                  style={{ justifyContent: "center" }}
                  icon={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${coin}.png`}
                  iconStyle={{ borderRadius: "100%" }}
                  className={`${styles.categoryItem} ${
                    selectedCoin === coin ? styles.selected : ""
                  }`}
                  clickHandler={handleSelectCoin}
                />
              ))}
          </ul>

          <div className={styles.btns}>
            {step > 1 && (
              <span
                className={styles.backBtn}
                onClick={() => setStep((curStep) => curStep - 1)}
              >
                <IoIosArrowBack />
                Back
              </span>
            )}

            {step === 3 &&
              categoriesFeatureTree[category][subcategory][feature]?.symbol_list
                ?.length === 0 && (
                <span
                  className={styles.saveBtn}
                  onClick={handleSaveSelectedFeature}
                >
                  Save
                </span>
              )}

            {step === 4 && (
              <span
                className={styles.saveBtn}
                onClick={handleSaveSelectedFeature}
              >
                Save
              </span>
            )}
          </div>
        </>
      )}
    </Modal>
  );
}

export default FeatureCategoriesModal;
