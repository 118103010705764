import {
  GET_INSIGHT_INITIAL_INFO_ERROR_NEW,
  GET_INSIGHT_INITIAL_INFO_REQUEST_NEW,
  GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW,
  GET_SIGNAL_VALUE_REQUEST,
  GET_SIGNAL_VALUE_SUCCESS,
  SET_CURRENT_SIGNAL_DATA,
  SET_CURRENT_SIGNAL_INDEX,
  SET_INSIGHT_MARKET_TYPE,
  UPDATE_SELECTED_FEATURES_NEW,
} from "./consts";

const initialState = {
  getInitialDataLoading: false,
  overallStatistics: {},
  categories: [],
  categoriesFeatureTree: {},
  processedFeaturesList: [],
  processedFeaturesData: [],
  getInitialDataError: "",
  selectedFeatures: [],
  initialDataFetched: false,
  insightMarketType: "crypto",
  getSignalValueLoading: false,
  currentSignalIndex: null,
  currentSignalData: {},
};

export const newInsightReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_INSIGHT_INITIAL_INFO_REQUEST_NEW:
      return {
        ...state,
        getInitialDataLoading: true,
        getInitialDataError: "",
      };

    case GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW:
      return {
        ...state,
        getInitialDataLoading: false,
        overallStatistics: payload?.OVERALL_STATISTICS,
        categories: payload?.CATEGORIES,
        categoriesFeatureTree: payload?.CATEGORIES_FEATURE_TREE,
        processedFeaturesList: payload?.PROCESSED_FEATURES,
        processedFeaturesData: payload?.RESPONSE_LIST,
        initialDataFetched: true,
        selectedFeatures: state.selectedFeatures.length
          ? state.selectedFeatures
          : payload?.PROCESSED_FEATURES,
      };

    case GET_INSIGHT_INITIAL_INFO_ERROR_NEW:
      return {
        ...state,
        getInitialDataLoading: false,
        getInitialDataError: payload,
      };

    case UPDATE_SELECTED_FEATURES_NEW:
      return {
        ...state,
        selectedFeatures: payload,
      };

    case SET_INSIGHT_MARKET_TYPE:
      return {
        ...state,
        insightMarketType: payload,
      };

    case GET_SIGNAL_VALUE_REQUEST:
      return {
        ...state,
        getSignalValueLoading: true,
      };
    case GET_SIGNAL_VALUE_SUCCESS:
      return {
        ...state,
        getSignalValueLoading: false,
        processedFeaturesList: payload?.PROCESSED_FEATURES,
        processedFeaturesData: payload?.RESPONSE_LIST,
      };

    case SET_CURRENT_SIGNAL_INDEX:
      return {
        ...state,
        currentSignalIndex: payload,
      };
    case SET_CURRENT_SIGNAL_DATA:
      return {
        ...state,
        currentSignalData: payload,
      };

    default:
      return state;
  }
};
