import React, { useEffect, useRef, useState } from "react";
import styles from "../styles/Components/signalDetails.module.sass";
import { Button, Input, Progress, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";
import ReactLoading from "react-loading";
import Modal from "./modal/Modal";
import {
  getPrice,
  setTrade,
  getPortfolioData,
} from "../redux/trading-assistant/actions";
import { useDispatch, useSelector } from "react-redux";
import ContentLoader from "react-content-loader";
import { setTrackSignal } from "../redux/alert-system/actions";
import { notify } from "../utility/Toastify/toastify";
// import TradeModal from "./modal/TradeModal";
import { BiBarChart } from "react-icons/bi";
import { BsPlusSquare } from "react-icons/bs";
import { MdNotificationsActive, MdNotificationsOff } from "react-icons/md";
import { setBasicTradeInfo, setMode, setStep } from "../redux/trade/actions";
import TradeModal from "../shared/trade-modal/TradeModal";

const SignalDetails = ({
  marketType,
  image,
  type,
  feature,
  category,
  subcategory,
  symbol,
  profitability,
  description,
  timeInterval,
  pricePrediction,
  predictionInterval,
  url_trading_view,
  tracked_with_alert_system,
  manageAlertSystemHandler,
  data,
}) => {
  const statusSpecifier = (value) => {
    return Number(String(value)?.split("%")[0]) > 0;
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  const [show, setShow] = useState(false);
  const [showTradeModal, setShowTradeModal] = useState(false);

  const dispatch = useDispatch();

  const setTradeHandler = (item) => {
    const {
      target_coin: asset,
      description,
      stop_loss_percent: stopLossPercent,
      take_profit_percent: takeProfitPercent,
      suggested_amount_percentage: amountPercent,
    } = item;
    const tradeType = description.includes("hold") ? "Buy" : "Sell";

    dispatch(
      setBasicTradeInfo({
        market: marketType,
        asset,
        tradeType,
        stopLossPercent,
        takeProfitPercent,
        amountPercent: amountPercent * 100,
      }),
    );
    dispatch(setStep("1-d"));
    dispatch(setMode("ai-suggestion"));
    setShowTradeModal(true);
  };

  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  return (
    <>
      <div
        className={styles.signalsDetailsContainer}
        data-tut="reactour__singlaDetails"
      >
        <div>
          <div>
            {image && <img src={image} />}
            <span>{symbol}</span>
          </div>
          <div>
            <div>
              <Tooltip
                placement="topLeft"
                title={
                  "The percentage gain in the last 2 months obtained by a comprehensive simulation using the signal"
                }
              >
                <span>
                  Profitability
                  <AiOutlineInfoCircle />
                </span>
              </Tooltip>
              <Tooltip
                placement="topLeft"
                title={"The interval of the prediction"}
              >
                <span>
                  Time Interval
                  <AiOutlineInfoCircle />
                </span>
              </Tooltip>
              <Tooltip
                placement="topLeft"
                title={"Current and predicted price"}
              >
                <span>
                  Price Prediction
                  <AiOutlineInfoCircle />
                </span>
              </Tooltip>
              <Tooltip
                placement="topLeft"
                title={
                  "The interval in which the actual price is expected to fall."
                }
              >
                <span>
                  Price Interval
                  <AiOutlineInfoCircle />
                </span>
              </Tooltip>
            </div>
            <div>
              <div>
                <span
                  className={
                    statusSpecifier(profitability?.maximum)
                      ? styles?.raise
                      : styles?.fall
                  }
                >
                  <Tooltip
                    placement="topLeft"
                    title={
                      "maximum profit in percent that a tester has achieved with this signal in the last 2 months."
                    }
                  >
                    <span>
                      <AiOutlineInfoCircle />
                      maximum:
                    </span>
                  </Tooltip>

                  {profitability?.maximum}
                </span>
                <span
                  className={
                    statusSpecifier(profitability?.average)
                      ? styles?.raise
                      : styles?.fall
                  }
                >
                  <Tooltip
                    placement="topLeft"
                    title={
                      "average percent profit that a tester has achieved with this signal in the last 2 months."
                    }
                  >
                    <span>
                      <AiOutlineInfoCircle />
                      average:
                    </span>
                  </Tooltip>
                  {profitability?.average}
                </span>
                <span
                  className={
                    statusSpecifier(profitability?.baseline)
                      ? styles?.raise
                      : styles?.fall
                  }
                >
                  <Tooltip
                    placement="topLeft"
                    title={
                      "Average percentage profit made in the last 2 months if you only bought and held the asset."
                    }
                  >
                    <span>
                      <AiOutlineInfoCircle />
                      baseline:
                    </span>
                  </Tooltip>
                  {profitability?.baseline}
                </span>
              </div>
              <div>
                <Tooltip placement="topLeft" title={"Current time"}>
                  <span>
                    {timeInterval?.current && (
                      <>
                        <AiOutlineInfoCircle />
                        {formatDate(new Date(timeInterval?.current))}
                      </>
                    )}
                  </span>
                </Tooltip>
                <Tooltip placement="topLeft" title={"Prediction time"}>
                  <span>
                    {timeInterval?.prediction && (
                      <>
                        <AiOutlineInfoCircle />{" "}
                        {formatDate(new Date(timeInterval?.prediction))}
                      </>
                    )}
                  </span>
                </Tooltip>
              </div>
              <div>
                <span>
                  <Tooltip placement="topLeft" title={"Current price (USD)"}>
                    <span className={styles.smFont}>
                      <AiOutlineInfoCircle />
                      current:
                    </span>
                  </Tooltip>
                  {pricePrediction ? `$ ${pricePrediction?.current}` : "-"}
                </span>
                <span>
                  <Tooltip placement="topLeft" title={"predicted price (USD)"}>
                    <span className={styles.smFont}>
                      <AiOutlineInfoCircle />
                      prediction:
                    </span>
                  </Tooltip>
                  {pricePrediction ? `$ ${pricePrediction?.prediction}` : "-"}
                </span>
                <span className={styles.changePirce}>
                  <Tooltip
                    placement="topLeft"
                    title={"predicted price change (%)"}
                  >
                    <span>
                      <AiOutlineInfoCircle />
                      price change:{" "}
                    </span>
                  </Tooltip>
                  {pricePrediction ? (
                    <span
                      style={{ marginLeft: 8 }}
                      className={
                        statusSpecifier(pricePrediction?.change)
                          ? styles?.raise
                          : styles?.fall
                      }
                    >
                      {pricePrediction?.change}
                    </span>
                  ) : null}
                </span>
                <span>
                  <Tooltip
                    placement="topLeft"
                    title={"Confidence of predicted price"}
                  >
                    <span className={styles.smFont}>
                      <AiOutlineInfoCircle />
                      confidence:
                    </span>
                  </Tooltip>

                  {pricePrediction ? (
                    <Progress
                      width={50}
                      steps={4}
                      percent={pricePrediction?.confidence.split("%")[0]}
                    />
                  ) : (
                    "-"
                  )}
                </span>
              </div>
              <div>
                <span>
                  <Tooltip
                    placement="topLeft"
                    title={
                      "Upper bound of the confidence interval for the predicted price"
                    }
                  >
                    <span className={styles.smFont}>
                      <AiOutlineInfoCircle />
                      upper bound:
                    </span>
                  </Tooltip>
                  {predictionInterval ? `$ ${predictionInterval?.high}` : "-"}
                </span>
                <span>
                  <Tooltip
                    placement="topLeft"
                    title={
                      "Lower bound of the confidence interval for the predicted price"
                    }
                  >
                    <span className={styles.smFont}>
                      <AiOutlineInfoCircle />
                      lower bound:
                    </span>
                  </Tooltip>
                  {predictionInterval ? `$ ${predictionInterval?.low}` : "-"}
                </span>
              </div>
            </div>
          </div>
          <div>
            <Tooltip placement="bottom" title={"Trading View"}>
              <Button className={styles?.btn}>
                <a href={url_trading_view} target={"_blank"} rel="noreferrer">
                  <BiBarChart className={styles?.icon} />
                </a>
              </Button>
            </Tooltip>
            <Tooltip placement="bottom" title={"Add to Portfolio"}>
              <Button
                className={styles?.btn}
                onClick={
                  () => {
                    setTradeHandler(data);
                  }
                  // getPriceHandler({
                  //   SYMBOL_NAME: symbol,
                  //   MARKET_TYPE: marketType,
                  // })
                }
              >
                <BsPlusSquare className={styles?.icon} />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={styles.description}>
          <div>
            <span>Suggested Action:</span>
            <span>{description}</span>
          </div>

          {tracked_with_alert_system ? (
            <div>
              <span className={styles.tracked}>
                <MdNotificationsActive />
              </span>
              <Button onClick={manageAlertSystemHandler}>
                Manage alert system
              </Button>
            </div>
          ) : (
            <div>
              <span className={styles.notTracked}>
                <MdNotificationsOff />
              </span>

              <Button onClick={manageAlertSystemHandler}>
                Manage alert system
              </Button>
            </div>
          )}
        </div>
      </div>
      <TradeModal setShow={setShowTradeModal} show={showTradeModal} />
      {/*<TradeModal*/}
      {/*  show={show}*/}
      {/*  handleOk={handleOk}*/}
      {/*  handleCancel={handleCancel}*/}
      {/*  setShow={setShow}*/}
      {/*  signal={selectedSignal}*/}
      {/*/>*/}
    </>
  );
};

export default SignalDetails;
