import React, { useState, useEffect } from "react";
import { Button, Empty, Table, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/Components/tables/overAllTable.module.sass";
import { motion } from "framer-motion/dist/framer-motion";
import { getPrice } from "../../redux/trading-assistant/actions";
// import TradeModal from "../modal/TradeModal";
import { BiBarChart } from "react-icons/bi";
import { BsPlusSquare } from "react-icons/bs";
import { setBasicTradeInfo, setMode, setStep } from "../../redux/trade/actions";
import TradeModal from "../../shared/trade-modal/TradeModal";
import { extractNumberFromString } from "../../utility/helper";

const OverAllTable = ({ marketType }) => {
  const { OVERALL_STATISTICS } = useSelector(
    (state) => state.insightState?.initialInfo,
  );
  const {
    TOP_20,
    TOP_20_Gainers,
    TOP_20_losers,
    TOP_20_Relatively_Gainers,
    TOP_20_Relatively_Losers,
  } = OVERALL_STATISTICS;
  const [dataSource, setDataSource] = useState([[], []]);
  const [selectedColumn1, setSelectedColumn1] = useState("TOP_20_SYMBOLS");
  const [selectedColumn2, setSelectedColumn2] = useState("TOP_20");
  const [titles, setTitles] = useState(["Top 20"]);
  const typeOfTables = [
    "TOP_20",
    "TOP_20_Gainers/Losers",
    "TOP_20_Relatively_Gainers/Losers",
  ];
  const [columns, setColumns] = useState([]);
  const [showTradeModal, setShowTradeModal] = useState(false);
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const currencyFormatter = (num, percentage) => {
    if (percentage) {
      return Math.sign(num) * Math.abs(num).toFixed(2) + " %";
    }
    if (Math.abs(num) > 99999999) {
      return Math.sign(num) * (Math.abs(num) / 1000000000).toFixed(2) + " B";
    } else if (Math.abs(num) > 999999) {
      return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(2) + " M";
    } else if (Math.abs(num) > 999) {
      return Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + " K";
    }
    return Math.sign(num) * Math.abs(num).toFixed(2);
  };

  const onChange = (key) => {
    switch (key) {
      case "1": {
        setTitles(["Top 20"]);
        setSelectedColumn1("TOP_20_SYMBOLS");
        setSelectedColumn2("TOP_20");
        break;
      }
      case "2": {
        setTitles(["Gainers", "Losers"]);
        setSelectedColumn1("TOP_20_Gainers_Losers_SYMBOLS");
        setSelectedColumn2("TOP_20_Gainers_Losers");
        break;
      }
      case "3": {
        setTitles(["Gainers", "Losers"]);
        setSelectedColumn1("TOP_20_Relatively_Gainers_Losers_SYMBOLS");
        setSelectedColumn2("TOP_20_Relatively_Gainers_Losers");
        break;
      }
    }
    setDataSource([[], []]);
  };

  useEffect(() => {
    setColumns([
      {
        title: "#",
        key: "row",
        dataIndex: "row",
        className: styles.col,
        width: 20,
        align: "center",
      },
      {
        title: "Symbol",
        dataIndex: selectedColumn1,
        key: selectedColumn1,
        className: styles.col,
        align: "center",
        width: 20,
      },
      {
        title:
          selectedColumn1 === "TOP_20_Relatively_Gainers_Losers_SYMBOLS"
            ? "Percentage"
            : "Volume",
        dataIndex: selectedColumn2,
        key: selectedColumn2,
        className: styles.col,
        align: "center",
        width: 20,
        render: (text) => (
          <span
            className={`${styles.volume} ${
              Number(text.split("B")[0]) > 0 || Number(text.split("%")[0]) > 0
                ? styles.raise
                : styles.fall
            }`}
          >
            {text}{" "}
            {selectedColumn1 !== "TOP_20_Relatively_Gainers_Losers_SYMBOLS" && (
              <span>USD</span>
            )}
          </span>
        ),
      },
      {
        title: "Add to Portfolio",
        key: "AddToPortfolio",
        className: styles.col,
        align: "center",
        width: 20,
        render: (text) => (
          <Button
            className={styles.addToPortfolioBtn}
            // onClick={() =>
            //   getPriceHandler({
            //     SYMBOL_NAME: text.symbol,
            //     MARKET_TYPE: marketType,
            //   })
            // }
            onClick={() =>
              setTradeHandler(text, selectedColumn1, text[selectedColumn2])
            }
          >
            <BsPlusSquare />
          </Button>
        ),
      },
      {
        title: "Trading View",
        key: "AddToPortfolio",
        className: styles.col,
        align: "center",
        responsive: ["sm", "md", "xxl"],
        width: 20,
        render: (text) => (
          <Button className={styles.tradingViewBtn}>
            <a
              href={`https://www.tradingview.com/chart/?symbol=BINANCE%3A${text.symbol}USDT`}
              target={"_blank"}
              rel="noreferrer"
            >
              <BiBarChart />
            </a>{" "}
          </Button>
        ),
      },
    ]);
    if (selectedColumn1 === "TOP_20_SYMBOLS") {
      OVERALL_STATISTICS?.TOP_20_SYMBOLS?.map((item, index) => {
        if (index < 10) {
          dataSource[0]?.push({
            row: index + 1,
            symbol: item,
            key: index,
            TOP_20_SYMBOLS: (
              <span className={styles.symbol}>
                <img
                  style={{ width: 36, height: 36, borderRadius: "100%" }}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item}.png`}
                  alt={item}
                />{" "}
                {item}
              </span>
            ),
            TOP_20: currencyFormatter(OVERALL_STATISTICS?.TOP_20[index]),
          });
        } else {
          dataSource[1]?.push({
            row: index + 1,
            symbol: item,
            key: index,
            TOP_20_SYMBOLS: (
              <span className={styles.symbol}>
                <img
                  style={{ width: 36, height: 36, borderRadius: "100%" }}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item}.png`}
                  alt={item}
                />{" "}
                {item}
              </span>
            ),
            TOP_20: currencyFormatter(OVERALL_STATISTICS?.TOP_20[index]),
          });
        }
      });
    } else if (selectedColumn1 === "TOP_20_Gainers_Losers_SYMBOLS") {
      OVERALL_STATISTICS?.TOP_20_Gainers_SYMBOLS?.map((item, index) => {
        dataSource[0]?.push({
          row: index + 1,
          symbol: item,
          key: index,
          TOP_20_Gainers_Losers_SYMBOLS: (
            <span className={styles.symbol}>
              <img
                style={{ width: 36, height: 36, borderRadius: "100%" }}
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item}.png`}
                alt={item}
              />{" "}
              {item}
            </span>
          ),
          TOP_20_Gainers_Losers: currencyFormatter(
            OVERALL_STATISTICS?.TOP_20_Gainers[index],
          ),
        });
      });
      OVERALL_STATISTICS?.TOP_20_losers_SYMBOLS?.map((item, index) => {
        dataSource[1]?.push({
          row: index + 1,
          symbol: item,
          key: index,
          TOP_20_Gainers_Losers_SYMBOLS: (
            <span className={styles.symbol}>
              <img
                style={{ width: 36, height: 36, borderRadius: "100%" }}
                src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item}.png`}
                alt={item}
              />{" "}
              {item}
            </span>
          ),
          TOP_20_Gainers_Losers: currencyFormatter(
            OVERALL_STATISTICS?.TOP_20_losers[index],
          ),
        });
      });
    } else {
      OVERALL_STATISTICS?.TOP_20_Relatively_Gainers_SYMBOLS?.map(
        (item, index) => {
          dataSource[0]?.push({
            row: index + 1,
            symbol: item,
            key: index,
            TOP_20_Relatively_Gainers_Losers_SYMBOLS: (
              <span className={styles.symbol}>
                <img
                  style={{ width: 36, height: 36, borderRadius: "100%" }}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item}.png`}
                  alt={item}
                />{" "}
                {item}
              </span>
            ),
            TOP_20_Relatively_Gainers_Losers: currencyFormatter(
              OVERALL_STATISTICS?.TOP_20_Relatively_Gainers?.[index],
              true,
            ),
          });
        },
      );
      OVERALL_STATISTICS?.TOP_20_Relatively_Losers_SYMBOLS?.map(
        (item, index) => {
          dataSource[1]?.push({
            row: index + 1,
            symbol: item,
            key: index,
            TOP_20_Relatively_Gainers_Losers_SYMBOLS: (
              <span className={styles.symbol}>
                <img
                  style={{ width: 36, height: 36, borderRadius: "100%" }}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item}.png`}
                  alt={item}
                />{" "}
                {item}
              </span>
            ),
            TOP_20_Relatively_Gainers_Losers: currencyFormatter(
              OVERALL_STATISTICS?.TOP_20_Relatively_Losers?.[index],
              true,
            ),
          });
        },
      );
    }
  }, [selectedColumn2, selectedColumn1, OVERALL_STATISTICS]);

  const [show, setShow] = useState(false);
  const [selectedSignal, setSelectedSignal] = useState(null);
  const {
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  // const getPriceHandler = (item) => {
  //   setShow(true);
  //   setSelectedSignal(item);
  //   dispatch(
  //     getPrice(
  //       userData?.["cognito:username"],
  //       userData?.["custom:custom_username"],
  //       item?.SYMBOL_NAME,
  //       item?.MARKET_TYPE
  //     )
  //   );
  // };

  const setTradeHandler = (item, isGainerLoser, volumeOrPercentage) => {
    const { symbol: asset } = item;
    if (isGainerLoser.includes("Gainer")) {
      let suggestedTrade;
      if (extractNumberFromString(volumeOrPercentage) < 0) {
        suggestedTrade = "Sell";
      } else {
        suggestedTrade = "Buy";
      }
      dispatch(
        setBasicTradeInfo({
          market: marketType,
          asset,
          tradeType: suggestedTrade,
        }),
      );
    } else {
      dispatch(
        setBasicTradeInfo({
          market: marketType,
          asset,
        }),
      );
    }
    dispatch(setStep("1-d"));
    dispatch(setMode("ai-table"));
    setShowTradeModal(true);
  };

  const handleCancel = () => {
    setShow(false);
  };
  const handleOk = () => {
    setShow(false);
  };

  return (
    <>
      <Tabs
        onChange={onChange}
        className={styles.tabs}
        items={typeOfTables.map((item, i) => {
          const id = String(i + 1);
          return {
            label: item.replaceAll("_", " "),
            key: id,
          };
        })}
      />
      <div className={styles.tableWrapper}>
        <div>
          {titles?.length > 1 ? (
            <>
              <span>{titles[0]}</span>
              <span>{titles[1]}</span>
            </>
          ) : (
            titles[0]
          )}
        </div>
        {selectedColumn2 === "TOP_20" && !TOP_20.length && <Empty />}
        {selectedColumn2 === "TOP_20_Gainers_Losers" &&
          !TOP_20_losers.length && <Empty />}
        {selectedColumn2 === "TOP_20_Relatively_Gainers_Losers" &&
          !TOP_20_Relatively_Losers.length && <Empty />}
        {dataSource[0]?.length > 0 && (
          <motion.div
            initial="hidden"
            animate={"visible"}
            transition={{ ease: "easeOut", duration: 1 }}
            variants={variants}
          >
            {dataSource[0]?.length > 0 && (
              <Table
                dataSource={dataSource?.[0]}
                columns={columns}
                scroll={{
                  y: 700,
                }}
                className={styles.table}
                // pagination={false}
              />
            )}
            {dataSource[1]?.length > 0 && (
              <Table
                dataSource={dataSource?.[1]}
                columns={columns}
                scroll={{
                  y: 700,
                }}
                className={styles.table}

                // pagination={false}
              />
            )}
          </motion.div>
        )}
      </div>
      <TradeModal setShow={setShowTradeModal} show={showTradeModal} />
      {/*<TradeModal*/}
      {/*  show={show}*/}
      {/*  handleOk={handleOk}*/}
      {/*  handleCancel={handleCancel}*/}
      {/*  setShow={setShow}*/}
      {/*  signal={selectedSignal}*/}
      {/*/>*/}
    </>
  );
};

export default OverAllTable;
