import { Button, Checkbox } from "antd";
import styles from "../../styles/pages/insight/index.module.sass";
import { IoMdArrowDropright } from "react-icons/io";
import ShowMoreText from "react-show-more-text";

import Ripples from "react-ripples";
import Chart from "./Chart";

function ChartSection({
  selectedFeatures,
  setCheckedFeatures,
  checkedFeatures,
  setSelectedCoin,
  setIsModalOpen,
  clearAllFeaturesHandler,
  removeFeaturesHandler,
  chartLoading,
  chartData,
  setChartData,
  signalsNumber,
  signalsRange,
  executeOnClick,
  showMore,
  selectedSignalDetails
}) {
  const selectedFeaturesOnchangeHandler = (e) => {
    if (e.target?.checked) {
      setCheckedFeatures((prevState) => {
        if (!checkedFeatures?.includes(e.target.value)) {
          return [...prevState, e.target.value];
        }
        return [...prevState];
      });
    } else {
      setCheckedFeatures((prevState) => {
        return checkedFeatures?.filter((item) => item != e.target.value);
      });
    }
  };

  return (
    <>
      <div className={styles.itemsContainer}>
        <div className={styles.topTenContainer}>
          {selectedFeatures?.map((item, index) => {
            return (
              <div className={`${styles.coin} `}>
                <Checkbox
                  value={item}
                  onChange={selectedFeaturesOnchangeHandler}
                  checked={checkedFeatures?.includes(item)}
                />
                {item?.symbol_icon && <img src={item?.symbol_icon} alt="" />}
                <span>
                  {item?.category}
                  <IoMdArrowDropright />
                  {item?.subcategory}
                  <IoMdArrowDropright />
                  {item?.feature}
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#0c4cfc",
                  }}
                  onClick={() => {
                    setSelectedCoin(index);
                    setIsModalOpen(true);
                  }}
                >
                  {item?.symbol}
                </span>
              </div>
            );
          })}
        </div>
        <div className={styles.controllers}>
          <Ripples during={1000}>
            <Button onClick={clearAllFeaturesHandler}>Clear All</Button>
          </Ripples>

          {checkedFeatures?.length > 0 && (
            <Ripples during={1000}>
              <Button onClick={removeFeaturesHandler}>
                Remove {checkedFeatures?.length}{" "}
                {checkedFeatures?.length > 1 ? "items" : "item"}
              </Button>
            </Ripples>
          )}
        </div>
      </div>
      {!chartLoading ? (
        <div
          className={styles.chartContainer}
          id={"chart"}
          data-tut="reactour__chart"
        >
          <Chart
            chartData={chartData}
            signalNumber={signalsNumber}
            setChartData={setChartData}
            signalRange={signalsRange}
            selectedFeatures={selectedFeatures}
          />
          <div style={{ margin: "36px 0" }}>
            <ShowMoreText
              lines={1}
              anchorClass="show-more-less-clickable"
              onClick={executeOnClick}
              expanded={showMore}
              className={styles.longText}
            >
              <strong>Description:{"  "}</strong>

              {selectedFeatures[selectedSignalDetails]?.description}
            </ShowMoreText>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", height: 750 }} id={"chart"}></div>
      )}
    </>
  );
}

export default ChartSection;
