import alert from "../../components/alerts/Alert";
import { errorHandler } from "../../utility/helper";
import {
  GET_INSIGHT_CHART_ERROR,
  GET_INSIGHT_CHART_REQUEST,
  GET_INSIGHT_CHART_SUCCESS,
  GET_INSIGHT_INITIAL_INFO_ERROR,
  GET_INSIGHT_INITIAL_INFO_REQUEST,
  GET_INSIGHT_INITIAL_INFO_SUCCESS,
  UPDATE_SELECTED_FEATURES,
} from "../insight/consts";
import axios from "axios";
import { axiosErrorHandler } from "../../utility/action-helper";

export const updateFeaturesList = (data) => (dispatch) => {
  dispatch({ type: UPDATE_SELECTED_FEATURES, payload: data });
};

export const getInsightInitialData =
  (coin, email, user, only_tree_categories = "False", retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_INSIGHT_INITIAL_INFO_REQUEST });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "insight_platform",
        email,
        user,
        market_type: coin,
        request_type: "get_initial_info",
        only_tree_categories,
      })
      .then((res) => {
        dispatch({
          type: GET_INSIGHT_INITIAL_INFO_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_INSIGHT_INITIAL_INFO_ERROR,
          payload: err?.response,
        });
        const { STATUS_CODE, MESSAGE } = err?.response.data;
        errorHandler(STATUS_CODE, MESSAGE);
      });
  };

export const getChartData =
  (coin, selectedFeatures, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_INSIGHT_CHART_REQUEST });
    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "insight_platform",
        email: "lfdlldf@jgkdk.com",
        market_type: coin,
        request_type: "get_signal_value",
        feature_list: selectedFeatures?.map((item) => ({
          symbol: item?.symbol,
          category: item?.category,
          feature: item?.name,
        })),
      })
      .then((res) => {
        dispatch({ type: GET_INSIGHT_CHART_SUCCESS, payload: res?.data });
      })
      .catch((err) => {
        axiosErrorHandler(err, retryCount, dispatch);
        dispatch({ type: GET_INSIGHT_CHART_ERROR, payload: err.response });
      });
  };
