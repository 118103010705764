import * as type from "../consts";
import {
  OTP_SEND_CODE_ERROR,
  OTP_SEND_CODE_REQUEST,
  OTP_SEND_CODE_SUCCESS,
  OTP_VERIFY_CODE_ERROR,
  OTP_VERIFY_CODE_REQUEST,
  OTP_VERIFY_CODE_SUCCESS,
  SAVE_USER_PASS,
} from "../consts";

const initialState = {
  userData: {},
  systemData: {},
  token: null,
  refreshToken: null,
  logout: "",
  userPass: [],
  loginWithTelegram: false,
  loginProcess: "",
  telegramData: {},
  otpSendCodeStatus: "",
  otpSendCodeMessage: "",
  otpSendCodeError: "",
  otpVerifyCodeStatus: "",
  otpVerifyCode: null,
  otpVerifyCodeError: "",
};
const main = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_USERDATA:
      return {
        ...state,
        userData: action.payload,
      };
    case type.SET_SYSTEM_DATA:
      return {
        ...state,
        systemData: action.payload,
      };
    case type.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case type.SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
      };
    case type.SET_DATA_AS_LOCAL:
      return {
        ...state,
        ...action.payload,
      };
    case type.SET_LOGOUT:
      return {
        ...state,
        userData: {},
        systemData: {},
        token: null,
        logout: action.payload,
      };
    case SAVE_USER_PASS:
      return {
        ...state,
        userPass: action?.payload,
      };

    case "set-login-process-to-telegram":
      return {
        ...state,
        loginProcess: "telegram",
      };

    case "login-with-telegram":
      return {
        ...state,
        loginWithTelegram: true,
      };
    case "save-telegram-data":
      return {
        ...state,
        telegramData: action?.payload,
      };

    case OTP_SEND_CODE_REQUEST:
      return {
        ...state,
        otpSendCodeError: "",
        otpSendCodeMessage: "",
        otpSendCodeStatus: "loading",
      };
    case OTP_SEND_CODE_SUCCESS:
      return {
        ...state,
        otpSendCodeStatus: "ready",
        otpSendCodeMessage: action?.payload,
      };
    case OTP_SEND_CODE_ERROR:
      return {
        ...state,
        otpSendCodeStatus: "error",
        otpSendCodeError: action?.payload,
      };

    case OTP_VERIFY_CODE_REQUEST:
      return {
        ...state,
        otpVerifyCodeError: "",
        otpVerifyCodeStatus: "loading",
        otpVerifyCode: null,
      };
    case OTP_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        otpVerifyCodeStatus: "ready",
        otpVerifyCode: action.payload,
      };
    case OTP_VERIFY_CODE_ERROR:
      return {
        ...state,
        otpVerifyCodeStatus: "error",
        otpVerifyCodeError: action?.payload,
      };

    default:
      return { ...state };
  }
};

export default main;
