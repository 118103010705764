import styles from "../../styles/shared/service-activation/SubscriptionSettings.module.css";
import { FaCrown } from "react-icons/fa";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tabs } from "antd";
import { getCheckoutLink } from "../../redux/pricing-plan/action";
import SubscriptionBasicPlan from "./plans/SubscriptionBasicPlan";
import SubscriptionProPlan from "./plans/SubscriptionProPlan";
import SubscriptionEnterprisePlan from "./plans/SubscriptionEnterprisePlan";

function SubscriptionSettings() {
  const {
    pricingPlan: { checkoutSession, checkoutLoading, pricingPlan },
    main: { userData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("basic");
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [proPlanDuration, setProPlanDuration] = useState(null);

  const buySubscriptionHandler = (e) => {
    if (
      !checkoutSession ||
      checkoutLoading ||
      (activeTab === "basic" && !selectedDuration) ||
      (activeTab === "pro" && !proPlanDuration)
    ) {
      e.preventDefault();
    }
  };

  const selectDurationHandler = (duration) => {
    if (checkoutLoading) return;

    if (activeTab === "basic" && selectedDuration === duration) return;
    if (activeTab === "pro" && proPlanDuration === duration) return;

    if (activeTab === "basic") {
      setSelectedDuration(duration);
    } else {
      setProPlanDuration(duration);
    }
    const redirectUrl = window.location.href;
    dispatch(
      getCheckoutLink(
        userData["cognito:username"],
        userData?.["custom:custom_username"],
        duration,
        redirectUrl,
        redirectUrl,
      ),
    );
  };

  const tabItems = [
    {
      key: "basic",
      label: (
        <span>
          {" "}
          <FaCrown color={"#CD7F32"} /> Basic
        </span>
      ),
      children: (
        <SubscriptionBasicPlan
          selectedDuration={selectedDuration}
          selectDurationHandler={selectDurationHandler}
          buySubscriptionHandler={buySubscriptionHandler}
        />
      ),
    },
    {
      key: "pro",
      label: (
        <span>
          {" "}
          <FaCrown color={"#C0C0C0"} /> Pro
        </span>
      ),
      children: (
        <SubscriptionProPlan
          proPlanDuration={proPlanDuration}
          buySubscriptionHandler={buySubscriptionHandler}
          selectDurationHandler={selectDurationHandler}
        />
      ),
    },
    {
      key: "enterprise",
      label: (
        <span>
          {" "}
          <FaCrown color={"#FFD700"} /> Enterprise
        </span>
      ),
      children: <SubscriptionEnterprisePlan />,
    },
  ];

  const changeTab = (key) => {
    setSelectedDuration(null);
    setProPlanDuration(null);
    setActiveTab(key);
  };

  return (
    <div className={styles.container}>
      <h2 style={{ marginBottom: ".5rem" }}>Subscription Plans</h2>
      <Tabs
        activeKey={activeTab}
        items={tabItems}
        onChange={changeTab}
        type="card"
      />
    </div>
  );
}

export default SubscriptionSettings;
