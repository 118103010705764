export const GET_INSIGHT_INITIAL_INFO_REQUEST_NEW =
  "GET_INSIGHT_INITIAL_INFO_REQUEST_NEW";
export const GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW =
  "GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW";
export const GET_INSIGHT_INITIAL_INFO_ERROR_NEW =
  "GET_INSIGHT_INITIAL_INFO_ERROR_NEW";

export const UPDATE_SELECTED_FEATURES_NEW = "UPDATE_SELECTED_FEATURES_NEW";
export const DELETE_FEATURE_FROM_SELECTED_FEATURES_NEW =
  "DELETE_FEATURE_FROM_SELECTED_FEATURES_NEW";
export const ADD_FEATURE_TO_SELECTED_FEATURES_NEW =
  "ADD_FEATURE_TO_SELECTED_FEATURES_NEW";

export const GET_SIGNAL_VALUE_REQUEST = "GET_SIGNAL_VALUE_REQUEST";
export const GET_SIGNAL_VALUE_SUCCESS = "GET_SIGNAL_VALUE_SUCCESS";
export const GET_SIGNAL_VALUE_ERROR = "GET_SIGNAL_VALUE_ERROR";

export const SET_INSIGHT_MARKET_TYPE = "SET_INSIGHT_MARKET_TYPE";
export const SET_CURRENT_SIGNAL_INDEX = "SET_CURRENT_SIGNAL_INDEX";
export const SET_CURRENT_SIGNAL_DATA = "SET_CURRENT_SIGNAL_DATA";
