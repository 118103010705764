import { Modal } from "antd";
import styles from "../../styles/pages/insight/index.module.sass";

function SymbolsModal({
  isModalOpen,
  handleOk,
  handleCancel,
  checked,
  selectedCoin,
  selectedSymbolId,
  setChecked,
  setSelectedSymbolId,
}) {
  return (
    <Modal
      title="Symbols"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      width={600}
      style={{ borderRadius: 8 }}
    >
      <div className={styles.modalItems}>
        {checked[selectedCoin]?.symbol_list?.map((item, index) => {
          return (
            <div
              className={`${styles.modalItem} ${
                selectedSymbolId === index ? styles.activeSymbol : ""
              }`}
              onClick={() => {
                let newList = checked;
                newList[selectedCoin]["symbol"] =
                  checked?.[selectedCoin]?.symbol_list?.length > 0
                    ? item
                    : null;
                newList[selectedCoin]["symbol_icon"] =
                  checked?.[selectedCoin]?.symbol_icon_list?.[index];
                setChecked(newList);
                setSelectedSymbolId(index);
              }}
            >
              <span>{item}</span>
              <span>
                <img
                  src={checked?.[selectedCoin]?.symbol_icon_list?.[index]}
                  alt="check"
                />
              </span>
            </div>
          );
        })}
      </div>
    </Modal>
  );
}

export default SymbolsModal;
