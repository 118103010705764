import { Badge, Button, Checkbox, Collapse, Empty } from "antd";
import styles from "../../styles/pages/insight/index.module.sass";
import { IoMdArrowDropright } from "react-icons/io";
import Ripples from "react-ripples";
import ShowMoreText from "react-show-more-text";
import ReactLoading from "react-loading";
import { MdOutlineAddAlert } from "react-icons/md";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import SignalDetails from "../SignalDetails";

function Signals({
  manageAlert,
  signalsDetails,
  selectedFeatures,
  setCheckedFeatures,
  setEditMode,
  setSelectedSignalDetails,
  selectedSignalDetails,
  editMode,
  selectedFeaturesOnchangeHandler,
  checkedFeatures,
  setSelectedCoin,
  setIsModalOpen,
  clearAllFeaturesHandler,
  removeFeaturesHandler,
  showMore,
  executeOnClick,
  tracked,
  trackSignalHandler,
  animateBtn,
  TrackSignalLoading,
  manageAlertSystemHandler,
  coin,
}) {
  return (
    <div className={styles.signalsDetails} id={"signals"} ref={manageAlert}>
      {signalsDetails?.length > 0 ? (
        <>
          <div className={styles.itemsContainer} style={{ padding: 0 }}>
            <div className={styles.topTenContainer}>
              {selectedFeatures?.map((item, index) => {
                return (
                  <div
                    onClick={(e) => {
                      setSelectedSignalDetails(index);
                    }}
                    className={`${styles.coin} ${styles.signal} ${
                      index === selectedSignalDetails ? styles.activeSignal : ""
                    }`}
                  >
                    {editMode && (
                      <Checkbox
                        value={item}
                        onChange={selectedFeaturesOnchangeHandler}
                        checked={checkedFeatures?.includes(item)}
                      />
                    )}
                    {item?.symbol_icon && (
                      <img src={item?.symbol_icon} alt="" />
                    )}
                    <span>
                      {item?.category}
                      <IoMdArrowDropright />
                      {item?.subcategory}
                      <IoMdArrowDropright />
                      {item?.feature}
                    </span>
                    {item?.symbol && (
                      <span
                        className={styles.symbols}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedCoin(index);
                          setIsModalOpen(true);
                        }}
                      >
                        {item?.symbol}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={styles.controllers}>
              <Button
                onClick={() => {
                  if (!editMode) {
                    setEditMode(true);
                  } else {
                    setCheckedFeatures([]);
                    setEditMode(false);
                  }
                }}
              >
                {editMode ? "Cancel" : "Edit"}
              </Button>
              <Ripples during={1000}>
                <Button onClick={clearAllFeaturesHandler}>Clear All</Button>
              </Ripples>
              {checkedFeatures?.length > 0 && (
                <Ripples during={1000}>
                  <Button onClick={removeFeaturesHandler}>
                    Remove {checkedFeatures?.length}{" "}
                    {checkedFeatures?.length > 1 ? "items" : "item"}
                  </Button>
                </Ripples>
              )}
            </div>
          </div>

          <div
            className={`${styles.featureDescription} ${
              showMore ? styles.showMore : ""
            }`}
          >
            <ShowMoreText
              lines={1}
              anchorClass="show-more-less-clickable"
              onClick={executeOnClick}
              expanded={showMore}
              className={styles.longText}
            >
              <strong>Description:{"  "}</strong>

              {selectedFeatures[selectedSignalDetails]?.description}
            </ShowMoreText>
            {!tracked ? (
              <Button
                onClick={trackSignalHandler}
                className={animateBtn ? styles.activeTrackBtn : ""}
              >
                {TrackSignalLoading ? (
                  <ReactLoading
                    type={"bars"}
                    color="#fff"
                    height={40}
                    width={36}
                  />
                ) : (
                  <>
                    Track with Alert System <MdOutlineAddAlert />
                  </>
                )}
              </Button>
            ) : (
              <Button
                onClick={trackSignalHandler}
                className={animateBtn ? styles.activeTrackBtn : ""}
              >
                {TrackSignalLoading ? (
                  <ReactLoading
                    type={"bars"}
                    color="#fff"
                    height={40}
                    width={36}
                  />
                ) : (
                  <>
                    Disable track with Alert System <MdOutlineAddAlert />
                  </>
                )}
              </Button>
            )}
          </div>

          {signalsDetails?.[selectedSignalDetails] &&
            Object.keys(signalsDetails?.[selectedSignalDetails])?.map(
              (item, index) => {
                return (
                  <Badge
                    count={
                      signalsDetails?.[selectedSignalDetails]?.[item]?.length
                    }
                    color={"blue"}
                    className={styles.collapse}
                  >
                    <Collapse collapsible="header" defaultActiveKey={[0, 1, 2]}>
                      <CollapsePanel header={item} key={index}>
                        {signalsDetails?.length > 0 &&
                        signalsDetails?.[selectedSignalDetails]?.[item]
                          ?.length ? (
                          signalsDetails?.[selectedSignalDetails]?.[item]?.map(
                            (item) => {
                              return (
                                <SignalDetails
                                  manageAlertSystemHandler={
                                    manageAlertSystemHandler
                                  }
                                  marketType={coin}
                                  tracked_with_alert_system={
                                    item?.tracked_with_alert_system
                                  }
                                  image={item?.target_coin_icon_url}
                                  profitability={item?.profitability}
                                  timeInterval={item?.time_interval}
                                  url_trading_view={item?.url_trading_view}
                                  type={item?.type}
                                  feature={
                                    selectedFeatures[selectedSignalDetails]
                                      ?.feature
                                  }
                                  subcategory={
                                    selectedFeatures[selectedSignalDetails]
                                      ?.subcategory
                                  }
                                  category={
                                    selectedFeatures[selectedSignalDetails]
                                      ?.category
                                  }
                                  symbol={item?.target_coin}
                                  predictionInterval={item?.prediction_interval}
                                  pricePrediction={item?.price_prediction}
                                  description={item?.description}
                                  data={item}
                                />
                              );
                            },
                          )
                        ) : (
                          <div className={styles?.noSignal}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </div>
                        )}
                      </CollapsePanel>
                    </Collapse>
                  </Badge>
                );
              },
            )}
        </>
      ) : (
        <Empty />
      )}
    </div>
  );
}

export default Signals;
