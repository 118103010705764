import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/shared/trade-modal/TradeModal.module.css";
import Step1A from "./steps/Step1a";
import Step1B from "./steps/Step1b";
import {
  resetTradeData,
  resetVirtualTradeState,
} from "../../redux/trade/actions";
import Step2 from "./steps/Step2";
import ResultMode from "./steps/step-components/ResultMode";
import Step1C from "./steps/Step1c";
import VirtualTradeStep2 from "./steps/virtual-trade/VirtualTradeStep2";
import AddToVirtualPortfolio from "./steps/AddToVirtualPortfolio";
import LimitOrderSetting from "./steps/LimitOrderSetting";
import LimitOrderResult from "./steps/step-components/LimitOrderResult";
import VirtualTradeResult from "./steps/virtual-trade/VirtualTradeResult";
import SetVirtualLimitOrder from "./steps/virtual-trade/SetVirtualLimitOrder";
import Step1D from "./steps/Step1d";
import AddToVirtualConfirmationDialog from "./steps/AddToVirtualConfirmationDialog";
import VirtualLimitOrderResult from "./steps/virtual-trade/VirtualLimitOrderResult";
import ShowRecommendation from "./steps/ShowRecommendation";

function TradeModal({ show, setShow }) {
  const {
    tradeState: { currentStep, takeProfitOrderLoading, stopLossOrderLoading },
    exchangeState: { orderTradeLoading },
    tradingAssistant: { setTradeLoading, limitOrderLoading },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const cancelHandler = () => {
    if (
      orderTradeLoading ||
      setTradeLoading ||
      takeProfitOrderLoading ||
      stopLossOrderLoading ||
      limitOrderLoading
    )
      return;
    setShow(false);
    dispatch(resetTradeData());
    dispatch(resetVirtualTradeState());
  };

  return (
    <Modal
      open={show}
      centered={true}
      footer={null}
      onCancel={cancelHandler}
      maskClosable={false}
      width={450}
      closable={
        !orderTradeLoading ||
        !setTradeLoading ||
        !takeProfitOrderLoading ||
        !stopLossOrderLoading ||
        !limitOrderLoading
      }
      style={{ overflow: "hidden" }}
      bodyStyle={{ minHeight: "200px" }}
      children={
        <div className={styles.steps}>
          {currentStep === "1-a" && (
            <Step1A step={currentStep} setShow={cancelHandler} show={show} />
          )}
          {/*<Step1A step={currentStep} setShow={setShow} />*/}
          {currentStep === "1-b" && (
            <Step1B step={currentStep} setShow={cancelHandler} />
          )}
          {currentStep === "1-d" && (
            <Step1D step={currentStep} setShow={cancelHandler} />
          )}
          {currentStep === "2" && (
            <Step2 step={currentStep} setShow={cancelHandler} />
          )}
          {currentStep === "result" && (
            <ResultMode step={currentStep} setShow={cancelHandler} />
          )}
          {currentStep === "1-c" && (
            <Step1C step={currentStep} setShow={cancelHandler} />
          )}
          {currentStep === "paper-2" && (
            <VirtualTradeStep2 setShow={cancelHandler} />
          )}
          {currentStep === "add-to-virtual" && (
            <AddToVirtualPortfolio setShow={cancelHandler} />
          )}
          {currentStep === "set-limit-order" && (
            <LimitOrderSetting setshow={cancelHandler} />
          )}
          {currentStep === "limit-order-result" && (
            <LimitOrderResult setShow={cancelHandler} step={currentStep} />
          )}
          {currentStep === "virtual-trade-result" && (
            <VirtualTradeResult setShow={cancelHandler} />
          )}
          {currentStep === "set-virtual-limit-order" && (
            <SetVirtualLimitOrder setShow={cancelHandler} />
          )}
          {currentStep === "add-to-virtual-confirmation" && (
            <AddToVirtualConfirmationDialog setShow={cancelHandler} />
          )}
          {currentStep === "virtual-limit-order-result" && (
            <VirtualLimitOrderResult setShow={cancelHandler} />
          )}
          {currentStep === "show-recommendation" && (
            <ShowRecommendation
              showCongrateMsg={false}
              setShow={cancelHandler}
            />
          )}
        </div>
      }
    />
  );
}

export default TradeModal;
