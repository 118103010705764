import React from "react";
import styled from "styled-components";

// Define the converter for the description text
const signalStrengthConverter = {
    1: "Faint",
    2: "Feeble",
    3: "Fair",
    4: "Robust",
    5: "Powerful",
};

// Define the colors for each level
const colors = {
    1: "#0C4CFC",
    2: "#0C4CFC",
    3: "#0C4CFC",
    4: "#0C4CFC",
    5: "#0C4CFC",
};

// Define a styled component for the timeline container
const Timeline = styled.div`
  display: flex;
  align-items: baseline;
  /* width: 100%; */
`;

// Define a styled component for the timeline row
const Row = styled.div`
  height: 8px;
  width: 4px;
  background-color: ${(props) => props.color};

  // The first child will have a height of 5px
  &:first-child {
    height: 5px;
  }

  // The second child will have a height of 7px
  &:nth-child(2) {
    height: 8px;
  }

  // The third child will have a height of 9px
  &:nth-child(3) {
    height: 11px;
  }

  // The fourth child will have a height of 11px
  &:nth-child(4) {
    height: 15px;
  }

  // The fifth child will have a height of 13px
  &:last-child {
    height: 19px;
  }

  &:not(:last-child) {
    margin-right: 3px;
  }

  @media (max-width: 480px) {
    width: 3px;
    &:not(:last-child) {
      margin-right: 2px;
    }

    &:first-child {
      height: 3px;
    }

    &:nth-child(2) {
      height: 6px;
    }

    &:nth-child(3) {
      height: 8px;
    }

    &:nth-child(4) {
      height: 11px;
    }

    &:last-child {
      height: 14px;
    }
  }
`;
// #0C4CFC

// Define a styled component for the description text
const Description = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-top: 0.5rem;
  /* display: flex;
  justify-content: center; */
  margin-bottom: 0 !important;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

// Define a function component that takes the level number as a prop
const SignalStrengthUi = ({level, width, showDescription = true}) => {
    // Create an array of five rows with different colors based on the level
    const rows = Array.from({length: 5}, (v, i) => {
        // If the index is less than the level, use the same color as the level
        if (i < level) {
            return <Row key={i} color={colors[level]}/>;
        }
        // Otherwise, use lightgray as the default color
        return <Row key={i} color="lightgray"/>;
    });

    const descriptionText = signalStrengthConverter[level];

    // Return the timeline component with the rows and the description text
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: width || "100%",
                alignItems: "center",
            }}
        >
            <Timeline>{rows}</Timeline>
            {showDescription && <Description color={colors[level]}>{descriptionText}</Description>}
        </div>
    );
};

export default SignalStrengthUi;
