import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Input, Button, Modal, message } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "../../../styles/Components/mini-app/auth/MiniAppLoginPage.module.css";
import {
  logInValidation,
  otpCodeValidation,
  otpEmailValidation,
} from "../../../utility/Validation/validation";
import { handleBlur } from "../../../utility/action";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { checkEmailException, formatUserData } from "../../../utility/helper";
import PoolData from "../../../pages/Auth/AWSConfig/PoolData";
import {
  sendOtpVerificationCode,
  setToken,
  setUserData,
  signinUpMiniApp,
  verifyOtpCode,
} from "../../../redux/main/action/main";
import axios from "axios";
import { notify } from "../../../utility/Toastify/toastify";
import { useDispatch, useSelector } from "react-redux";
import { routeConvertor } from "../../../utility/formatter-helper";
import MiniAppSignupPage from "./MiniAppSignupPage";
import MiniAppConfirmPage from "./MiniAppConfirmPage";
import ConfirmAlert from "../../../pages/Auth/ConfirmAlert";
import MiniAppResendConfirmationCode from "./MiniAppResendConfirmationCode";
import MiniAppOtpLogin from "./MiniAppOtpLogin";
import { FcGoogle } from "react-icons/fc";
import { FaGoogle } from "react-icons/fa";
import { FiArrowLeftCircle } from "react-icons/fi";

const GoogleButton = ({ onClick }) => {
  return (
    <div className={styles.googleBtn} onClick={onClick}>
      <FcGoogle /> login with Google
    </div>
  );
};

function MiniAppLoginPage() {
  const {
    main: { telegramData },
  } = useSelector((state) => state);
  const [authType, setAuthType] = useState("login");
  const [loading, setLoading] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  const [userEmail, setUserEmail] = useState("");
  const [pass, setPass] = useState("");
  const [loginType, setLoginType] = useState("otp");

  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get the value of the 'telegram_auth' parameter
  const telegramAuth = params.get("tgWebAppStartParam");

  // Get the value of the 'user' parameter
  const telegram_id = telegramData?.telegramId || telegramAuth?.slice(0, 20);
  const telegramId = telegram_id?.replace(/^0+/, "");

  const next = telegramData?.next || telegramAuth?.slice(-5);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(logInValidation),
    mode: "onBlur",
  });

  const navigateHandler = () => {
    navigate(`/${routeConvertor[next] || "mini-dashboard"}`);
  };

  const setAuthData = (data, authType = "cognito") => {
    dispatch(setToken(data?.idToken?.jwtToken));
    dispatch(
      setUserData(
        authType === "cognito"
          ? data?.idToken?.payload
          : formatUserData(data?.accessToken),
      ),
    );
    axios.defaults.headers.common["Authorization"] =
      authType === "cognito"
        ? `Bearer ${data?.idToken?.jwtToken}`
        : `${data?.idToken?.jwtToken}`;

    localStorage.setItem(
      "aws-amplify-federatedInfo",
      JSON.stringify({ token: data?.idToken?.jwtToken }),
    );
    localStorage.setItem(
      "aws-amplify-USERDATA",
      JSON.stringify(data?.idToken?.payload),
    );
    dispatch(
      signinUpMiniApp(
        telegramId,
        data?.idToken?.payload["custom:custom_username"],
        data?.idToken?.payload?.email,
      ),
    );
  };

  const handleLoginWithGoogle = () => {
    setAuthType("google");
  };

  const onSubmit = (data) => {
    if (loading) return;
    setLoading(true);

    setUserEmail(data?.email.toLowerCase());
    setPass(data?.pass);

    let user = new CognitoUser({
      Username: checkEmailException(data?.email),
      Pool: PoolData,
    });
    let authDetails = new AuthenticationDetails({
      Username: data?.email,
      Password: data?.pass,
      ValidationData: { captcha: "rtspa_mehdi_ravanbakhsh_761" },
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        localStorage.setItem("telegramData", telegramAuth);
        setAuthData(data);
        setLoading(false);
        // navigate("/ai-assistant/assistant");
        navigateHandler();
      },

      onFailure: function (err) {
        setLoading(false);
        console.log(err);
        if (err?.message === "User is not confirmed.") {
          setShowConfirmAlert(true);
          return;
        }
        // message.error(err?.message);
        Modal.error({
          title: err?.message,
          centered: true,
          okButtonProps: {
            style: {
              backgroundColor: "#0c3fce",
              borderColor: "#0c3fce",
            },
          },
        });
      },

      newPasswordRequired: (data) => {
        notify(data, "success");
        setLoading(false);
      },
    });
  };

  return (
    <div className={styles.container}>
      {authType === "login" && (
        <ul className={styles.loginOptions}>
          <li
            className={`${styles.loginOption} ${
              loginType === "otp" ? styles.active : ""
            }`}
            onClick={() => setLoginType("otp")}
          >
            Email / OTP
          </li>
          <li
            className={`${styles.loginOption} ${
              loginType === "email" ? styles.active : ""
            }`}
            onClick={() => {
              setLoginType("email");
            }}
          >
            Email / Password
          </li>
        </ul>
      )}

      {authType === "login" && loginType === "email" && (
        <Form onFinish={handleSubmit(onSubmit)}>
          <Form.Item
            label="Email"
            validateStatus={errors.email ? "error" : ""}
            help={errors.email ? errors.email.message : ""}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="email"
                  onBlur={(e) => {
                    field.onBlur(e);
                    handleBlur(e);
                  }}
                  autoComplete="email"
                />
              )}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            validateStatus={errors.pass ? "error" : ""}
            help={errors.pass ? errors.pass.message : ""}
          >
            <Controller
              name="pass"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="password"
                  onBlur={(e) => {
                    field.onBlur(e);
                    handleBlur(e);
                  }}
                  autoComplete="current-password"
                />
              )}
            />
          </Form.Item>

          <Form.Item
            style={{ alignSelf: "center", marginTop: "2rem", width: "100%" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className={styles.submitBtn}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      )}

      {authType === "login" && loginType === "otp" && (
        <MiniAppOtpLogin setAuthData={setAuthData} navigate={navigateHandler}>
          <GoogleButton onClick={handleLoginWithGoogle} />{" "}
          <span
            className={styles.createAccountBtn}
            onClick={() => setAuthType("signup")}
          >
            Create Account
          </span>
          <p
            className={styles.switchToLoginBtn}
            onClick={() => setAuthType("resend-confirmation")}
          >
            Didn't confirm account? <span>Confirm</span>
          </p>
        </MiniAppOtpLogin>
      )}

      {authType === "google" && (
        <>
          <FiArrowLeftCircle
            size={20}
            className={styles.backBtn}
            onClick={() => setAuthType("login")}
          />
          <MiniAppOtpLogin
            setAuthData={setAuthData}
            navigate={navigateHandler}
            isGoogleLogin={true}
          />
        </>
      )}

      <ConfirmAlert
        show={showConfirmAlert}
        setShow={setShowConfirmAlert}
        email={userEmail}
        password={pass}
        telegramId={telegramId}
        navigateHandler={navigateHandler}
      />

      {authType === "signup" && <MiniAppSignupPage setAuthType={setAuthType} />}
      {authType === "confirm" && (
        <MiniAppConfirmPage
          setAuthType={setAuthType}
          navigate={navigateHandler}
        />
      )}
      {authType === "resend-confirmation" && (
        <MiniAppResendConfirmationCode setAuthType={setAuthType} />
      )}
    </div>
  );
}

export default MiniAppLoginPage;
