import { Table } from "antd";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { formatPrice, transformObject } from "../../../utility/helper";
import styles from "../../../styles/Components/tables/portfolioTable.module.sass";
import ColumnTitle from "../../tables/trade-assistant-table/ColumnTitle";
import SignalStrengthUi from "../../trading-assistant/SignalStrengthUi";
import { CgMoreO } from "react-icons/cg";

function InsightSignalsTable() {
  const {
    newInsightState: {
      selectedFeatures,
      processedFeaturesList,
      currentSignalIndex,
      currentSignalData,
      getInitialDataLoading,
    },
  } = useSelector((state) => state);

  const signals = currentSignalData?.SIGNALS;
  const [tableData, setTableData] = useState([]);

  const columns = [
    {
      title: <span title="Suggested trading action">Signal</span>,
      dataIndex: "type",
      key: "type",
      width: 80,
      align: "center",
      responsive: ["xs", "sm"], // show only on small devices
      render: (text) => (
        <span
          className={`${
            text?.action === "Buy"
              ? styles.buy
              : text?.action === "Sell"
              ? styles.sell
              : styles.holdBuy
          }`}
          style={{ cursor: "pointer" }}
          // onClick={() => setTradeHandler(text)}
        >
          {text?.action === "Sell"
            ? "Sell/Short"
            : text.action === "Buy"
            ? "Buy/Long"
            : text.action}
        </span>
      ),
    },
    // {
    //   title: "Market",
    //   dataIndex: "marketType",
    //   key: "marketType",
    //   width: 100,
    //   align: "center",

    //   render: (text) => <span className={styles.tableContent}>{text}</span>,
    // },
    {
      title: "Coin",
      dataIndex: "coin",
      key: "coin",
      width: 85,
      align: "center",
      responsive: ["xs", "sm"],

      render: (item) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            color: "#0c4cfc",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          className={styles.tableContent}
          // onClick={() => setTradeHandler(item)}
        >
          <img
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${item?.target_coin}.png`}
            alt={item?.target_coin}
          />
          {item?.target_coin}
        </span>
      ),
    },
    // {
    //   title: (
    //     <span title={"Analytical category guiding the trading decision"}>
    //       Category
    //     </span>
    //   ),
    //   dataIndex: "category",
    //   key: "category",
    //   align: "center",
    //   width: 120,
    //   responsive: ["lg"], // hide on small and medium devices
    //   render: (text) => (
    //     <span className={styles.tableContent}>
    //       {text
    //         ? text === "On-Chain"
    //           ? text
    //           : text.replace(/-/g, " ")
    //         : "No Category"}
    //     </span>
    //   ),
    // },
    // {
    //   title: (
    //     <span title={"Real-time value of the cryptocurrency in the market"}>
    //       Current Price
    //     </span>
    //   ),
    //   dataIndex: "currentPrice",
    //   key: "currentPrice",
    //   width: 110,
    //   align: "center",
    //   responsive: ["sm"], // hide on small and medium devices
    //
    //   render: (text) => (
    //     <span className={styles.tableContent}>${formatPrice(text, 3)}</span>
    //   ),
    // },
    {
      // title: (
      //   <ColumnTitle
      //     title={
      //       <span title={"Protection level to minimize potential losses"}>
      //         Stop Loss
      //       </span>
      //     }
      //     // mode={isPercent}
      //     // toggleMode={setIsPercent}
      //   />
      // ),
      title: "Stop Loss",
      dataIndex: "stopLoss",
      key: "stopLoss",
      width: 110,
      align: "center",
      responsive: ["lg"], // hide on small and medium devices

      render: (text, record) => (
        <span className={styles.tableContent}>
          {/*{isPercent*/}
          {/*  ? `${formatPrice(record?.stopLossPercent, 2)}%`*/}
          {/*  : `$${formatPrice(text, 3)}`}*/}
          {text}
        </span>
      ),
    },
    {
      // title: (
      //   <ColumnTitle
      //     title={
      //       <span title={"Targeted level for securing trading gains"}>
      //         Take Profit
      //       </span>
      //     }
      //     // mode={isPercent}
      //     // toggleMode={setIsPercent}
      //   />
      // ),
      title: "Take Profit",
      dataIndex: "takeProfit",
      key: "takeProfit",
      // sorter: (a, b) => a.takeProfit - b.takeProfit,
      width: 110,
      align: "center",
      responsive: ["md"], // hide on small and medium devices

      render: (text, record) => (
        <span className={styles.tableContent}>
          {text}
          {/*{isPercent*/}
          {/*  ? `${formatPrice(record?.takeProfitPercent, 2)}%`*/}
          {/*  : `$${formatPrice(text, 3)}`}*/}
        </span>
      ),
    },
    // {
    //   title: (
    //     <span title={"Anticipated profit or loss percentage from the trade"}>
    //       Recent Return
    //     </span>
    //   ),
    //   dataIndex: "expectedReturn",
    //   key: "expectedReturn",
    //   sorter: (a, b) => a.expectedReturn - b.expectedReturn,
    //   width: 100,
    //   align: "center",
    //   responsive: ["xs", "sm"], // show only on small devices
    //
    //   render: (text) => <span className={styles.tableContent}>{text}%</span>,
    // },
    // {
    //   title: (
    //     <span
    //       title={
    //         "Confidence level in the accuracy of the provided trading recommendation"
    //       }
    //     >
    //       Strength
    //     </span>
    //   ),
    //   dataIndex: "SIGNAL_STRENGTH",
    //   key: "SIGNAL_STRENGTH",
    //   sorter: (a, b) => a.SIGNAL_STRENGTH - b.SIGNAL_STRENGTH,
    //   width: 100,
    //   align: "center",
    //   responsive: ["sm"], // hide on small and medium devices
    //
    //   render: (text) => (
    //     <span className={styles.tableContent}>
    //       <SignalStrengthUi level={text} />
    //     </span>
    //   ),
    // },
    // {
    //   title: "Details",
    //   dataIndex: "details",
    //   key: "details",
    //   width: 80,
    //   align: "center",
    //   responsive: ["xs", "sm"], // show only on small devices
    //   render: (item) => (
    //     <span
    //       style={{
    //         color: "blue",
    //         textDecoration: "underline",
    //         cursor: "pointer",
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //       onClick={() => showDetailsHandler(item)}
    //     >
    //       <CgMoreO style={{ marginRight: ".3rem" }} />
    //       More
    //     </span>
    //   ),
    // },
  ];

  // return <Table />;
  useEffect(() => {
    if (Object.keys(currentSignalData).length) {
      setTableData(
        transformObject(signals)?.map((signal, index) => {
          return {
            key: index,
            // marketType: signal?.MARKET_TYPE,
            coin: signal,
            // expectedReturn: signal?.EXPECTED_RETURN,
            type: signal,
            // SIGNAL_STRENGTH: signal?.SIGNAL_STRENGTH,
            buyNow: signal,
            // category: signal?.CATEGORY_INFO.category,
            // currentPrice: signal?.CURRENT_PRICE,
            stopLoss: signal?.stop_loss_percent,
            takeProfit: signal?.take_profit_percent,
            details: signal,
          };
        }),
      );
    }
  }, [currentSignalData, signals, currentSignalIndex]);

  return (
    <div>
      table
      {console.log(signals)}
      {/*{console.log(tableData, signals)}*/}
      <Table
        columns={columns}
        className={styles.table}
        dataSource={tableData}
        scroll={{ y: 700 }}
        pagination={false}
        loading={getInitialDataLoading}
      />
    </div>
  );
}

export default InsightSignalsTable;
