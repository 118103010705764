import React, { useEffect, useState } from "react";
import styles from "../../styles/Components/payment/stripe.module.sass";
import { Badge, Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { GoCalendar } from "react-icons/go";
import { getCheckoutLink } from "../../redux/pricing-plan/action";

function Stripe({ show, hide, data, type, setPlan, disabledPlan }) {
  // const [planType, setPlanType] = useState(
  //   disabledPlan === "any"
  //     ? type
  //     : disabledPlan === "monthly"
  //     ? "annually"
  //     : "monthly",
  // );
  const [planType, setPlanType] = useState(null);

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.main?.userData);
  const { checkoutSession, checkoutLoading, pricingPlan } = useSelector(
    (state) => state.pricingPlan,
  );

  const [lastPlanType, setLastPlanType] = useState(null); // A state variable to store the last planType

  useEffect(() => {
    if (userData?.email && planType) {
      const redirectUrl = window.location.href;
      dispatch(
        getCheckoutLink(
          userData["cognito:username"],
          userData?.["custom:custom_username"],
          planType === "monthly" ? planType : "yearly",
          redirectUrl,
          redirectUrl,
        ),
      );
    }
  }, [dispatch, planType, userData]);

  // useEffect(() => {
  //   if (userData["cognito:username"]) {
  //     // Only call getCheckoutLink if planType is different from lastPlanType
  //     if (lastPlanType !== planType) {
  //       const redirectUrl = window.location.href;
  //       dispatch(
  //         getCheckoutLink(
  //           userData["cognito:username"],
  //           userData?.["custom:custom_username"],
  //           planType === "monthly" ? planType : "yearly",
  //           redirectUrl,
  //           redirectUrl,
  //         ),
  //       );
  //       setLastPlanType(planType); // Update the lastPlanType state
  //     }
  //   }
  // }, [userData, planType, dispatch, lastPlanType]);

  // useEffect(() => {
  //     setAmount(data?.pricingPlanMonthly?.price);
  // }, [data]);

  useEffect(() => {
    if (type) {
      setPlanType(type);
    }
  }, [type]);

  const handleCancel = () => {
    hide(false);
  };
  return (
    <Modal
      title="Select plan"
      onCancel={handleCancel}
      maskClosable={false}
      open={show}
      onOk={false}
      footer={false}
      width={600}
      centered
    >
      <div className={styles.plans}>
        <div
          style={{
            cursor: `${disabledPlan === "monthly" ? "not-allowed" : "pointer"}`,
          }}
          onClick={() => {
            if (disabledPlan === "monthly") return;
            // if (planType === "annually") return;
            setPlanType("monthly");
            if (setPlan) {
              setPlan("monthly");
            }
          }}
        >
          <div className={planType === "monthly" ? styles.active : ""}>
            <span>
              <GoCalendar /> Monthly
            </span>
            <span>
              {data?.pricingPlanMonthly?.price} <span>USD</span>
            </span>
          </div>
        </div>
        <Badge.Ribbon
          text={`${pricingPlan["yearly_discount"]}% OFF`}
          size={"large"}
        >
          <div
            style={{
              cursor: `${
                disabledPlan === "annually" ? "not-allowed" : "pointer"
              }`,
            }}
            onClick={() => {
              if (disabledPlan === "annually") return;
              // if (planType === "monthly") return;
              setPlanType("annually");
              setPlan("annually");
            }}
            className={planType === "annually" ? styles.active : ""}
          >
            <span>
              <GoCalendar />
              Annually
            </span>
            <span>
              {data?.pricingPlanAnnual?.price} <span>USD</span>{" "}
            </span>
          </div>
        </Badge.Ribbon>
      </div>
      {/* <Elements stripe={stripePromise}>
        <CheckoutForm hide={hide} amount={Number(amount)?.toFixed(2)} />
      </Elements> */}
      {userData["cognito:username"] ? (
        checkoutLoading ? (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Spin tip="Please wait" />
          </div>
        ) : (
          planType && (
            <a
              className={styles.button}
              href={checkoutSession || ""}
              target="_blank"
              rel="noreferrer"
              style={{
                opacity: `${!checkoutSession ? ".8" : 1}`,
                cursor: `${!checkoutSession ? "not-allowed" : "pointer"}`,
              }}
            >
              Buy now
            </a>
          )
        )
      ) : (
        ""
      )}
    </Modal>
  );
}

export default Stripe;
