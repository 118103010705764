import DashboardLayout from "../../components/layout/DashboardLayout";
import styles from "../../styles/pages/new-insight/NewInsight.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getInsightInitialDataNew,
  setCurrentSignalData,
  setCurrentSignalIndex,
} from "../../redux/new-insight/actions";
import InsightSignalsSection from "../../components/new-insight/InsightSignalsSection";

function NewInsight() {
  const {
    main: { userData },
    newInsightState: {
      getInitialDataLoading,
      overallStatistics,
      categories,
      categoriesFeatureTree,
      processedFeaturesList,
      processedFeaturesData,
      getInitialDataError,
      selectedFeatures,
      initialDataFetched,
      insightMarketType,
      currentSignalIndex,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData?.email && !initialDataFetched && !getInitialDataLoading) {
      dispatch(
        getInsightInitialDataNew(
          userData?.email,
          userData["custom:custom_username"],
          insightMarketType,
        ),
      );
    }
  }, [dispatch, getInitialDataLoading, initialDataFetched, userData]);

  useEffect(() => {
    if (processedFeaturesData.length > 0) {
      dispatch(setCurrentSignalIndex(processedFeaturesData.length - 1));
    }
  }, [dispatch, processedFeaturesData]);

  useEffect(() => {
    if (currentSignalIndex) {
      dispatch(setCurrentSignalData(processedFeaturesData[currentSignalIndex]));
    }
  }, [currentSignalIndex, dispatch, processedFeaturesData]);

  return (
    <DashboardLayout hasDemo>
      <div className={styles.main}>
        <InsightSignalsSection />
      </div>
    </DashboardLayout>
  );
}

export default NewInsight;
