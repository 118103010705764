// STYLES
import { Button, Dropdown, Menu } from "antd";
import styles from "../../styles/Components/insight/Navbar.module.css";

// ICONS
import forexIcon from "../../asset/Icons/forexIcon.png";
import stockIcon from "../../asset/Icons/stockIcon.png";
import chartIcon from "../../asset/Icons/chartIcon.png";
import signalsIcon from "../../asset/Icons/signals.png";
import trendsIcon from "../../asset/Icons/trends.png";
import cryptoIcon from "../../asset/Icons/cryptoIcon.png";
import { FiChevronDown } from "react-icons/fi";
import asx from "../../asset/Icons/asx1.png";

function Navbar({ coin, changeMarket, content, changeContent }) {
  const handleClick = (e) => {
    if (e.target.closest("li")) changeContent(+e.target.closest("li").id);
  };

  const menu = (
    <Menu onClick={(e) => changeMarket(e.key)}>
      <Menu.Item className={styles.menuItem} key="crypto">
        <img className={styles.menuIcon} src={cryptoIcon} alt="crypto" />
        Crypto
      </Menu.Item>
      <Menu.Item className={styles.menuItem} key="forex">
        <img className={styles.menuIcon} src={forexIcon} alt="forex" />
        Forex
      </Menu.Item>
      <Menu.Item className={styles.menuItem} key="asx">
        <img className={styles.menuIcon} src={stockIcon} alt="stock" />
        <img
          style={{ width: "17px", marginLeft: ".3rem" }}
          src={asx}
          alt="asx"
        />{" "}
        Stock
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={styles.container}>
      <Dropdown overlay={menu}>
        <Button>
          Market{" "}
          <img
            src={`${
              coin === "crypto"
                ? cryptoIcon
                : coin === "forex"
                ? forexIcon
                : stockIcon
            }`}
            alt={coin}
            className={styles.marketIcon}
          />{" "}
          <FiChevronDown />
        </Button>
      </Dropdown>
      <ul className={styles.navList} onClick={handleClick}>
        <li
          className={`${styles.navItem} ${content === 1 ? styles.active : ""}`}
          id="1"
          title="Buy and sell suggestions, Profitability analytics"
        >
          <img src={signalsIcon} alt="signals" className={styles.navIcon} />
          Signals
        </li>
        <li className={styles.separtor}></li>
        <li
          className={`${styles.navItem} ${content === 2 ? styles.active : ""}`}
          id="2"
          title="Historical Visualization"
        >
          <img src={chartIcon} alt="chart" className={styles.navIcon} />
          Chart
        </li>
        <li className={styles.separtor}></li>
        <li
          className={`${styles.navItem} ${content === 3 ? styles.active : ""}`}
          id="3"
          title="Top 20 symbols, Rising and falling symbols"
        >
          <img src={trendsIcon} alt="Trends" className={styles.navIcon} />
          Trends
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
