import { useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

function Chart({
  chartData,
  setChartData,
  signalNumber,
  selectedFeatures,
  signalRange,
}) {
  const [test, setTest] = useState(false);
  // const [min, setMin] = useState(0)
  // const [max, setMax] = useState(0)

  let indexOfName = 0;
  // useEffect(() => {
  //     const valuesArr = chartData.map(item => item.map(itm => itm.value))
  //     let newArr = [];
  //     valuesArr.map(item => {
  //         newArr.push(...item)
  //     })
  //     setMin(Math.min(...newArr));
  //     setMax(Math.max(...newArr));
  // }, [min, max, chartData])

  useLayoutEffect(() => {
    var root = am5.Root.new("chartdiv");
    root._logo.dispose();

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      }),
    );

    var easing = am5.ease.linear;
    chart
      .get("colors")
      .set("colors", [
        am5.color("#1ecafa"),
        am5.color("#1fc219"),
        am5.color("#db2e5f"),
      ]);

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: "minute",
          count: 30,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    function createAxisAndSeries(opposite, index) {
      var yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: opposite,
      });
      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 1,
          renderer: yRenderer,
          min: signalRange[indexOfName]?.[0],
          max: signalRange[indexOfName]?.[1],
        }),
      );

      // if (chart.yAxes.indexOf(yAxis) > 0) {
      //     yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
      // }

      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          name: selectedFeatures[indexOfName]?.feature,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{valueY}",
          }),
        }),
      );
      indexOfName = indexOfName + 1;

      // series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
      series.strokes.template.setAll({ strokeWidth: 3 });

      yRenderer.grid.template.set("strokeOpacity", 0.05);
      yRenderer.labels.template.set("fill", series.get("fill"));
      yRenderer.setAll({
        stroke: series.get("fill"),
        strokeOpacity: 3,
        strokeWidth: 1,
        opacity: 1,
      });

      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["date"],
      });

      series.data.setAll(generateChartData(index));
    }

    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: "none",
      }),
    );
    cursor.lineY.set("visible", true);

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      }),
    );

    if (signalNumber > 0) {
      createAxisAndSeries(false, 0);
    }
    if (signalNumber > 1) {
      createAxisAndSeries(true, 1);
    }
    if (signalNumber > 2) {
      createAxisAndSeries(true, 2);
    }
    chart.appear(1000, 100);

    function generateChartData(index) {
      var data = [];

      chartData[index]?.map((item, i) => {
        data.push({
          date: item?.date,
          value: item.value,
        });
      });

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          align: "left",
          valign: "bottom",
        }),
        dataSource: data,
      });
      return data;
    }

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        y: am5.percent(5),
      }),
    );
    legend.data.setAll(chart.series.values);

    let eles = document.querySelectorAll("[aria-labelledby$=-title]");
    eles.forEach((ele) => {
      ele.style.visibility = "hidden !important";
    });

    return () => {
      if (root) {
        root.dispose();
      }
    };
  }, [test]);

  return (
    <>
      <div
        id="chartdiv"
        style={{ width: "calc(100% - 10px)", height: "650px" }}
      ></div>
    </>
  );
}

export default Chart;
