import { Button } from "antd";
import { useState } from "react";
import FeatureCategoriesModal from "./FeatureCategoriesModal";
import InsightSelectedFeatures from "./signals-section/InsightSelectedFeatures";
import { useSelector } from "react-redux";
import styles from "../../styles/Components/new-insight/InsightSignalsSection.module.css";
import { FaCheckSquare } from "react-icons/fa";
import InsightSignalsTable from "./signals-section/InsightSignalsTable";

function InsightSignalsSection() {
  const {
    newInsightState: { selectedFeatures, processedFeaturesList },
  } = useSelector((state) => state);

  const [showFeaturesModal, setShowFeaturesModal] = useState(false);
  return (
    <div className={styles.container}>
      <Button
        className={styles.featuresBtn}
        onClick={() => setShowFeaturesModal(true)}
      >
        Features
      </Button>

      {selectedFeatures.length > processedFeaturesList.length && (
        <div className={styles.selectedFeatures}>
          <h3>Selected Features:</h3>
          <InsightSelectedFeatures type={"selected"} />
        </div>
      )}
      <div>
        <h3 className={styles.featuresBoxTitle}>
          <FaCheckSquare size={18} color={"#03C988"} />
          Processed Features:
        </h3>
        <InsightSelectedFeatures type={"processed"} />
      </div>

      <InsightSignalsTable />

      <FeatureCategoriesModal
        show={showFeaturesModal}
        closeHandler={() => setShowFeaturesModal(false)}
        type={"selectFeature"}
      />
    </div>
  );
}

export default InsightSignalsSection;
