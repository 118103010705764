import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, message, Tabs } from "antd";
import {
  handleStopLossSelection,
  handleTakeProfitSelection,
  setStep,
  setVirtualAssetAmount,
  setVirtualRemainingBudget,
} from "../../../../redux/trade/actions";
import styles from "../../../../styles/shared/trade-modal/Step.module.css";
import { formatAmount, formatAssetPrice } from "../../../../utility/helper";
import { AiFillCaretRight } from "react-icons/ai";
import {
  getPrice,
  setLimitOrder,
} from "../../../../redux/trading-assistant/actions";
import SetVirtualLimitAmount from "./SetVirtualLimitAmount";
import SetVirtualLimitPrice from "./SetVirtualLimitPrice";
import FeeSettingModal from "./FeeSettingModal";
import { IoClose } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";

const { TabPane } = Tabs;

function SetVirtualLimitOrder({ setShow }) {
  const {
    main: { userData },
    tradeState: {
      virtualSelectedAsset,
      virtualMarketType,
      virtualSelectedPortfolio,
      virtualAssetAmount,
      isStopLossChecked,
      isTakeProfitChecked,
      stopLossAmountPercent,
      takeProfitAmountPercent,
      stopLossTriggerPricePercent,
      takeProfitTriggerPricePercent,
      virtualTradeAmount,
    },
    tradingAssistant: {
      getPriceLoading,
      price,
      getPortfolioDataLoading,
      limitOrderLoading,
    },
    profile: {
      profileData: { transaction_fee: fee },
      loading,
    },
  } = useSelector((state) => state);

  const [isDone, setIsDone] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [showFeeModal, setShowFeeModal] = useState(false);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("");

  const handleTabChange = (key) => {
    if (getPriceLoading || getPortfolioDataLoading) {
      return message.info(" Please wait to load data");
    }
    if (key === "takeProfit") {
      dispatch(handleTakeProfitSelection(true));
    }
    if (key === "stopLoss") {
      dispatch(handleStopLossSelection(true));
    }
    setActiveTab(key);
  };

  const handleCheckboxChange = (type) => {
    if (getPriceLoading || getPortfolioDataLoading) {
      return message.info(" Please wait to load data");
    }
    if (type === "stopLoss") {
      dispatch(handleStopLossSelection(!isStopLossChecked));
    }
    if (type === "takeProfit") {
      dispatch(handleTakeProfitSelection(!isTakeProfitChecked));
    }
  };

  const setOrderHandler = async () => {
    if (!isDone) return;
    if (getPriceLoading || loading) return;
    if (!fee) {
      setShowFeeModal(true);
      return;
    }

    const limitTypes = [];
    const limitAmounts = [];
    const limitPrices = [];

    if (isTakeProfitChecked) {
      limitTypes.push("take_profit");
      limitAmounts.push(`${takeProfitAmountPercent}`);
      limitPrices.push(`${takeProfitTriggerPricePercent}`);
    }

    if (isStopLossChecked) {
      limitTypes.push("stop_loss");
      limitAmounts.push(`${stopLossAmountPercent}`);
      limitPrices.push(`${stopLossTriggerPricePercent}`);
    }
    dispatch(
      setLimitOrder(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        virtualSelectedPortfolio.toLowerCase(),
        virtualMarketType,
        virtualSelectedAsset,
        limitTypes,
        limitAmounts,
        limitPrices,
        "show",
      ),
    );

    dispatch(setStep("virtual-limit-order-result"));
  };

  useEffect(() => {
    if (!isTakeProfitChecked && !isStopLossChecked) {
      setIsDone(false);
    }
  }, [isTakeProfitChecked, isStopLossChecked]);

  useEffect(() => {
    if (!limitOrderLoading && virtualSelectedAsset) {
      dispatch(
        getPrice(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          virtualSelectedAsset,
          virtualMarketType,
        ),
      );

      // Fetch price data every minute
      const intervalId = setInterval(() => {
        if (virtualSelectedAsset) {
          dispatch(
            getPrice(
              userData?.["cognito:username"],
              userData?.["custom:custom_username"],
              virtualSelectedAsset,
              virtualMarketType,
            ),
          );
        }
      }, 60000); // 60000 milliseconds = 1 minute

      // Clean up the interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [
    dispatch,
    limitOrderLoading,
    userData,
    virtualMarketType,
    virtualSelectedAsset,
  ]);

  useEffect(() => {
    if (price && !getPriceLoading && !getPortfolioDataLoading && !activeTab) {
      setActiveTab("stopLoss");
    }
  }, [price, getPriceLoading, getPortfolioDataLoading, activeTab]);

  useEffect(() => {
    if (price && !getPriceLoading && !updateData) {
      dispatch(
        setVirtualAssetAmount(
          price?.CURRENT_NATIVE_AMOUNT[virtualSelectedPortfolio] ||
            virtualTradeAmount,
        ),
      );
      dispatch(
        setVirtualRemainingBudget(
          price?.CURRENT_INVESTMENT_BUDGET[virtualSelectedPortfolio],
        ),
      );
      setUpdateData(true);
    }
  }, [price, getPriceLoading, updateData]);

  return (
    <div className={limitOrderLoading ? styles.inactive : ""}>
      <div className={styles.assetInfo}>
        <div>
          <h4>Asset Name:</h4> <p>{virtualSelectedAsset}</p>
        </div>
        <div>
          <h4>Current Price:</h4>{" "}
          {getPriceLoading ? (
            <p>Loading...</p>
          ) : (
            <p>$ {formatAssetPrice(price?.PRICE)}</p>
          )}
        </div>
        <div>
          <h4>Native Amount:</h4>{" "}
          <p>
            {getPortfolioDataLoading
              ? "loading..."
              : formatAmount(virtualAssetAmount)}
          </p>
        </div>
      </div>
      <h3>
        <AiFillCaretRight />
        What limit order type you want? <br />
        <p>
          <strong style={{ marginRight: ".3rem" }}>Note:</strong>You can set
          both of them.
        </p>
      </h3>

      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        tabPosition="top"
        type="card"
        onChange={handleTabChange}
      >
        <TabPane
          tab={
            <span>
              Stop Loss{" "}
              {isStopLossChecked &&
                !getPriceLoading &&
                !getPortfolioDataLoading && <FaCheck color={"green"} />}
            </span>
          }
          key="stopLoss"
        >
          <Checkbox
            checked={isStopLossChecked}
            onChange={() => handleCheckboxChange("stopLoss")}
          >
            Stop loss
          </Checkbox>
          {isStopLossChecked && (
            <div className={styles.input} style={{ margin: "1rem 0 0" }}>
              <label>Amount:</label>
              <SetVirtualLimitAmount
                type="stopLoss"
                isSelect={isStopLossChecked}
              />
              <label>Trigger price:</label>
              <SetVirtualLimitPrice
                type="low"
                setIsDone={setIsDone}
                className={styles.priceSetting}
              />
            </div>
          )}
        </TabPane>

        <TabPane
          tab={
            <span>
              Take Profit{" "}
              {isTakeProfitChecked &&
                !getPriceLoading &&
                !getPortfolioDataLoading && <FaCheck color={"green"} />}
            </span>
          }
          key="takeProfit"
        >
          <Checkbox
            checked={isTakeProfitChecked}
            onChange={() => handleCheckboxChange("takeProfit")}
          >
            Take profit
          </Checkbox>
          {isTakeProfitChecked && (
            <div className={styles.input} style={{ margin: "1rem 0 0" }}>
              <label>Amount:</label>
              <SetVirtualLimitAmount
                type="takeProfit"
                isSelect={isTakeProfitChecked}
              />
              <label>Trigger price:</label>
              <SetVirtualLimitPrice
                type="high"
                setIsDone={setIsDone}
                className={styles.priceSetting}
              />
            </div>
          )}
        </TabPane>
      </Tabs>

      <div
        className={styles.btnContainer}
        style={{ marginTop: "1rem", gap: "1rem" }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            color: "#2b5fec ",
            gap: ".3rem",
            padding: "0 .3rem",
            borderBottom: "1px solid #2b5fec",
            cursor: "pointer",
          }}
          onClick={() => {
            // setShow();
            dispatch(setStep("show-recommendation"));
          }}
        >
          Cancel <IoClose />
        </span>
        <Button
          // loading={limitOrderLoading}
          type="primary"
          htmlType="submit"
          className={`${styles.submitBtn} ${!isDone ? styles.inactive : ""}`}
          onClick={setOrderHandler}
        >
          Place Order
        </Button>
      </div>
      <FeeSettingModal
        show={showFeeModal}
        setShow={setShowFeeModal}
        setTrade={setOrderHandler}
      />
    </div>
  );
}

export default SetVirtualLimitOrder;
