import * as type from "../consts";
import PoolData from "../../../pages/Auth/AWSConfig/PoolData";
import axios from "axios";
import { axiosErrorHandler } from "../../../utility/action-helper";
import {
  OTP_SEND_CODE_ERROR,
  OTP_SEND_CODE_REQUEST,
  OTP_SEND_CODE_SUCCESS,
  OTP_VERIFY_CODE_ERROR,
  OTP_VERIFY_CODE_REQUEST,
  OTP_VERIFY_CODE_SUCCESS,
} from "../consts";
import { formatUserData } from "../../../utility/helper";

export const setToken = (data) => (dispatch) => {
  dispatch({
    type: type.SET_TOKEN,
    payload: data,
  });
};

export const setRefreshToken = (data) => (dispatch) => {
  localStorage.setItem("REFRESH_TOKEN", data);
  dispatch({
    type: type.SET_REFRESH_TOKEN,
    payload: data,
  });
};

export const setSystemData = (data) => (dispatch) => {
  localStorage.setItem("SYSTEM_DATA", JSON.stringify(data));
  dispatch({
    type: type.SET_SYSTEM_DATA,
    payload: data,
  });
};

export const setUserData = (data) => (dispatch) => {
  // console.log(data);
  localStorage.setItem("aws-amplify-USERDATA", JSON.stringify(data));
  dispatch({
    type: type.SET_USERDATA,
    payload: data,
  });
};

export const setDataAsLocal = () => (dispatch) => {
  dispatch({
    type: type.SET_DATA_AS_LOCAL,
    payload: {
      token: localStorage.getItem("TOKEN"),

      systemData: JSON.parse(localStorage.getItem("SYSTEM_DATA")),

      userData: JSON.parse(localStorage.getItem("USER_DATA")),
    },
  });
};

export const setLogout = () => (dispatch) => {
  // localStorage.setItem("lastLogout", JSON.stringify(new Date()));
  // localStorage.removeItem("aws-amplify-federatedInfo");
  // localStorage.removeItem("aws-amplify-USERDATA");
  localStorage.clear();
  localStorage.setItem("lastLogout", JSON.stringify(new Date()));
  // dispatch(setLogout());
  const user = PoolData.getCurrentUser();
  user?.signOut();
  window.location.href = "/auth/login";
  window.location.reload();
  dispatch({
    type: type.SET_LOGOUT,
    payload: new Date(),
  });
};

export const saveUserPass = (data) => {
  return { type: type.SAVE_USER_PASS, payload: data };
};

export const setLoginProcessToTelegram = () => {
  // "set-login-process-to-telegram"
  return { type: "set-login-process-to-telegram" };
};

export const signinUpMiniApp =
  (telegram_id, user, email, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: "signin_up_mini_app_request" });
    await axios
      .post(
        "https://0znr1xesf9.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          operation_mode: "signin_up_miniapp",
          telegram_id,
          user,
          email,
        },
      )
      .then((res) => {
        console.log(res);
        dispatch({ type: "signin_up_mini_app_success" });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => signinUpMiniApp(telegram_id, user, email, retryCount + 1),
          "signin_up_mini_app_error",
          user,
          email,
          "signin_up_miniapp",
        );
      });
  };
export const saveTelegramAuthData = (data) => (dispatch) => {
  const telegramId = data.slice(0, 20);
  const userName = data.slice(20, 25);
  const email = data.slice(25, 30);
  const next = data.slice(-5);
  const payload = {
    telegramId,
    userName,
    email,
    next,
  };

  localStorage.setItem("telegramAuthData", JSON.stringify(payload));
  dispatch({ type: "save-telegram-data", payload });
};

export const sendOtpVerificationCode =
  (email, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: OTP_SEND_CODE_REQUEST });

    await axios
      .post(
        "https://weu658vyjj.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          operation_mode: "send_verification_code",
          email,
        },
      )
      .then((res) => {
        console.log(res);
        dispatch({ type: OTP_SEND_CODE_SUCCESS, payload: res?.data?.message });
      })
      .catch((err) => {
        console.log(err);
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => sendOtpVerificationCode(email, retryCount + 1),
          OTP_SEND_CODE_ERROR,
          "user",
          email,
          "send_verification_code",
        );
      });
  };

export const verifyOtpCode =
  (email, verification_code, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: OTP_VERIFY_CODE_REQUEST });

    await axios
      .post(
        "https://weu658vyjj.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          operation_mode: "verify_verification_code",
          email,
          verification_code,
        },
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: OTP_VERIFY_CODE_SUCCESS,
          payload: res?.data,
        });
        // dispatch(setToken(res?.data?.accessToken?.jwtToken));
        // dispatch(setUserData(formatUserData(res?.data?.accessToken?.payload)));
      })
      .catch((err) => {
        console.log(err);
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () => sendOtpVerificationCode(email, retryCount + 1),
          OTP_VERIFY_CODE_ERROR,
          "user",
          email,
          "verify_verification_code",
        );
      });
  };
