import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import styles from "../../styles/pages/insight/index.module.sass";
import { Badge, Button, Checkbox, Collapse, Empty, Modal, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getInsightInitialData,
  updateFeaturesList,
} from "../../redux/insight/action";
import OverAllTable from "../../components/tables/OverAllTable";
import SignalDetails from "../../components/SignalDetails";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import ShowMoreText from "react-show-more-text";
import axios from "axios";
import { useLocation } from "react-router";
import forexIcon from "../../asset/Icons/forexIcon.png";
import stockIcon from "../../asset/Icons/stockIcon.png";
import chartIcon from "../../asset/Icons/chartIcon.png";
import signalsIcon from "../../asset/Icons/signals.png";
import trendsIcon from "../../asset/Icons/trends.png";
import cryptoIcon from "../../asset/Icons/cryptoIcon.png";
import Ripples from "react-ripples";
import ReactLoading from "react-loading";
import {
  disableTrackSignal,
  setTrackSignal,
} from "../../redux/alert-system/actions";
import Alert from "../../components/alerts/Alert";
import { MdOutlineAddAlert } from "react-icons/md";
import { IoMdArrowDropright } from "react-icons/io";
import MultiLevelSideBar from "../../components/sidebars/MultiLevelSideBar";
import Navbar from "../../components/insight/Navbar";
import { FiChevronDown } from "react-icons/fi";
import { TbCurrencyDollarAustralian } from "react-icons/tb";
import ChartSection from "../../components/insight/ChartSection";
import SymbolsModal from "../../components/insight/SymbolsModal";
import Signals from "../../components/insight/Signals";
import asx from "../../asset/Icons/asx1.png";
import { useNavigate } from "react-router-dom";
import { allItemsMatch } from "../../utility/helper";

function Chart({
  chartData,
  setChartData,
  signalNumber,
  selectedFeatures,
  signalRange,
}) {
  const [test, setTest] = useState(false);
  // const [min, setMin] = useState(0)
  // const [max, setMax] = useState(0)

  let indexOfName = 0;
  // useEffect(() => {
  //     const valuesArr = chartData.map(item => item.map(itm => itm.value))
  //     let newArr = [];
  //     valuesArr.map(item => {
  //         newArr.push(...item)
  //     })
  //     setMin(Math.min(...newArr));
  //     setMax(Math.max(...newArr));
  // }, [min, max, chartData])

  useLayoutEffect(() => {
    var root = am5.Root.new("chartdiv");
    root._logo.dispose();

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      }),
    );

    var easing = am5.ease.linear;
    chart
      .get("colors")
      .set("colors", [
        am5.color("#1ecafa"),
        am5.color("#1fc219"),
        am5.color("#db2e5f"),
      ]);

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: "minute",
          count: 30,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    function createAxisAndSeries(opposite, index) {
      var yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: opposite,
      });
      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 1,
          renderer: yRenderer,
          min: signalRange[indexOfName]?.[0],
          max: signalRange[indexOfName]?.[1],
        }),
      );

      // if (chart.yAxes.indexOf(yAxis) > 0) {
      //     yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
      // }

      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          name: selectedFeatures[indexOfName]?.feature,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{valueY}",
          }),
        }),
      );
      indexOfName = indexOfName + 1;

      // series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
      series.strokes.template.setAll({ strokeWidth: 3 });

      yRenderer.grid.template.set("strokeOpacity", 0.05);
      yRenderer.labels.template.set("fill", series.get("fill"));
      yRenderer.setAll({
        stroke: series.get("fill"),
        strokeOpacity: 3,
        strokeWidth: 1,
        opacity: 1,
      });

      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["date"],
      });

      series.data.setAll(generateChartData(index));
    }

    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: "none",
      }),
    );
    cursor.lineY.set("visible", true);

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      }),
    );

    if (signalNumber > 0) {
      createAxisAndSeries(false, 0);
    }
    if (signalNumber > 1) {
      createAxisAndSeries(true, 1);
    }
    if (signalNumber > 2) {
      createAxisAndSeries(true, 2);
    }
    chart.appear(1000, 100);

    function generateChartData(index) {
      var data = [];

      chartData[index]?.map((item, i) => {
        data.push({
          date: item?.date,
          value: item.value,
        });
      });

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
          align: "left",
          valign: "bottom",
        }),
        dataSource: data,
      });
      return data;
    }

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        y: am5.percent(5),
      }),
    );
    legend.data.setAll(chart.series.values);

    let eles = document.querySelectorAll("[aria-labelledby$=-title]");
    eles.forEach((ele) => {
      ele.style.visibility = "hidden !important";
    });

    return () => {
      if (root) {
        root.dispose();
      }
    };
  }, [test]);

  return (
    <>
      <div
        id="chartdiv"
        style={{ width: "calc(100% - 10px)", height: "650px" }}
      ></div>
    </>
  );
}

const Index = ({ showRatingModal, setVisitedPage }) => {
  const dispatch = useDispatch();

  const {
    main: { userData },
    insightState: { loading, error, selectedFeatures, initialInfo },
    profile: { profileData },
    alertSystem: { TrackSignalLoading },
  } = useSelector((state) => state);

  const {
    TOP_TEN_SYMBOLS,
    CATEGORIES_FEATURE_TREE,
    RESPONSE_LIST,
    PROCESSED_FEATURES,
  } = initialInfo;

  const location = useLocation();
  const [chartLoading, setChartLoading] = useState(false);
  const [chartData, setChartData] = useState([[], [], []]);
  const [coin, setCoin] = useState("crypto");
  const [signalsNumber, setSignalsNumber] = useState(0);
  const [openOverAllModal, setOpenOverAllModal] = useState(false);
  const [chartError, setChartError] = useState("");
  const [signalsDetails, setSignalDetails] = useState([]);
  const [selectedSignalDetails, setSelectedSignalDetails] = useState(0);
  const [defaultSignal, setDefaultSignal] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [selectedContent, setSelectedContent] = useState(1);
  const [checkedFeatures, setCheckedFeatures] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSymbolId, setSelectedSymbolId] = useState("");
  const [checked, setChecked] = useState(selectedFeatures);
  const [selectedCoin, setSelectedCoin] = useState(0);
  const [tracked, setTracked] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [animateBtn, setAnimateBtn] = useState(false);
  const [signalsRange, setSignalsRange] = useState([]);

  const navigate = useNavigate();

  const onChange = (val) => {
    dispatch(updateFeaturesList([]));
    setSignalDetails([]);
    setCoin(val);
  };
  const contentsRadioHandler = (data) => {
    setSelectedContent(data?.target?.value);
  };

  const executeOnClick = (e) => {
    setShowMore(!showMore);
  };
  const selectedFeaturesOnchangeHandler = (e) => {
    if (e.target?.checked) {
      setCheckedFeatures((prevState) => {
        if (!checkedFeatures?.includes(e.target.value)) {
          return [...prevState, e.target.value];
        }
        return [...prevState];
      });
    } else {
      setCheckedFeatures((prevState) => {
        return checkedFeatures?.filter((item) => item != e.target.value);
      });
    }
  };
  const removeFeaturesHandler = () => {
    let newList = selectedFeatures;
    checkedFeatures?.map((item) => {
      newList.splice(newList.indexOf(item), 1);
    });
    dispatch(updateFeaturesList(newList));
    getData();
    setCheckedFeatures([]);
  };
  const clearAllFeaturesHandler = () => {
    dispatch(updateFeaturesList([]));
  };

  const getData = async (condition, retryCount = 0) => {
    try {
      setChartLoading(true);

      // Create copies of the state variables
      let newChartData = [...chartData];
      let newSignalsRange = [...signalsRange];

      // Clear the previous data
      newChartData[0] = [];
      newChartData[1] = [];
      newChartData[2] = [];
      newSignalsRange[0] = [];
      newSignalsRange[1] = [];
      newSignalsRange[2] = [];

      // Send the request to a valid URL
      let data;
      if (!condition) {
        await axios
          .post(
            "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
            {
              platform_mode: "insight_platform",
              email: userData?.email,
              user: userData["custom:custom_username"],
              market_type: coin,
              request_type: "get_signal_value",
              feature_list: selectedFeatures?.map((item) => ({
                symbol: item?.symbol ? item?.symbol : "",
                subcategory: item?.subcategory,
                category: item?.category,
                feature: item?.feature,
              })),
            },
          )
          .then((res) => {
            data = res?.data?.RESPONSE_LIST;
          })
          .catch((err) => {
            if (retryCount < 3) {
              getData(condition, retryCount + 1);
            }
          });
      } else {
        data = RESPONSE_LIST;
      }

      // Create an array for storing the signals details
      let newArr = [];

      data
        ?.filter((item) => item.VALUES.length > 0)
        .map((item, index) => {
          newArr.push(item?.SIGNALS);
          newSignalsRange[index].push(...item?.RANGE);

          item?.VALUES?.map((itm, idx) => {
            if (index === 0) {
              newChartData[0].push({
                date: new Date(item?.TIME_STAMPS[idx]),
                value: itm,
              });
            }
            if (index === 1) {
              newChartData[1].push({
                date: new Date(item?.TIME_STAMPS[idx]),
                value: itm,
              });
            }
            if (index === 2) {
              newChartData[2].push({
                date: new Date(item?.TIME_STAMPS[idx]),
                value: itm,
              });
            }
          });
        });

      // Update the state variables only once
      setSignalDetails(newArr);
      setSignalsRange(newSignalsRange);
      setChartData(newChartData);

      console.log(chartData);
      setSignalsNumber(data?.length);
      setDefaultSignal(false);

      if (
        (selectedFeatures?.length === 0 && !defaultSignal) ||
        data?.length > 0
      ) {
        setChartLoading(false);
      }
    } catch (err) {
      setChartError(err.response);
      setChartLoading(false);
    }
  };
  useEffect(() => {
    if (location?.hash === "#overall-statistics") {
      setOpenOverAllModal(true);
    }
  }, [location]);

  useEffect(() => {
    if (userData?.email && Object.keys(initialInfo).length === 0) {
      dispatch(
        getInsightInitialData(
          coin,
          userData?.email,
          userData["custom:custom_username"],
        ),
      );
    }
  }, [userData?.email, coin, initialInfo]);
  useEffect(() => {
    setDefaultSignal(true);
    if (CATEGORIES_FEATURE_TREE) {
      const firstSignal =
        CATEGORIES_FEATURE_TREE[Object.keys(CATEGORIES_FEATURE_TREE)?.[0]][
          Object.keys(
            CATEGORIES_FEATURE_TREE[Object.keys(CATEGORIES_FEATURE_TREE)?.[0]],
          )[0]
        ][
          Object.keys(
            CATEGORIES_FEATURE_TREE[Object.keys(CATEGORIES_FEATURE_TREE)?.[0]][
              Object.keys(
                CATEGORIES_FEATURE_TREE[
                  Object.keys(CATEGORIES_FEATURE_TREE)?.[0]
                ],
              )[0]
            ],
          )[0]
        ];
      if (!RESPONSE_LIST) {
        dispatch(
          updateFeaturesList([
            {
              symbol_icon: firstSignal?.symbol_icon_list[0],
              symbol: firstSignal?.symbol_list[0],
              subcategory: firstSignal?.subcategory,
              category: firstSignal?.category,
              feature: firstSignal?.name,
              description: firstSignal?.description,
              symbol_list: firstSignal?.symbol_list,
              symbol_icon_list: firstSignal?.symbol_icon_list,
              feature_key: firstSignal?.feature_key,
            },
          ]),
        );
      } else {
        const featuresList = [];
        PROCESSED_FEATURES.map((featureItem) => {
          const { symbol, subcategory, category, feature } = featureItem;
          const featureDetails =
            CATEGORIES_FEATURE_TREE[category][subcategory][feature];
          const { feature_key, description, symbol_list, symbol_icon_list } =
            featureDetails;

          const featureData = {
            symbol_icon: `https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${symbol}.png`,
            symbol,
            subcategory,
            category,
            feature,
            feature_key,
            description,
            symbol_list,
            symbol_icon_list,
          };

          featuresList.push(featureData);
        });
        dispatch(updateFeaturesList(featuresList));
      }
    }
  }, [CATEGORIES_FEATURE_TREE, RESPONSE_LIST, PROCESSED_FEATURES]);

  useEffect(() => {
    if (userData?.email && selectedFeatures.length > 0) {
      getData(allItemsMatch(selectedFeatures, PROCESSED_FEATURES));
    }
    setChecked(selectedFeatures);
  }, [selectedFeatures, PROCESSED_FEATURES]);

  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(updateFeaturesList([...checked]));
    setSelectedSymbolId("");
  };
  const handleCancel = (e) => {
    setIsModalOpen(false);
    // setChecked((prev) => (prev?.filter((item, index) => index != prev?.length - 1 && item)))
  };
  const trackSignalHandler = async () => {
    if (tracked) {
      await dispatch(
        disableTrackSignal(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          selectedFeatures[selectedSignalDetails]?.feature_key,
        ),
      ).then(() => {
        setTracked(false);
      });
    } else {
      await dispatch(
        setTrackSignal(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          selectedFeatures[selectedSignalDetails]?.category,
          selectedFeatures[selectedSignalDetails]?.feature,
          selectedFeatures[selectedSignalDetails]?.subcategory,
        ),
      ).then(() => {
        setTracked(true);
      });
    }
    getData();
  };
  const manageAlert = useRef(null);
  const manageAlertSystemHandler = async () => {
    manageAlert.current.scrollIntoView({ behavior: "smooth" });
    setAnimateBtn(true);
    setTimeout(() => {
      setAnimateBtn(false);
    }, 5000);
  };
  useEffect(() => {
    let num = 0;
    if (signalsDetails?.[selectedSignalDetails]) {
      Object.keys(signalsDetails?.[selectedSignalDetails])?.map(
        (item, index) => {
          if (
            signalsDetails?.[selectedSignalDetails]?.[item]?.length > 0 &&
            num === 0
          ) {
            setTracked(
              signalsDetails?.[selectedSignalDetails]?.[item]?.[0]
                ?.tracked_with_alert_system,
            );
            num = 1;
          }
        },
      );
    }
  }, [selectedSignalDetails, signalsDetails]);

  // Handle error
  const errorAlert = (text) => {
    return Alert(null, text, "warning", {
      cancel: "Close",
      defeat: {
        text: "Buy Plan",
        value: "buyPlan",
      },
    });
  };

  useEffect(() => {
    if (chartError) {
      if (chartError?.data) {
        const { RESPONSE_LIST } = chartError.data;
        RESPONSE_LIST.forEach(({ STATUS_CODE: code, MESSAGE }) => {
          if (code === 401 || code === 402 || code === 403) {
            errorAlert(MESSAGE);
          } else if (code === 450) {
            Alert(null, MESSAGE, "warning", {
              // cancel: "Close",
              defeat: {
                text: "Login",
                value: "login",
              },
            });
          } else {
            Alert("", MESSAGE, "warning", {
              cancel: "Close",
            });
          }
        });
      }
    }
  }, [chartError]);

  // Show rating modal before exit page
  useEffect(() => {
    return () => {
      if (profileData?.pricing_plan) {
        const expirationDate = new Date(profileData?.expiration_date);
        if (expirationDate.getTime() <= new Date().getTime()) return;
        if (!profileData?.insight_rate && profileData?.suggested_features) {
          showRatingModal(true);
          setVisitedPage("insight");
        }
      }
    };
  }, []);

  // CHECK ACCOUNT EXPIRATION
  useEffect(() => {
    if (profileData?.expiration_date) {
      const expirationDate = new Date(profileData?.expiration_date);
      if (expirationDate.getTime() <= new Date().getTime()) {
        navigate("/expired-account");
      }
    }
  }, [navigate, profileData]);

  return (
    <>
      <DashboardLayout
        insightLayout={window.innerWidth > 1200 ? true : false}
        error={chartError || error}
        // loading={
        //   chartLoading
        //     ? chartLoading
        //     : TOP_TEN_SYMBOLS?.length > 0
        //       ? loading
        //       : true
        // }
      >
        <div className={styles.container}>
          <div className={styles.sideBar}>
            <div className={styles.overAllAndCoins} data-tut="reactour__copy">
              <div>
                <h4>Market Type</h4>
                <Radio.Group
                  value={coin}
                  onChange={(e) => onChange(e.target.value)}
                  style={{
                    display: "flex",
                    gap: "24px",
                    flexDirection: "column",
                  }}
                >
                  <Radio
                    className={`${styles.coinTypesRadio} ${styles.marketTypeRadio}`}
                    value="crypto"
                  >
                    <img src={cryptoIcon} alt="crypto" />
                    Crypto
                  </Radio>

                  <Radio
                    value="forex"
                    // disabled
                    className={`${styles.coinTypesRadio} ${styles.marketTypeRadio}`}
                  >
                    <img src={forexIcon} alt="forex" />
                    {/* <Badge
                      count="coming soon"
                      size="small"
                      status="default"
                      className={styles.badge}
                    >
                      Forex
                    </Badge> */}
                    Forex
                  </Radio>

                  <Radio
                    value="asx"
                    // disabled
                    className={`${styles.coinTypesRadio} ${styles.marketTypeRadio}`}
                  >
                    <img src={stockIcon} alt="stock" />
                    {/* <Badge
                      count={"coming soon"}
                      size={"small"}
                      status={"default"}
                      className={styles.badge}
                    >
                      Stock
                    </Badge> */}
                    <img
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                      src={asx}
                      alt="asx"
                    />{" "}
                    Stock
                  </Radio>
                </Radio.Group>
              </div>
              <div>
                <h4>Table of Contents</h4>
                <Radio.Group
                  name="radiogroup"
                  defaultValue={selectedContent}
                  value={selectedContent}
                  onChange={contentsRadioHandler}
                  className={styles.radioContainer}
                >
                  <Radio value={1} className={styles.radioItem}>
                    <div>
                      <div>
                        <img src={signalsIcon} alt="Signals" />
                        <span>Signals</span>
                      </div>
                      <span>
                        Buy and sell suggestions <br /> Profitability analytics{" "}
                      </span>
                    </div>
                  </Radio>
                  <Radio value={2} className={styles.radioItem}>
                    <div>
                      <div>
                        <img src={chartIcon} alt="chart" />
                        <span>Chart</span>
                      </div>
                      <span>Historical Visualization</span>
                    </div>
                  </Radio>
                  <Radio value={3} className={styles.radioItem}>
                    <div>
                      <div>
                        <img src={trendsIcon} alt="Trends" />
                        <span>Trends</span>
                      </div>
                      <span>
                        Top 20 symbols <br />
                        Rising and falling symbols
                      </span>
                    </div>
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className={styles.main}>
            <div className={styles.navbar}>
              <div className={styles.menuButton}>
                <Button className={styles.dropdownBtn}>
                  Features <FiChevronDown />
                </Button>
                <div className={styles.featureDropdown}>
                  {CATEGORIES_FEATURE_TREE && <MultiLevelSideBar />}
                </div>
              </div>
              <Navbar
                coin={coin}
                changeMarket={onChange}
                content={selectedContent}
                changeContent={setSelectedContent}
              />
            </div>
            {selectedContent === 1 && (
              <Signals
                TrackSignalLoading={TrackSignalLoading}
                animateBtn={animateBtn}
                checkedFeatures={checkedFeatures}
                clearAllFeaturesHandler={clearAllFeaturesHandler}
                coin={coin}
                editMode={editMode}
                executeOnClick={executeOnClick}
                manageAlert={manageAlert}
                manageAlertSystemHandler={manageAlertSystemHandler}
                removeFeaturesHandler={removeFeaturesHandler}
                selectedFeatures={selectedFeatures}
                selectedFeaturesOnchangeHandler={
                  selectedFeaturesOnchangeHandler
                }
                selectedSignalDetails={selectedSignalDetails}
                setCheckedFeatures={setCheckedFeatures}
                setEditMode={setEditMode}
                setIsModalOpen={setIsModalOpen}
                setSelectedCoin={setSelectedCoin}
                setSelectedSignalDetails={setSelectedSignalDetails}
                showMore={showMore}
                signalsDetails={signalsDetails}
                trackSignalHandler={trackSignalHandler}
                tracked={tracked}
              />
            )}

            {selectedContent === 2 && (
              <ChartSection
                chartData={chartData}
                chartLoading={chartLoading}
                checkedFeatures={checkedFeatures}
                clearAllFeaturesHandler={clearAllFeaturesHandler}
                executeOnClick={executeOnClick}
                removeFeaturesHandler={removeFeaturesHandler}
                selectedFeatures={selectedFeatures}
                selectedSignalDetails={selectedSignalDetails}
                setChartData={setChartData}
                setCheckedFeatures={setCheckedFeatures}
                setIsModalOpen={setIsModalOpen}
                setSelectedCoin={setSelectedCoin}
                showMore={showMore}
                signalsNumber={signalsNumber}
                signalsRange={signalsRange}
              />
            )}
            {selectedContent === 3 && <OverAllTable marketType={coin} />}
          </div>
        </div>
      </DashboardLayout>
      <SymbolsModal
        checked={checked}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        selectedCoin={selectedCoin}
        selectedSymbolId={selectedSymbolId}
        setChecked={setChecked}
        setSelectedSymbolId={setSelectedSymbolId}
      />
    </>
  );
};

export default Index;
