import axios from "axios";
import {
    GET_200COINS_ERROR,
    GET_200COINS_REQUEST,
    GET_200COINS_SUCCESS,
    GET_BALANCE_ERROR,
    GET_BALANCE_REQUEST,
    GET_BALANCE_SUCCESS,
    GET_PORTFOLIO_DATA_ERROR,
    GET_PORTFOLIO_DATA_REQUEST,
    GET_PORTFOLIO_DATA_SUCCESS,
    GET_PRICE_ERROR,
    GET_PRICE_REQUEST,
    GET_PRICE_SUCCESS,
    GET_TRADE_ASSISTANT_SUCCESS,
    GET_TRADE_HISTORY_SUCCESS,
    RESET_HISTORY_ERROR,
    RESET_HISTORY_REQUEST,
    RESET_HISTORY_SUCCESS,
    SET_TRADING_ERROR,
    SET_TRADING_REQUEST,
    SET_TRADING_SUCCESS,
    GET_INVESTMENT_BUDGET_REQUEST,
    GET_INVESTMENT_BUDGET_SUCCESS,
    GET_INVESTMENT_BUDGET_ERROR,
    GET_TRADE_HISTORY_REQUEST,
    GET_TRADE_HISTORY_ERROR,
    GET_TRADE_ASSISTANT_REQUEST,
    GET_TRADE_ASSISTANT_ERROR,
    SET_LIMIT_ORDER_REQUEST,
    SET_LIMIT_ORDER_SUCCESS,
    SET_LIMIT_ORDER_ERROR,
    VIRTUAL_PORTFOLIO,
    SET_AI_MARKET_TYPE,
    UPDATE_PORTFOLIO_DATA,
    UPDATE_TRADE_ASSISTANT_DATA,
    SET_AI_ASSISTANT_MODE,
    GET_ALL_TRADE_SUGGESTION_REQUEST,
    GET_ALL_TRADE_SUGGESTION_SUCCESS,
    GET_ALL_TRADE_SUGGESTION_ERROR,
} from "./consts";
import {notify} from "../../utility/Toastify/toastify";
import {errorHandler, networkErrorHandler} from "../../utility/helper";
import {axiosErrorHandler} from "../../utility/action-helper";

export const setVirtualPortfolio = (portfolio) => {
    return {type: VIRTUAL_PORTFOLIO, payload: portfolio};
};

export const getPortfolioData =
    (email, user, portfolio_type, retryCount = 0) =>
        async (dispatch) => {
            dispatch({type: GET_PORTFOLIO_DATA_REQUEST});

            await axios
                .post(
                    "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    {
                        platform_mode: "trade_assistant",
                        email: email,
                        user: user,
                        request_type: "get_portfolio",
                        portfolio_type,
                    },
                )
                .then((res) => {
                    dispatch({type: GET_PORTFOLIO_DATA_SUCCESS, payload: res?.data});
                })
                .catch((err) => {
                    axiosErrorHandler(
                        err,
                        retryCount,
                        dispatch,
                        () => getPortfolioData(email, user, portfolio_type, retryCount + 1),
                        GET_PORTFOLIO_DATA_ERROR,
                        user,
                        email,
                        "assistant/get_portfolio",
                    );
                });
        };

export const getInvestmentBudget =
    (email, user, portfolioType = "primary") =>
        async (dispatch) => {
            dispatch({type: GET_INVESTMENT_BUDGET_REQUEST});

            await axios
                .post(
                    "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    {
                        platform_mode: "trade_assistant",
                        email: email,
                        user: user,
                        request_type: "get_investment_budget",
                        portfolio_type: portfolioType,
                    },
                )
                .then((res) => {
                    dispatch({type: GET_INVESTMENT_BUDGET_SUCCESS, payload: res?.data});
                })
                .catch((err) => {
                    dispatch({type: GET_INVESTMENT_BUDGET_ERROR, payload: err?.response});
                    const {STATUS_CODE, MESSAGE} = err?.response.data;
                    errorHandler(STATUS_CODE, MESSAGE);
                });
        };

export const getTradeHistory =
    (email, user, portfolioType = "primary", retryCount = 0) =>
        async (dispatch) => {
            // dispatch({ type: GET_PORTFOLIO_DATA_REQUEST });
            dispatch({type: GET_TRADE_HISTORY_REQUEST});

            await axios
                .post(
                    "https://hav8w1xk8h.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    {
                        platform_mode: "trade_assistant",
                        email: email,
                        user: user,
                        request_type: "get_trade_history",
                        portfolio_type: portfolioType,
                    },
                )
                .then((res) => {
                    dispatch({type: GET_TRADE_HISTORY_SUCCESS, payload: res?.data});
                })
                .catch((err) => {
                    axiosErrorHandler(
                        err,
                        retryCount,
                        dispatch,
                        () =>
                            getTradeHistory(
                                email,
                                user,
                                (portfolioType = "primary"),
                                retryCount + 1,
                            ),
                        GET_TRADE_HISTORY_ERROR,
                        user,
                        email,
                        "assistant/get_trade_history",
                    );
                    // dispatch({ type: GET_PORTFOLIO_DATA_ERROR, payload: err?.response });
                    // dispatch({ type: GET_TRADE_HISTORY_ERROR, payload: err?.response });
                    // const { STATUS_CODE, MESSAGE } = err?.response.data;
                    // errorHandler(STATUS_CODE, MESSAGE);
                });
        };

export const getTradeAssistant =
    (
        email,
        user,
        portfolio_type = "primary",
        market_type = "crypto",
        mode = null,
        retryCount = 0,
    ) =>
        async (dispatch) => {
            // dispatch({ type: GET_PORTFOLIO_DATA_REQUEST });
            dispatch({type: GET_TRADE_ASSISTANT_REQUEST});

            const requestPayload = {
                platform_mode: "trade_assistant",
                email,
                user,
                request_type: "get_trade_suggestion",
                portfolio_type,
                market_type,
            };

            if (mode !== "null" && mode) {
                requestPayload.ai_assistant_mode = mode;
            }

            await axios
                .post(
                    "https://hav8w1xk8h.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    requestPayload,
                )
                .then((res) => {
                    dispatch({type: GET_TRADE_ASSISTANT_SUCCESS, payload: res?.data});
                })
                .catch((err) => {
                    axiosErrorHandler(
                        err,
                        retryCount,
                        dispatch,
                        () =>
                            getTradeAssistant(
                                email,
                                user,
                                portfolio_type,
                                market_type,
                                mode,
                                retryCount + 1,
                            ),
                        GET_TRADE_ASSISTANT_ERROR,
                        user,
                        email,
                        "get_trade_suggestion",
                    );
                    // dispatch({ type: GET_PORTFOLIO_DATA_ERROR, payload: err?.response });
                });
        };

export const getPrice =
    (email, user, symbol_name, market_type, retryCount = 0) =>
        async (dispatch) => {
            dispatch({type: GET_PRICE_REQUEST});
            await axios
                .post(
                    "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    {
                        platform_mode: "trade_assistant",
                        email,
                        user,
                        market_type,
                        request_type: "get_price",
                        symbol_name,
                    },
                )
                .then((res) => {
                    dispatch({type: GET_PRICE_SUCCESS, payload: res?.data});
                })
                .catch((err) => {
                    axiosErrorHandler(
                        err,
                        retryCount,
                        dispatch,
                        () => getPrice(email, user, symbol_name, market_type, retryCount + 1),
                        GET_PRICE_ERROR,
                        user,
                        email,
                        "assistant/get_price",
                    );
                });
        };
export const setTrade =
    (
        email,
        user,
        symbol_name,
        market_type,
        amount_native,
        trade_type,
        portfolio_type,
        native_or_usd,
        notif,
        retryCount = 0,
    ) =>
        async (dispatch) => {
            dispatch({type: SET_TRADING_REQUEST});
            await axios
                .post(
                    // "https://9mcsz8aaqg.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    {
                        platform_mode: "trade_assistant",
                        request_type: "set_trade",
                        email,
                        user,
                        market_type,
                        symbol_name,
                        amount_native,
                        trade_type,
                        portfolio_type,
                        native_or_usd,
                    },
                )
                .then((res) => {
                    dispatch({type: SET_TRADING_SUCCESS, payload: res?.data});
                    dispatch({type: GET_PRICE_SUCCESS, payload: null});
                    dispatch(getPortfolioData(email, user, portfolio_type));
                    dispatch(setVirtualPortfolio(portfolio_type));
                    dispatch(getTradeHistory(email, user, portfolio_type));

                    if (notif) {
                        notify("The transaction was successful", "success");
                    }
                })
                .catch((err) => {
                    axiosErrorHandler(
                        err,
                        retryCount,
                        dispatch,
                        () =>
                            setTrade(
                                email,
                                user,
                                symbol_name,
                                market_type,
                                amount_native,
                                trade_type,
                                portfolio_type,
                                native_or_usd,
                                notif,
                                retryCount + 1,
                            ),
                        SET_TRADING_ERROR,
                        user,
                        email,
                        "assistant/set_trade",
                    );
                });
        };

export const get200Coins =
    (email, user, retryCount = 0) =>
        async (dispatch) => {
            dispatch({type: GET_200COINS_REQUEST});
            await axios
                .post(
                    "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    {
                        platform_mode: "trade_assistant",
                        email,
                        user,
                        request_type: "get_top_200_coins",
                    },
                )
                .then((res) => {
                    dispatch({type: GET_200COINS_SUCCESS, payload: res?.data});
                })
                .catch((err) => {
                    axiosErrorHandler(
                        err,
                        retryCount,
                        dispatch,
                        () => get200Coins(email, user, retryCount + 1),
                        GET_200COINS_ERROR,
                        user,
                        email,
                        "get_top_200_coins",
                    );
                });
        };
// https://9mcsz8aaqg.execute-api.ap-southeast-2.amazonaws.com/dev/api
export const resetHistory =
    (email, user, portfolio_type, retryCount = 0) =>
        async (dispatch) => {
            dispatch({type: RESET_HISTORY_REQUEST});

            await axios
                .post(
                    "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    {
                        platform_mode: "trade_assistant",
                        email,
                        user,
                        request_type: "reset_trade_history",
                        portfolio_type,
                    },
                )
                .then((res) => {
                    dispatch({type: RESET_HISTORY_SUCCESS, payload: res?.data});
                    dispatch({type: GET_TRADE_HISTORY_SUCCESS, payload: []});
                    dispatch(getPortfolioData(email, user, portfolio_type));
                    notify("Your trade history cleared", "success");
                })
                .catch((err) => {
                    axiosErrorHandler(
                        err,
                        retryCount,
                        dispatch,
                        () => resetHistory(email, user, portfolio_type, retryCount + 1),
                        RESET_HISTORY_ERROR,
                        user,
                        email,
                        "assistant/reset_trade_history",
                    );
                });
        };

export const getBalanceChart =
    (email, user, portfolio_type, num_days_before, retryCount = 0) =>
        async (dispatch) => {
            dispatch({type: GET_BALANCE_REQUEST});

            await axios
                .post(
                    "https://hav8w1xk8h.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    {
                        platform_mode: "trade_assistant",
                        email,
                        user,
                        request_type: "get_balance_chart",
                        portfolio_type,
                        num_days_before,
                    },
                )
                .then((res) => {
                    dispatch({type: GET_BALANCE_SUCCESS, payload: res?.data});
                })
                .catch((err) => {
                    axiosErrorHandler(
                        err,
                        retryCount,
                        dispatch,
                        () =>
                            getBalanceChart(
                                email,
                                user,
                                portfolio_type,
                                num_days_before,
                                retryCount + 1,
                            ),
                        GET_BALANCE_ERROR,
                        user,
                        email,
                        "assistant/get_balance_chart",
                    );
                });
        };

export const setLimitOrder =
    (
        email,
        user,
        portfolio_type,
        market_type,
        symbol_name,
        trade_type_list,
        trade_type_amount,
        trade_type_trigger_price,
        notif,
        retryCount = 0,
    ) =>
        async (dispatch) => {
            dispatch({type: SET_LIMIT_ORDER_REQUEST});

            await axios
                .post(
                    "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    {
                        platform_mode: "trade_assistant",
                        email,
                        user,
                        request_type: "set_trade",
                        market_type,
                        symbol_name,
                        trade_type: "limit_orders",
                        portfolio_type,
                        trade_type_list,
                        trade_type_amount,
                        trade_type_trigger_price,
                    },
                )
                .then((res) => {
                    dispatch({
                        type: SET_LIMIT_ORDER_SUCCESS,
                        payload: res?.data?.Message,
                    });
                    // if (notif) {
                    //   // notify("Limit order successfully executed.", "success");
                    // }
                })
                .catch((err) => {
                    axiosErrorHandler(
                        err,
                        retryCount,
                        dispatch,
                        () =>
                            setLimitOrder(
                                email,
                                user,
                                portfolio_type,
                                market_type,
                                symbol_name,
                                trade_type_list,
                                trade_type_amount,
                                trade_type_trigger_price,
                                notif,
                                retryCount + 1,
                            ),
                        SET_LIMIT_ORDER_ERROR,
                        user,
                        email,
                        "limit_orders",
                    );
                });
        };

export const setAIMarketType = (market) => {
    return {type: SET_AI_MARKET_TYPE, payload: market};
};

export const updateAIPortfolioData = (data) => {
    return {type: UPDATE_PORTFOLIO_DATA, payload: data};
};

export const updateTradeAssistantData = (newValues) => {
    return {type: UPDATE_TRADE_ASSISTANT_DATA, payload: newValues};
};

export const setAiAssistantMode = (mode) => (dispatch) => {
    return dispatch({type: SET_AI_ASSISTANT_MODE, payload: mode});
};

export const getAllTradeSuggestion =
    (user, email, market_type, processCode = "", retryCount = 0) =>
        async (dispatch) => {
            dispatch({type: GET_ALL_TRADE_SUGGESTION_REQUEST});

            const data = {
                platform_mode: "trade_assistant",
                user,
                email,
                market_type,
                request_type: "get_trade_suggestion",
                cache: "True",
                use_all_symbols: "True",
            };

            if (processCode) {
                data.process_code = processCode;
            }

            await axios
                .post(
                    "https://hav8w1xk8h.execute-api.ap-southeast-2.amazonaws.com/dev/api",
                    data,
                )
                .then((res) => {
                    if (res?.data?.process_code) {
                        setTimeout(() => {
                            dispatch(
                                getAllTradeSuggestion(
                                    user,
                                    email,
                                    market_type,
                                    res?.data?.process_code,
                                ),
                            );
                        }, 20 * 1000);
                    } else {
                        dispatch({
                            type: GET_ALL_TRADE_SUGGESTION_SUCCESS,
                            payload: res?.data,
                        });
                    }
                })
                .catch((err) => {
                    axiosErrorHandler(
                        err,
                        retryCount,
                        dispatch,
                        () =>
                            getAllTradeSuggestion(
                                user,
                                email,
                                market_type,
                                processCode,
                                retryCount + 1,
                            ),
                        GET_ALL_TRADE_SUGGESTION_ERROR,
                        user,
                        email,
                        "get_trade_suggestion/all_symbols",
                    );
                });
        };
