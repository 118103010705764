import {
  GET_INSIGHT_INITIAL_INFO_ERROR_NEW,
  GET_INSIGHT_INITIAL_INFO_REQUEST_NEW,
  GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW,
  GET_SIGNAL_VALUE_ERROR,
  GET_SIGNAL_VALUE_REQUEST,
  GET_SIGNAL_VALUE_SUCCESS,
  SET_CURRENT_SIGNAL_DATA,
  SET_CURRENT_SIGNAL_INDEX,
  SET_INSIGHT_MARKET_TYPE,
  UPDATE_SELECTED_FEATURES_NEW,
} from "./consts";
import axios from "axios";
import { errorHandler } from "../../utility/helper";
import { axiosErrorHandler } from "../../utility/action-helper";

export const getInsightInitialDataNew =
  (email, user, market_type, only_tree_categories = "False", retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_INSIGHT_INITIAL_INFO_REQUEST_NEW });

    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "insight_platform",
        email,
        user,
        market_type,
        request_type: "get_initial_info",
        only_tree_categories,
      })
      .then((res) => {
        dispatch({
          type: GET_INSIGHT_INITIAL_INFO_SUCCESS_NEW,
          payload: res?.data,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getInsightInitialDataNew(
              email,
              user,
              market_type,
              (only_tree_categories = "False"),
              retryCount + 1,
            ),
          GET_INSIGHT_INITIAL_INFO_ERROR_NEW,
          user,
          email,
          "get_initial_info",
        );
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // dispatch({
        //   type: GET_INSIGHT_INITIAL_INFO_ERROR_NEW,
        //   payload: MESSAGE,
        // });
        // errorHandler(STATUS_CODE, MESSAGE);
      });
  };

export const updateSelectedFeaturesList = (features) => (dispatch) => {
  return dispatch({ type: UPDATE_SELECTED_FEATURES_NEW, payload: features });
};

export const getSignalValue =
  (email, user, market_type, feature_list, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_SIGNAL_VALUE_REQUEST });
    await axios
      .post("https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api", {
        platform_mode: "insight_platform",
        email,
        user,
        market_type,
        request_type: "get_signal_value",
        feature_list,
      })
      .then((res) => {
        dispatch({ type: GET_SIGNAL_VALUE_SUCCESS, payload: res?.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getSignalValue(
              email,
              user,
              market_type,
              feature_list,
              retryCount + 1,
            ),
          GET_SIGNAL_VALUE_ERROR,
          user,
          email,
          "get_signal_value",
        );
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // errorHandler(STATUS_CODE, MESSAGE);
        // dispatch({ type: GET_SIGNAL_VALUE_ERROR, payload: MESSAGE });
      });
  };

export const setInsightMarketType = (type) => (dispatch) => {
  return dispatch({ type: SET_INSIGHT_MARKET_TYPE, payload: type });
};

export const setCurrentSignalIndex = (signalIndex) => (dispatch) => {
  return dispatch({ type: SET_CURRENT_SIGNAL_INDEX, payload: signalIndex });
};

export const setCurrentSignalData = (data) => (dispatch) => {
  return dispatch({ type: SET_CURRENT_SIGNAL_DATA, payload: data });
};
