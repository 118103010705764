import { useDispatch, useSelector } from "react-redux";
import { FaCaretRight } from "react-icons/fa";
import styles from "../../../styles/Components/new-insight/signals-section/InsightSelectedFeatures.module.css";
import { setCurrentSignalIndex } from "../../../redux/new-insight/actions";

function InsightSelectedFeatures({ type }) {
  const {
    newInsightState: {
      selectedFeatures,
      processedFeaturesList,
      currentSignalIndex,
      currentSignalData,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const activeSignal =
    processedFeaturesList?.length &&
    processedFeaturesList?.length - 1 - currentSignalIndex;

  const features =
    type === "selected" ? [...selectedFeatures] : [...processedFeaturesList];

  const selectFeatureHandler = (index) => {
    dispatch(setCurrentSignalIndex(processedFeaturesList?.length - 1 - index));
  };

  return (
    <ul className={styles.featuresList}>
      {console.log(currentSignalIndex, currentSignalData)}
      {features?.reverse()?.map((feature, i) => (
        <li
          className={`${styles.featureItem} ${
            type === "processed" && activeSignal === i ? styles.active : ""
          }`}
          key={`${feature.category}${feature.subcategory} ${feature.feature} ${feature.symbol}`}
          onClick={() => selectFeatureHandler(i)}
        >
          <span>{feature.category}</span>
          <span>
            <FaCaretRight />
          </span>
          <span>{feature.subcategory}</span>
          <span>
            <FaCaretRight />
          </span>
          <span>{feature.feature}</span>
          {feature.symbol && (
            <>
              <span>
                <FaCaretRight />
              </span>
              <span className={styles.symbol}>
                <img
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${feature.symbol}.png`}
                  alt={feature.symbol}
                />
                {feature.symbol}
              </span>
            </>
          )}
        </li>
      ))}
    </ul>
  );
}

export default InsightSelectedFeatures;
