import styles from "../../../styles/Components/new-insight/FeatureCategoriesModal.module.css";

function FeatureListItem({
  text,
  clickHandler,
  className,
  icon,
  children,
  iconStyle,
  style,
}) {
  return (
    <li
      className={className}
      onClick={() => clickHandler(text)}
      style={style || {}}
    >
      {icon && (
        <img src={icon} alt={text} className={styles.icon} style={iconStyle} />
      )}
      {text}
      {children}
    </li>
  );
}

export default FeatureListItem;
