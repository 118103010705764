import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { useForm } from "react-hook-form";
import { handleBlur } from "../../utility/action";
import { confirmationCodeValidation } from "../../utility/Validation/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { notify } from "../../utility/Toastify/toastify";
import poolData from "./AWSConfig/PoolData";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import "../../styles/pages/Auth/auth.css";
import { useNavigate } from "react-router-dom";
import Text from "../../components/auth/Text";
import { useDispatch, useSelector } from "react-redux";
import { checkEmailException } from "../../utility/helper";
import PoolData from "./AWSConfig/PoolData";
import { setToken, setUserData } from "../../redux/main/action/main";
import axios from "axios";
import { Button as AntdButton } from "antd";

function ConfirmEmail({ registeredEmail }) {
  const {
    main: { userPass },
  } = useSelector((state) => state);

  const savedUserPass = localStorage.getItem("userPass")?.split(",");
  const savedEmail = savedUserPass?.[0];
  const savedPass = savedUserPass?.[1];

  //btn loading
  const [loading, setLoading] = useState(false);
  const [emailValue, setEmailValue] = useState(
    registeredEmail || savedEmail || "",
  );
  const [seconds, setSeconds] = useState(60);
  const [resendLoading, setResendLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleResendCode = () => {
    if (seconds > 0 || resendLoading) return;
    setResendLoading(true);
    const getUser = () => {
      return new CognitoUser({
        Username: checkEmailException(emailValue),
        Pool: PoolData,
      });
    };

    const onSuccess = (data) => {
      setResendLoading(false);
      notify(
        "Confirmation code sent to your email inbox. Please check your email and enter the code.",
        "success",
      );
    };
    const onFailure = (err) => {
      notify(err.message || JSON.stringify(err), "error");
      setResendLoading(false);
    };

    getUser().resendConfirmationCode((err, data) => {
      setSeconds(60);
      if (err) {
        onFailure(err);
      } else {
        onSuccess(data);
      }
    });
  };

  //validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(confirmationCodeValidation),
  });

  //form submit function
  const onsubmit = (data) => {
    setLoading(true);
    const userData = {
      Username: data?.email.toLowerCase(),
      Pool: poolData,
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(
        data?.confirmationCode,
        true,
        function (err, result) {
          if (err) {
            notify(err?.message, "error");
            reject(err);
            setLoading(false);
          } else {
            resolve(result);

            let user = new CognitoUser({
              Username: checkEmailException(userPass[0] || savedEmail),
              Pool: PoolData,
            });
            let authDetails = new AuthenticationDetails({
              Username: userPass[0] || savedEmail,
              Password: userPass[1] || savedPass,
              ValidationData: { captcha: "rtspa_mehdi_ravanbakhsh_761" },
            });

            user.authenticateUser(authDetails, {
              onSuccess: (data) => {
                dispatch(setToken(data?.idToken?.jwtToken));
                dispatch(setUserData(data?.idToken?.payload));
                axios.defaults.headers.common["Authorization"] =
                  `Bearer ${data?.idToken?.jwtToken}`;
                // window.location.reload();
                // notify("You have successfully logged in", "success");
                localStorage.setItem(
                  "aws-amplify-federatedInfo",
                  JSON.stringify({ token: data?.idToken?.jwtToken }),
                );
                localStorage.setItem(
                  "aws-amplify-USERDATA",
                  JSON.stringify(data?.idToken?.payload),
                );
                setLoading(false);
                notify("You have successfully signed up", "success");
                // navigate("/ai-assistant/assistant");
                navigate("/welcome");
              },

              onFailure: function (err) {
                window.grecaptcha.reset();
                setLoading(false);
                if (err?.message.includes("captcha")) return;
                notify(err?.message, "error");
              },

              newPasswordRequired: (data) => {
                notify(data, "success");
                setLoading(false);
              },
            });
            // setLoading(false);
            // navigate("/auth/login");
          }
        },
      );
    });
    // [{Name:"email",Value:data?.email}]
  };

  useEffect(() => {
    notify(
      "A confirmation code has been sent to your email inbox. Please check your email and enter the code.",
      "info",
    );
  }, []);

  useEffect(() => {
    window.onbeforeunload = confirmExit;

    function confirmExit() {
      return "show warning";
    }
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(
        () => setSeconds((seconds) => seconds - 1),
        1000,
      );
      return () => clearTimeout(timer);
    }
  }, [seconds]);

  return (
    <div className="limiter">
      <div className="container-login100">
        {/*<Logo />*/}
        <div className="content">
          <Text />

          <div className="wrap-login100">
            <form
              className="login100-form validate-form"
              onSubmit={handleSubmit(onsubmit)}
            >
              <h4 className="form-title">
                Confirm Your Email Address: Check Your Inbox and Enter the
                Confirmation Code
              </h4>

              <div
                className={`wrap-input100 validate-input ${
                  errors.username && "border-error"
                }`}
              >
                <input
                  className={`input100 ${emailValue ? "has-value" : ""}`}
                  type="text"
                  {...register("email")}
                  value={emailValue}
                  onBlur={(e) => handleBlur(e)}
                />
                <span
                  className="focus-input100"
                  data-placeholder={"Email"}
                ></span>
              </div>
              {errors.email && (
                <span className="error-text">{errors.email.message}</span>
              )}

              <div
                className={` wrap-input100 validate-input ${
                  errors.confirmationCode && "border-error"
                }`}
              >
                <input
                  className="input100"
                  type={"text"}
                  {...register("confirmationCode")}
                  onBlur={(e) => handleBlur(e)}
                />
                <span
                  className="focus-input100"
                  data-placeholder={"Confirmation code"}
                ></span>
              </div>
              {errors.confirmationCode && (
                <span className="error-text">
                  {errors.confirmationCode.message}
                </span>
              )}

              <Button
                ButtonText={"Submit"}
                className={"signup"}
                loading={loading}
              />
              <AntdButton
                className="resend-button"
                type="button"
                onClick={handleResendCode}
                loading={resendLoading}
                disabled={resendLoading || seconds > 0}
              >
                Resend Code {seconds > 0 && `(${seconds}s)`}
              </AntdButton>
            </form>
            {/* <div className="aws-logo">
                            <img src={awsLogo} alt="powered by aws" />
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmEmail;
