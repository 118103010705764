import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { handleBlur, handleShowPass } from "../../utility/action";
import {
  logInValidation,
  otpCodeValidation,
  otpEmailValidation,
} from "../../utility/Validation/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { notify } from "../../utility/Toastify/toastify";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import PoolData from "./AWSConfig/PoolData";
import "../../styles/pages/Auth/auth.css";
import { setToken, setUserData } from "../../redux/main/action/main";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Auth, Amplify } from "aws-amplify";
import { checkEmailException, formatUserData } from "../../utility/helper";
import axios from "axios";
import Text from "../../components/auth/Text";
import GoogleLoginButton from "./GoogleLoginButton";
import ConfirmAlert from "./ConfirmAlert";
import alert from "../../components/alerts/Alert";
import SixDigitInput from "../../components/mini-app/auth/SixDigitInput";
import { FiEdit } from "react-icons/fi";

var AWS = require("aws-sdk");

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState("");
  const [googleLoading, setGoogleLoading] = useState(false);
  const [code, setCode] = useState("");
  const recaptchaRef = useRef(null);

  function onChange(value) {
    if (value) {
      setCaptcha(value);
    } else {
      window.grecaptcha.reset();
    }
  }

  //google authentication integrated with AWS

  Amplify.configure({
    Auth: {
      identityPoolId: "us-east-1:dad77690-7bc3-4d24-bfc8-9a5bc43f1f6a",
      region: "us-east-1",
    },
  });

  useEffect(() => {
    const ga =
      window.gapi && window.gapi.auth2
        ? window.gapi.auth2.getAuthInstance()
        : null;

    if (!ga) createScript();
  }, []);

  const signIn = () => {
    const ga = window.gapi.auth2.getAuthInstance();
    ga.signIn().then(
      (googleUser) => {
        getAWSCredentials(googleUser);
      },
      (error) => {
        notify(error, "error");
      },
    );
  };

  const getAWSCredentials = async (googleUser) => {
    const { id_token, expires_at } = googleUser.getAuthResponse();
    const profile = googleUser.getBasicProfile();
    let user = {
      email: profile.getEmail(),
      name: profile.getName(),
    };

    const credentials = await Auth.federatedSignIn(
      "google",
      { token: id_token, expires_at },
      user,
    );
    if (credentials["authenticated"]) {
      // Add the Google access token to the Amazon Cognito credentials login map.
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: "us-east-1:dad77690-7bc3-4d24-bfc8-9a5bc43f1f6a",
        region: "us-east-1",
        Logins: {
          "accounts.google.com": id_token,
        },
      });

      // Obtain AWS credentials
      AWS.config.credentials.get(function (res) {
        // notify("You have successfully logged in", "success");
        // navigate("/ai-assistant/assistant");
        navigate("/welcome");
      });
    }
  };

  const createScript = () => {
    // load the Google SDK
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.async = true;
    script.onload = initGapi;
    document.body.appendChild(script);
  };

  const initGapi = () => {
    // init the Google SDK client
    const g = window.gapi;
    g.load("auth2", function () {
      g.auth2.init({
        client_id:
          "225357808354-8efg7jlf2sina9grvsj571mfnhhh1pms.apps.googleusercontent.com",
        plugin_name: "cryptocrispy",
      });
    });
  };

  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [pass, setPass] = useState("");
  //btn loading
  const [loading, setLoading] = useState(false);
  const [loginType, setLoginType] = useState("otp");
  const [otpStep, setOtpStep] = useState(1);

  const validationSchema =
    loginType === "email"
      ? logInValidation
      : otpStep === 1
      ? otpEmailValidation
      : otpCodeValidation;

  // validation
  const {
    register,
    watch,
    handleSubmit,
    formState,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const resetCaptcha = () => {
    recaptchaRef.current.reset();
  };

  //form submit function
  const onsubmit = async (data) => {
    if (loginType === "otp") {
      setUserEmail(data.email);
      setLoading(true);
      if (otpStep === 1) {
        await axios
          .post(
            "https://weu658vyjj.execute-api.ap-southeast-2.amazonaws.com/dev/api",
            {
              operation_mode: "send_verification_code",
              email: checkEmailException(data?.email),
            },
          )
          .then((res) => {
            notify("Verification code sent", "success");
            setOtpStep(2);
          })
          .catch((err) => {
            alert(null, err?.response?.data?.message, "error", {
              cancel: "Ok",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }

      if (otpStep === 2) {
        setCode(data?.code);
        await axios
          .post(
            "https://weu658vyjj.execute-api.ap-southeast-2.amazonaws.com/dev/api",
            {
              operation_mode: "verify_verification_code",
              email: checkEmailException(data?.email),
              verification_code: data?.code,
            },
          )
          .then((res) => {
            console.log(res);
            const {
              accessToken: { jwtToken },
            } = res?.data;
            dispatch(setToken(jwtToken));
            dispatch(setUserData(formatUserData(res?.data?.accessToken)));
            localStorage.setItem(
              "aws-amplify-federatedInfo",
              JSON.stringify({ token: jwtToken }),
            );
            axios.defaults.headers.common["Authorization"] = `${jwtToken}`;
            navigate("/welcome");
          })
          .catch((err) => {
            alert(null, err?.response?.data?.message, "error", {
              cancel: "Ok",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }

    if (loginType === "email") {
      if (!captcha) {
        return alert(null, "Please complete the reCAPTCHA.", "info", {
          cancel: "Ok",
        });
      }
      setLoading(true);
      setUserEmail(data?.email.toLowerCase());
      setPass(data?.pass);

      let user = new CognitoUser({
        Username: checkEmailException(data?.email),
        Pool: PoolData,
      });
      let authDetails = new AuthenticationDetails({
        Username: data?.email,
        Password: data?.pass,
        ValidationData: { captcha: captcha },
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          dispatch(setToken(data?.idToken?.jwtToken));
          dispatch(setUserData(data?.idToken?.payload));
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data?.idToken?.jwtToken}`;
          // window.location.reload();
          // notify("You have successfully logged in", "success");
          localStorage.setItem(
            "aws-amplify-federatedInfo",
            JSON.stringify({ token: data?.idToken?.jwtToken }),
          );
          localStorage.setItem(
            "aws-amplify-USERDATA",
            JSON.stringify(data?.idToken?.payload),
          );
          setLoading(false);
          // navigate("/ai-assistant/assistant");
          navigate("/welcome");
        },

        onFailure: function (err) {
          window.grecaptcha.reset();
          setLoading(false);
          if (err?.message === "User is not confirmed.") {
            setShowConfirmAlert(true);
            return;
          }
          if (err?.message.includes("captcha")) return;
          notify(err?.message, "error");
        },

        newPasswordRequired: (data) => {
          notify(data, "success");
          setLoading(false);
        },
      });
    }
  };

  return (
    <div className="limiter">
      <div className="container-login100">
        {/*<Logo />*/}
        <div className="content">
          <Text />
          <div className="wrap-login100">
            <div>
              <form
                className="login100-form validate-form"
                onSubmit={handleSubmit(onsubmit)}
              >
                <h4 className="form-title">
                  Manage Your Crypto Investments - Sign In Here
                </h4>
                <ul className="login-tab">
                  <li
                    className={`login-option ${
                      loginType === "otp" ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      if (loading) return;
                      setLoginType("otp");
                    }}
                  >
                    Email / OTP
                  </li>
                  <li
                    className={`login-option ${
                      loginType === "email" ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      if (loading) return;
                      setLoginType("email");
                    }}
                  >
                    Email / Password
                  </li>
                </ul>

                {loginType === "otp" && (
                  <>
                    {otpStep === 1 && (
                      <>
                        <div className="wrap-input100 validate-input">
                          <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="input100"
                                type="text"
                                {...field}
                                onBlur={(e) => handleBlur(e)}
                                autoComplete="email"
                                placeholder="Email"
                              />
                            )}
                          />
                        </div>

                        {errors.email && (
                          <span className="error-text">
                            {errors.email.message}
                          </span>
                        )}

                        <Button
                          ButtonText={"Send Code"}
                          className={"login"}
                          loading={loading}
                          type="submit"
                        />

                        <GoogleLoginButton setLoading={setGoogleLoading} />

                        <div className="text-center p-t-20">
                          <span className="txt1">Don’t have an account?</span>
                          <Link className="txt2" to="/auth/signup">
                            Create Account
                          </Link>
                        </div>
                        <div className="text-center">
                          <Link className="txt2" to="/auth/forgotPassword">
                            Forgot password
                          </Link>
                        </div>
                      </>
                    )}

                    {otpStep === 2 && (
                      <>
                        <div
                          className="wrap-input100 validate-input"
                          style={{ borderBottom: "none" }}
                        >
                          <label>
                            Enter the 6-digit code sent to your email
                          </label>
                          <Controller
                            name="code"
                            control={control}
                            render={({ field }) => (
                              <SixDigitInput
                                value={code}
                                onChange={(value) => {
                                  field.onChange(value);
                                  setCode(value);
                                }}
                                onBlur={(e) => {
                                  field.onBlur(e);
                                  handleBlur(e);
                                }}
                              />
                            )}
                          />
                        </div>
                        {errors.code && (
                          <span className="error-text">
                            {errors.code.message}
                          </span>
                        )}

                        <Button
                          ButtonText={"Login"}
                          className={"login"}
                          loading={loading}
                          type="submit"
                        />

                        <div
                          className="edit-email-btn"
                          onClick={() => {
                            setOtpStep(1);
                            setCode("");
                            setUserEmail("");
                          }}
                        >
                          <FiEdit /> Edit email
                        </div>
                      </>
                    )}
                  </>
                )}

                {loginType === "email" && (
                  <>
                    <div className=" wrap-input100 validate-input`">
                      <input
                        className="input100 has-val"
                        type={"text"}
                        {...register("email")}
                        onBlur={(e) => handleBlur(e)}
                        name="email"
                        autoComplete="email"
                      />
                      <span
                        className="focus-input100"
                        data-placeholder={"Email"}
                      ></span>
                    </div>
                    {errors.email && (
                      <span className="error-text">{errors.email.message}</span>
                    )}

                    <div className=" wrap-input100 validate-input`">
                      <span className="btn-show-pass">
                        <i
                          className="far fa-eye"
                          onClick={(e) => handleShowPass(e)}
                        ></i>
                      </span>
                      <input
                        className="input100 has-val"
                        type={"password"}
                        {...register("pass")}
                        onBlur={(e) => handleBlur(e)}
                        name="pass"
                        autoComplete="current-password"
                      />

                      <span
                        className="focus-input100"
                        data-placeholder={"Password"}
                      ></span>
                    </div>
                    {errors.pass && (
                      <span className="error-text">{errors.pass.message}</span>
                    )}
                    <div className="captcha-container">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LdHiBciAAAAAJMxTY9eLXcU3LzijbFu5KRfp6Bg"
                        onChange={onChange}
                        onErrored={resetCaptcha}
                        onExpired={resetCaptcha}
                        // ref={...register("recaptcha")}
                      />
                    </div>

                    {errors.recaptcha && (
                      <span className="error-text">
                        {errors.recaptcha.message}
                      </span>
                    )}

                    <Button
                      ButtonText={"LogIn"}
                      className={"login"}
                      loading={loading || googleLoading}
                    />
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <ConfirmAlert
        show={showConfirmAlert}
        setShow={setShowConfirmAlert}
        email={userEmail}
        password={pass}
      />
    </div>
  );
}

export default LoginPage;
