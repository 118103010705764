export const riskOptions = [
  "Very Cautious",
  "Cautious",
  "Balanced",
  "Adventurous",
  "Very Adventurous",
];

export const riskAppetiteColor = [
  "#ff0000",
  "#ffa500",
  "#02a82b",
  "#00bfff",
  "#022da1 ",
];

export const certificatedExchange = [
  "cryptocrispy",
  "swyftx",
  "binance",
  "coinbase",
  "kraken",
  "bybit",
  "krakenfutures",
  "kucoin",
  "kucoinfutures",
  "okx",
  "gate",
];

export const botScoreColor = (score) => {
  if (score >= 90) {
    return "#02a82b";
  } else if (score >= 40 && score < 90) {
    return "#ffa500";
  } else {
    return "#ff0000";
  }
};

export const categoryTooltip = {
  Market:
    "Choose Market to see features such as price, candles and tickers, etc.",
  "Technical Indicators": "Various technical indicators",
  Blockchain: "Various on-chain indicators",
  Financial: "Various financial indicators",
  "On-Chain": "Various on-chain indicators",
  Social: "Social indicators like social dominance, key figures, etc",
  "AI-Signal": "AI signals give you price prediction in time intervals",
};

// All countries
// length 252
export const countryList = [
  { name: "Afghanistan", code: "AF", phone: 93 },
  { name: "Aland Islands", code: "AX", phone: 358 },
  { name: "Albania", code: "AL", phone: 355 },
  { name: "Algeria", code: "DZ", phone: 213 },
  { name: "American Samoa", code: "AS", phone: 1684 },
  { name: "Andorra", code: "AD", phone: 376 },
  { name: "Angola", code: "AO", phone: 244 },
  { name: "Anguilla", code: "AI", phone: 1264 },
  { name: "Antarctica", code: "AQ", phone: 672 },
  { name: "Antigua and Barbuda", code: "AG", phone: 1268 },
  { name: "Argentina", code: "AR", phone: 54 },
  { name: "Armenia", code: "AM", phone: 374 },
  { name: "Aruba", code: "AW", phone: 297 },
  { name: "Australia", code: "AU", phone: 61 },
  { name: "Austria", code: "AT", phone: 43 },
  { name: "Azerbaijan", code: "AZ", phone: 994 },
  { name: "Bahamas", code: "BS", phone: 1242 },
  { name: "Bahrain", code: "BH", phone: 973 },
  { name: "Bangladesh", code: "BD", phone: 880 },
  { name: "Barbados", code: "BB", phone: 1246 },
  { name: "Belarus", code: "BY", phone: 375 },
  { name: "Belgium", code: "BE", phone: 32 },
  { name: "Belize", code: "BZ", phone: 501 },
  { name: "Benin", code: "BJ", phone: 229 },
  { name: "Bermuda", code: "BM", phone: 1441 },
  { name: "Bhutan", code: "BT", phone: 975 },
  { name: "Bolivia", code: "BO", phone: 591 },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BQ", phone: 599 },
  { name: "Bosnia and Herzegovina", code: "BA", phone: 387 },
  { name: "Botswana", code: "BW", phone: 267 },
  { name: "Bouvet Island", code: "BV", phone: 55 },
  { name: "Brazil", code: "BR", phone: 55 },
  { name: "British Indian Ocean Territory", code: "IO", phone: 246 },
  { name: "Brunei Darussalam", code: "BN", phone: 673 },
  { name: "Bulgaria", code: "BG", phone: 359 },
  { name: "Burkina Faso", code: "BF", phone: 226 },
  { name: "Burundi", code: "BI", phone: 257 },
  { name: "Cambodia", code: "KH", phone: 855 },
  { name: "Cameroon", code: "CM", phone: 237 },
  { name: "Canada", code: "CA", phone: 1 },
  { name: "Cape Verde", code: "CV", phone: 238 },
  { name: "Cayman Islands", code: "KY", phone: 1345 },
  { name: "Central African Republic", code: "CF", phone: 236 },
  { name: "Chad", code: "TD", phone: 235 },
  { name: "Chile", code: "CL", phone: 56 },
  { name: "China", code: "CN", phone: 86 },
  { name: "Christmas Island", code: "CX", phone: 61 },
  { name: "Cocos (Keeling) Islands", code: "CC", phone: 672 },
  { name: "Colombia", code: "CO", phone: 57 },
  { name: "Comoros", code: "KM", phone: 269 },
  { name: "Congo", code: "CG", phone: 242 },
  { name: "Congo, Democratic Republic of the Congo", code: "CD", phone: 242 },
  { name: "Cook Islands", code: "CK", phone: 682 },
  { name: "Costa Rica", code: "CR", phone: 506 },
  { name: "Cote D'Ivoire", code: "CI", phone: 225 },
  { name: "Croatia", code: "HR", phone: 385 },
  { name: "Cuba", code: "CU", phone: 53 },
  { name: "Curacao", code: "CW", phone: 599 },
  { name: "Cyprus", code: "CY", phone: 357 },
  { name: "Czech Republic", code: "CZ", phone: 420 },
  { name: "Denmark", code: "DK", phone: 45 },
  { name: "Djibouti", code: "DJ", phone: 253 },
  { name: "Dominica", code: "DM", phone: 1767 },
  { name: "Dominican Republic", code: "DO", phone: 1809 },
  { name: "Ecuador", code: "EC", phone: 593 },
  { name: "Egypt", code: "EG", phone: 20 },
  { name: "El Salvador", code: "SV", phone: 503 },
  { name: "Equatorial Guinea", code: "GQ", phone: 240 },
  { name: "Eritrea", code: "ER", phone: 291 },
  { name: "Estonia", code: "EE", phone: 372 },
  { name: "Ethiopia", code: "ET", phone: 251 },
  { name: "Falkland Islands (Malvinas)", code: "FK", phone: 500 },
  { name: "Faroe Islands", code: "FO", phone: 298 },
  { name: "Fiji", code: "FJ", phone: 679 },
  { name: "Finland", code: "FI", phone: 358 },
  { name: "France", code: "FR", phone: 33 },
  { name: "French Guiana", code: "GF", phone: 594 },
  { name: "French Polynesia", code: "PF", phone: 689 },
  { name: "French Southern Territories", code: "TF", phone: 262 },
  { name: "Gabon", code: "GA", phone: 241 },
  { name: "Gambia", code: "GM", phone: 220 },
  { name: "Georgia", code: "GE", phone: 995 },
  { name: "Germany", code: "DE", phone: 49 },
  { name: "Ghana", code: "GH", phone: 233 },
  { name: "Gibraltar", code: "GI", phone: 350 },
  { name: "Greece", code: "GR", phone: 30 },
  { name: "Greenland", code: "GL", phone: 299 },
  { name: "Grenada", code: "GD", phone: 1473 },
  { name: "Guadeloupe", code: "GP", phone: 590 },
  { name: "Guam", code: "GU", phone: 1671 },
  { name: "Guatemala", code: "GT", phone: 502 },
  { name: "Guernsey", code: "GG", phone: 44 },
  { name: "Guinea", code: "GN", phone: 224 },
  { name: "Guinea-Bissau", code: "GW", phone: 245 },
  { name: "Guyana", code: "GY", phone: 592 },
  { name: "Haiti", code: "HT", phone: 509 },
  { name: "Heard Island and McDonald Islands", code: "HM", phone: 0 },
  { name: "Holy See (Vatican City State)", code: "VA", phone: 39 },
  { name: "Honduras", code: "HN", phone: 504 },
  { name: "Hong Kong", code: "HK", phone: 852 },
  { name: "Hungary", code: "HU", phone: 36 },
  { name: "Iceland", code: "IS", phone: 354 },
  { name: "India", code: "IN", phone: 91 },
  { name: "Indonesia", code: "ID", phone: 62 },
  { name: "Iran, Islamic Republic of", code: "IR", phone: 98 },
  { name: "Iraq", code: "IQ", phone: 964 },
  { name: "Ireland", code: "IE", phone: 353 },
  { name: "Isle of Man", code: "IM", phone: 44 },
  { name: "Israel", code: "IL", phone: 972 },
  { name: "Italy", code: "IT", phone: 39 },
  { name: "Jamaica", code: "JM", phone: 1876 },
  { name: "Japan", code: "JP", phone: 81 },
  { name: "Jersey", code: "JE", phone: 44 },
  { name: "Jordan", code: "JO", phone: 962 },
  { name: "Kazakhstan", code: "KZ", phone: 7 },
  { name: "Kenya", code: "KE", phone: 254 },
  { name: "Kiribati", code: "KI", phone: 686 },
  { name: "Korea, Democratic People's Republic of", code: "KP", phone: 850 },
  { name: "Korea, Republic of", code: "KR", phone: 82 },
  { name: "Kosovo", code: "XK", phone: 381 },
  { name: "Kuwait", code: "KW", phone: 965 },
  { name: "Kyrgyzstan", code: "KG", phone: 996 },
  { name: "Lao People's Democratic Republic", code: "LA", phone: 856 },
  { name: "Latvia", code: "LV", phone: 371 },
  { name: "Lebanon", code: "LB", phone: 961 },
  { name: "Lesotho", code: "LS", phone: 266 },
  { name: "Liberia", code: "LR", phone: 231 },
  { name: "Libyan Arab Jamahiriya", code: "LY", phone: 218 },
  { name: "Liechtenstein", code: "LI", phone: 423 },
  { name: "Lithuania", code: "LT", phone: 370 },
  { name: "Luxembourg", code: "LU", phone: 352 },
  { name: "Macao", code: "MO", phone: 853 },
  {
    name: "Macedonia, the Former Yugoslav Republic of",
    code: "MK",
    phone: 389,
  },
  { name: "Madagascar", code: "MG", phone: 261 },
  { name: "Malawi", code: "MW", phone: 265 },
  { name: "Malaysia", code: "MY", phone: 60 },
  { name: "Maldives", code: "MV", phone: 960 },
  { name: "Mali", code: "ML", phone: 223 },
  { name: "Malta", code: "MT", phone: 356 },
  { name: "Marshall Islands", code: "MH", phone: 692 },
  { name: "Martinique", code: "MQ", phone: 596 },
  { name: "Mauritania", code: "MR", phone: 222 },
  { name: "Mauritius", code: "MU", phone: 230 },
  { name: "Mayotte", code: "YT", phone: 262 },
  { name: "Mexico", code: "MX", phone: 52 },
  { name: "Micronesia, Federated States of", code: "FM", phone: 691 },
  { name: "Moldova, Republic of", code: "MD", phone: 373 },
  { name: "Monaco", code: "MC", phone: 377 },
  { name: "Mongolia", code: "MN", phone: 976 },
  { name: "Montenegro", code: "ME", phone: 382 },
  { name: "Montserrat", code: "MS", phone: 1664 },
  { name: "Morocco", code: "MA", phone: 212 },
  { name: "Mozambique", code: "MZ", phone: 258 },
  { name: "Myanmar", code: "MM", phone: 95 },
  { name: "Namibia", code: "NA", phone: 264 },
  { name: "Nauru", code: "NR", phone: 674 },
  { name: "Nepal", code: "NP", phone: 977 },
  { name: "Netherlands", code: "NL", phone: 31 },
  { name: "Netherlands Antilles", code: "AN", phone: 599 },
  { name: "New Caledonia", code: "NC", phone: 687 },
  { name: "New Zealand", code: "NZ", phone: 64 },
  { name: "Nicaragua", code: "NI", phone: 505 },
  { name: "Niger", code: "NE", phone: 227 },
  { name: "Nigeria", code: "NG", phone: 234 },
  { name: "Niue", code: "NU", phone: 683 },
  { name: "Norfolk Island", code: "NF", phone: 672 },
  { name: "Northern Mariana Islands", code: "MP", phone: 1670 },
  { name: "Norway", code: "NO", phone: 47 },
  { name: "Oman", code: "OM", phone: 968 },
  { name: "Pakistan", code: "PK", phone: 92 },
  { name: "Palau", code: "PW", phone: 680 },
  { name: "Palestinian Territory, Occupied", code: "PS", phone: 970 },
  { name: "Panama", code: "PA", phone: 507 },
  { name: "Papua New Guinea", code: "PG", phone: 675 },
  { name: "Paraguay", code: "PY", phone: 595 },
  { name: "Peru", code: "PE", phone: 51 },
  { name: "Philippines", code: "PH", phone: 63 },
  { name: "Pitcairn", code: "PN", phone: 64 },
  { name: "Poland", code: "PL", phone: 48 },
  { name: "Portugal", code: "PT", phone: 351 },
  { name: "Puerto Rico", code: "PR", phone: 1787 },
  { name: "Qatar", code: "QA", phone: 974 },
  { name: "Reunion", code: "RE", phone: 262 },
  { name: "Romania", code: "RO", phone: 40 },
  { name: "Russian Federation", code: "RU", phone: 7 },
  { name: "Rwanda", code: "RW", phone: 250 },
  { name: "Saint Barthelemy", code: "BL", phone: 590 },
  { name: "Saint Helena", code: "SH", phone: 290 },
  { name: "Saint Kitts and Nevis", code: "KN", phone: 1869 },
  { name: "Saint Lucia", code: "LC", phone: 1758 },
  { name: "Saint Martin", code: "MF", phone: 590 },
  { name: "Saint Pierre and Miquelon", code: "PM", phone: 508 },
  { name: "Saint Vincent and the Grenadines", code: "VC", phone: 1784 },
  { name: "Samoa", code: "WS", phone: 684 },
  { name: "San Marino", code: "SM", phone: 378 },
  { name: "Sao Tome and Principe", code: "ST", phone: 239 },
  { name: "Saudi Arabia", code: "SA", phone: 966 },
  { name: "Senegal", code: "SN", phone: 221 },
  { name: "Serbia", code: "RS", phone: 381 },
  { name: "Serbia and Montenegro", code: "CS", phone: 381 },
  { name: "Seychelles", code: "SC", phone: 248 },
  { name: "Sierra Leone", code: "SL", phone: 232 },
  { name: "Singapore", code: "SG", phone: 65 },
  { name: "St Martin", code: "SX", phone: 721 },
  { name: "Slovakia", code: "SK", phone: 421 },
  { name: "Slovenia", code: "SI", phone: 386 },
  { name: "Solomon Islands", code: "SB", phone: 677 },
  { name: "Somalia", code: "SO", phone: 252 },
  { name: "South Africa", code: "ZA", phone: 27 },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    phone: 500,
  },
  { name: "South Sudan", code: "SS", phone: 211 },
  { name: "Spain", code: "ES", phone: 34 },
  { name: "Sri Lanka", code: "LK", phone: 94 },
  { name: "Sudan", code: "SD", phone: 249 },
  { name: "Suriname", code: "SR", phone: 597 },
  { name: "Svalbard and Jan Mayen", code: "SJ", phone: 47 },
  { name: "Swaziland", code: "SZ", phone: 268 },
  { name: "Sweden", code: "SE", phone: 46 },
  { name: "Switzerland", code: "CH", phone: 41 },
  { name: "Syrian Arab Republic", code: "SY", phone: 963 },
  { name: "Taiwan, Province of China", code: "TW", phone: 886 },
  { name: "Tajikistan", code: "TJ", phone: 992 },
  { name: "Tanzania, United Republic of", code: "TZ", phone: 255 },
  { name: "Thailand", code: "TH", phone: 66 },
  { name: "Timor-Leste", code: "TL", phone: 670 },
  { name: "Togo", code: "TG", phone: 228 },
  { name: "Tokelau", code: "TK", phone: 690 },
  { name: "Tonga", code: "TO", phone: 676 },
  { name: "Trinidad and Tobago", code: "TT", phone: 1868 },
  { name: "Tunisia", code: "TN", phone: 216 },
  { name: "Turkey", code: "TR", phone: 90 },
  { name: "Turkmenistan", code: "TM", phone: 7370 },
  { name: "Turks and Caicos Islands", code: "TC", phone: 1649 },
  { name: "Tuvalu", code: "TV", phone: 688 },
  { name: "Uganda", code: "UG", phone: 256 },
  { name: "Ukraine", code: "UA", phone: 380 },
  { name: "United Arab Emirates", code: "AE", phone: 971 },
  { name: "United Kingdom", code: "GB", phone: 44 },
  { name: "United States", code: "US", phone: 1 },
  { name: "United States Minor Outlying Islands", code: "UM", phone: 1 },
  { name: "Uruguay", code: "UY", phone: 598 },
  { name: "Uzbekistan", code: "UZ", phone: 998 },
  { name: "Vanuatu", code: "VU", phone: 678 },
  { name: "Venezuela", code: "VE", phone: 58 },
  { name: "Viet Nam", code: "VN", phone: 84 },
  { name: "Virgin Islands, British", code: "VG", phone: 1284 },
  { name: "Virgin Islands, U.s.", code: "VI", phone: 1340 },
  { name: "Wallis and Futuna", code: "WF", phone: 681 },
  { name: "Western Sahara", code: "EH", phone: 212 },
  { name: "Yemen", code: "YE", phone: 967 },
  { name: "Zambia", code: "ZM", phone: 260 },
  { name: "Zimbabwe", code: "ZW", phone: 263 },
];

export const timeZoneToCountryCode = {
  "Africa/Abidjan": "CI",
  "Africa/Accra": "GH",
  "Africa/Addis_Ababa": "ET",
  "Africa/Algiers": "DZ",
  "Africa/Asmara": "ER",
  "Africa/Bamako": "ML",
  "Africa/Bangui": "CF",
  "Africa/Banjul": "GM",
  "Africa/Bissau": "GW",
  "Africa/Blantyre": "MW",
  "Africa/Brazzaville": "CG",
  "Africa/Bujumbura": "BI",
  "Africa/Cairo": "EG",
  "Africa/Casablanca": "MA",
  "Africa/Ceuta": "ES",
  "Africa/Conakry": "GN",
  "Africa/Dakar": "SN",
  "Africa/Dar_es_Salaam": "TZ",
  "Africa/Djibouti": "DJ",
  "Africa/Douala": "CM",
  "Africa/El_Aaiun": "EH",
  "Africa/Freetown": "SL",
  "Africa/Gaborone": "BW",
  "Africa/Harare": "ZW",
  "Africa/Johannesburg": "ZA",
  "Africa/Juba": "SS",
  "Africa/Kampala": "UG",
  "Africa/Khartoum": "SD",
  "Africa/Kigali": "RW",
  "Africa/Kinshasa": "CD",
  "Africa/Lagos": "NG",
  "Africa/Libreville": "GA",
  "Africa/Lome": "TG",
  "Africa/Luanda": "AO",
  "Africa/Lubumbashi": "CD",
  "Africa/Lusaka": "ZM",
  "Africa/Malabo": "GQ",
  "Africa/Maputo": "MZ",
  "Africa/Maseru": "LS",
  "Africa/Mbabane": "SZ",
  "Africa/Mogadishu": "SO",
  "Africa/Monrovia": "LR",
  "Africa/Nairobi": "KE",
  "Africa/Ndjamena": "TD",
  "Africa/Niamey": "NE",
  "Africa/Nouakchott": "MR",
  "Africa/Ouagadougou": "BF",
  "Africa/Porto-Novo": "BJ",
  "Africa/Sao_Tome": "ST",
  "Africa/Tripoli": "LY",
  "Africa/Tunis": "TN",
  "Africa/Windhoek": "NA",
  "America/Adak": "US",
  "America/Anchorage": "US",
  "America/Anguilla": "AI",
  "America/Antigua": "AG",
  "America/Araguaina": "BR",
  "America/Argentina/Buenos_Aires": "AR",
  "America/Argentina/Catamarca": "AR",
  "America/Argentina/Cordoba": "AR",
  "America/Argentina/Jujuy": "AR",
  "America/Argentina/La_Rioja": "AR",
  "America/Argentina/Mendoza": "AR",
  "America/Argentina/Rio_Gallegos": "AR",
  "America/Argentina/Salta": "AR",
  "America/Argentina/San_Juan": "AR",
  "America/Argentina/San_Luis": "AR",
  "America/Argentina/Tucuman": "AR",
  "America/Argentina/Ushuaia": "AR",
  "America/Aruba": "AW",
  "America/Asuncion": "PY",
  "America/Atikokan": "CA",
  "America/Bahia": "BR",
  "America/Bahia_Banderas": "MX",
  "America/Barbados": "BB",
  "America/Belem": "BR",
  "America/Belize": "BZ",
  "America/Blanc-Sablon": "CA",
  "America/Boa_Vista": "BR",
  "America/Bogota": "CO",
  "America/Boise": "US",
  "America/Cambridge_Bay": "CA",
  "America/Campo_Grande": "BR",
  "America/Cancun": "MX",
  "America/Caracas": "VE",
  "America/Cayenne": "GF",
  "America/Cayman": "KY",
  "America/Chicago": "US",
  "America/Chihuahua": "MX",
  "America/Costa_Rica": "CR",
  "America/Creston": "CA",
  "America/Cuiaba": "BR",
  "America/Curacao": "CW",
  "America/Danmarkshavn": "GL",
  "America/Dawson": "CA",
  "America/Dawson_Creek": "CA",
  "America/Denver": "US",
  "America/Detroit": "US",
  "America/Dominica": "DM",
  "America/Edmonton": "CA",
  "America/Eirunepe": "BR",
  "America/El_Salvador": "SV",
  "America/Fort_Nelson": "CA",
  "America/Fortaleza": "BR",
  "America/Glace_Bay": "CA",
  "America/Godthab": "GL",
  "America/Goose_Bay": "CA",
  "America/Grand_Turk": "TC",
  "America/Grenada": "GD",
  "America/Guadeloupe": "GP",
  "America/Guatemala": "GT",
  "America/Guayaquil": "EC",
  "America/Guyana": "GY",
  "America/Halifax": "CA",
  "America/Havana": "CU",
  "America/Hermosillo": "MX",
  "America/Indiana/Indianapolis": "US",
  "America/Indiana/Knox": "US",
  "America/Indiana/Marengo": "US",
  "America/Indiana/Petersburg": "US",
  "America/Indiana/Tell_City": "US",
  "America/Indiana/Vevay": "US",
  "America/Indiana/Vincennes": "US",
  "America/Indiana/Winamac": "US",
  "America/Inuvik": "CA",
  "America/Iqaluit": "CA",
  "America/Jamaica": "JM",
  "America/Juneau": "US",
  "America/Kentucky/Louisville": "US",
  "America/Kentucky/Monticello": "US",
  "America/Kralendijk": "BQ",
  "America/La_Paz": "BO",
  "America/Lima": "PE",
  "America/Los_Angeles": "US",
  "America/Maceio": "BR",
  "America/Managua": "NI",
  "America/Manaus": "BR",
  "America/Marigot": "MF",
  "America/Martinique": "MQ",
  "America/Matamoros": "MX",
  "America/Mazatlan": "MX",
  "America/Menominee": "US",
  "America/Merida": "MX",
  "America/Metlakatla": "US",
  "America/Mexico_City": "MX",
  "America/Miquelon": "PM",
  "America/Moncton": "CA",
  "America/Monterrey": "MX",
  "America/Montevideo": "UY",
  "America/Montserrat": "MS",
  "America/Nassau": "BS",
  "America/New_York": "US",
  "America/Nipigon": "CA",
  "America/Nome": "US",
  "America/Noronha": "BR",
  "America/North_Dakota/Beulah": "US",
  "America/North_Dakota/Center": "US",
  "America/North_Dakota/New_Salem": "US",
  "America/Nuuk": "GL",
  "America/Ojinaga": "US",
  "America/Panama": "PA",
  "America/Pangnirtung": "CA",
  "America/Paramaribo": "SR",
  "America/Phoenix": "US",
  "America/Port-au-Prince": "HT",
  "America/Port_of_Spain": "TT",
  "America/Porto_Velho": "BR",
  "America/Puerto_Rico": "PR",
  "America/Punta_Arenas": "CL",
  "America/Rainy_River": "CA",
  "America/Rankin_Inlet": "CA",
  "America/Recife": "BR",
  "America/Regina": "CA",
  "America/Resolute": "CA",
  "America/Rio_Branco": "BR",
  "America/Santarem": "BR",
  "America/Santiago": "CL",
  "America/Santo_Domingo": "DO",
  "America/Sao_Paulo": "BR",
  "America/Scoresbysund": "GL",
  "America/Sitka": "US",
  "America/St_Barthelemy": "BL",
  "America/St_Johns": "CA",
  "America/St_Kitts": "KN",
  "America/St_Lucia": "LC",
  "America/St_Thomas": "VI",
  "America/St_Vincent": "VC",
  "America/Swift_Current": "CA",
  "America/Tegucigalpa": "HN",
  "America/Thule": "GL",
  "America/Thunder_Bay": "CA",
  "America/Tijuana": "MX",
  "America/Toronto": "CA",
  "America/Tortola": "VG",
  "America/Vancouver": "CA",
  "America/Whitehorse": "CA",
  "America/Winnipeg": "CA",
  "America/Yakutat": "US",
  "America/Yellowknife": "CA",
  "Antarctica/Casey": "AQ",
  "Antarctica/Davis": "AQ",
  "Antarctica/DumontDUrville": "AQ",
  "Antarctica/Macquarie": "AQ",
  "Antarctica/Mawson": "AQ",
  "Antarctica/McMurdo": "AQ",
  "Antarctica/Palmer": "AQ",
  "Antarctica/Rothera": "AQ",
  "Antarctica/Syowa": "AQ",
  "Antarctica/Troll": "AQ",
  "Antarctica/Vostok": "AQ",
  "Arctic/Longyearbyen": "SJ",
  "Asia/Aden": "YE",
  "Asia/Almaty": "KZ",
  "Asia/Amman": "JO",
  "Asia/Anadyr": "RU",
  "Asia/Aqtau": "KZ",
  "Asia/Aqtobe": "KZ",
  "Asia/Ashgabat": "TM",
  "Asia/Atyrau": "KZ",
  "Asia/Baghdad": "IQ",
  "Asia/Bahrain": "BH",
  "Asia/Baku": "AZ",
  "Asia/Bangkok": "TH",
  "Asia/Barnaul": "RU",
  "Asia/Beirut": "LB",
  "Asia/Bishkek": "KG",
  "Asia/Brunei": "BN",
  "Asia/Chita": "RU",
  "Asia/Choibalsan": "MN",
  "Asia/Colombo": "LK",
  "Asia/Damascus": "SY",
  "Asia/Dhaka": "BD",
  "Asia/Dili": "TL",
  "Asia/Dubai": "AE",
  "Asia/Dushanbe": "TJ",
  "Asia/Famagusta": "CY",
  "Asia/Gaza": "PS",
  "Asia/Hebron": "PS",
  "Asia/Ho_Chi_Minh": "VN",
  "Asia/Hong_Kong": "HK",
  "Asia/Hovd": "MN",
  "Asia/Irkutsk": "RU",
  "Asia/Jakarta": "ID",
  "Asia/Jayapura": "ID",
  "Asia/Jerusalem": "IL",
  "Asia/Kabul": "AF",
  "Asia/Kamchatka": "RU",
  "Asia/Karachi": "PK",
  "Asia/Kathmandu": "NP",
  "Asia/Khandyga": "RU",
  "Asia/Kolkata": "IN",
  "Asia/Krasnoyarsk": "RU",
  "Asia/Kuala_Lumpur": "MY",
  "Asia/Kuching": "MY",
  "Asia/Kuwait": "KW",
  "Asia/Macau": "MO",
  "Asia/Magadan": "RU",
  "Asia/Makassar": "ID",
  "Asia/Manila": "PH",
  "Asia/Muscat": "OM",
  "Asia/Nicosia": "CY",
  "Asia/Novokuznetsk": "RU",
  "Asia/Novosibirsk": "RU",
  "Asia/Omsk": "RU",
  "Asia/Oral": "KZ",
  "Asia/Phnom_Penh": "KH",
  "Asia/Pontianak": "ID",
  "Asia/Pyongyang": "KP",
  "Asia/Qatar": "QA",
  "Asia/Qostanay": "KZ",
  "Asia/Qyzylorda": "KZ",
  "Asia/Riyadh": "SA",
  "Asia/Sakhalin": "RU",
  "Asia/Samarkand": "UZ",
  "Asia/Seoul": "KR",
  "Asia/Shanghai": "CN",
  "Asia/Singapore": "SG",
  "Asia/Srednekolymsk": "RU",
  "Asia/Taipei": "TW",
  "Asia/Tashkent": "UZ",
  "Asia/Tbilisi": "GE",
  "Asia/Tehran": "IR",
  "Asia/Thimphu": "BT",
  "Asia/Tokyo": "JP",
  "Asia/Tomsk": "RU",
  "Asia/Ulaanbaatar": "MN",
  "Asia/Urumqi": "CN",
  "Asia/Ust-Nera": "RU",
  "Asia/Vientiane": "LA",
  "Asia/Vladivostok": "RU",
  "Asia/Yakutsk": "RU",
  "Asia/Yangon": "MM",
  "Asia/Yekaterinburg": "RU",
  "Asia/Yerevan": "AM",
  "Atlantic/Azores": "PT",
  "Atlantic/Bermuda": "BM",
  "Atlantic/Canary": "ES",
  "Atlantic/Cape_Verde": "CV",
  "Atlantic/Faroe": "FO",
  "Atlantic/Madeira": "PT",
  "Atlantic/Reykjavik": "IS",
  "Atlantic/South_Georgia": "GS",
  "Atlantic/St_Helena": "SH",
  "Atlantic/Stanley": "FK",
  "Australia/Adelaide": "AU",
  "Australia/Brisbane": "AU",
  "Australia/Broken_Hill": "AU",
  "Australia/Currie": "AU",
  "Australia/Darwin": "AU",
  "Australia/Eucla": "AU",
  "Australia/Hobart": "AU",
  "Australia/Lindeman": "AU",
  "Australia/Lord_Howe": "AU",
  "Australia/Melbourne": "AU",
  "Australia/Perth": "AU",
  "Australia/Sydney": "AU",
  "Europe/Amsterdam": "NL",
  "Europe/Andorra": "AD",
  "Europe/Astrakhan": "RU",
  "Europe/Athens": "GR",
  "Europe/Belgrade": "RS",
  "Europe/Berlin": "DE",
  "Europe/Bratislava": "SK",
  "Europe/Brussels": "BE",
  "Europe/Bucharest": "RO",
  "Europe/Budapest": "HU",
  "Europe/Busingen": "DE",
  "Europe/Chisinau": "MD",
  "Europe/Copenhagen": "DK",
  "Europe/Dublin": "IE",
  "Europe/Gibraltar": "GI",
  "Europe/Guernsey": "GG",
  "Europe/Helsinki": "FI",
  "Europe/Isle_of_Man": "IM",
  "Europe/Istanbul": "TR",
  "Europe/Jersey": "JE",
  "Europe/Kaliningrad": "RU",
  "Europe/Kiev": "UA",
  "Europe/Kirov": "RU",
  "Europe/Lisbon": "PT",
  "Europe/Ljubljana": "SI",
  "Europe/London": "GB",
  "Europe/Luxembourg": "LU",
  "Europe/Madrid": "ES",
  "Europe/Malta": "MT",
  "Europe/Mariehamn": "AX",
  "Europe/Minsk": "BY",
  "Europe/Monaco": "MC",
  "Europe/Moscow": "RU",
  "Europe/Oslo": "NO",
  "Europe/Paris": "FR",
  "Europe/Podgorica": "ME",
  "Europe/Prague": "CZ",
  "Europe/Riga": "LV",
  "Europe/Rome": "IT",
  "Europe/Samara": "RU",
  "Europe/San_Marino": "SM",
  "Europe/Sarajevo": "BA",
  "Europe/Saratov": "RU",
  "Europe/Simferopol": "UA",
  "Europe/Skopje": "MK",
  "Europe/Sofia": "BG",
  "Europe/Stockholm": "SE",
  "Europe/Tallinn": "EE",
  "Europe/Tirane": "AL",
  "Europe/Ulyanovsk": "RU",
  "Europe/Uzhgorod": "UA",
  "Europe/Vaduz": "LI",
  "Europe/Vatican": "VA",
  "Europe/Vienna": "AT",
  "Europe/Vilnius": "LT",
  "Europe/Volgograd": "RU",
  "Europe/Warsaw": "PL",
  "Europe/Zagreb": "HR",
  "Europe/Zaporozhye": "UA",
  "Europe/Zurich": "CH",
  "Indian/Antananarivo": "MG",
  "Indian/Chagos": "IO",
  "Indian/Christmas": "CX",
  "Indian/Cocos": "CC",
  "Indian/Comoro": "KM",
  "Indian/Kerguelen": "TF",
  "Indian/Mahe": "SC",
  "Indian/Maldives": "MV",
  "Indian/Mauritius": "MU",
  "Indian/Mayotte": "YT",
  "Indian/Reunion": "RE",
  "Pacific/Apia": "WS",
  "Pacific/Auckland": "NZ",
  "Pacific/Bougainville": "PG",
  "Pacific/Chatham": "NZ",
  "Pacific/Chuuk": "FM",
  "Pacific/Easter": "CL",
  "Pacific/Efate": "VU",
  "Pacific/Enderbury": "KI",
  "Pacific/Fakaofo": "TK",
  "Pacific/Fiji": "FJ",
  "Pacific/Funafuti": "TV",
  "Pacific/Galapagos": "EC",
  "Pacific/Gambier": "PF",
  "Pacific/Guadalcanal": "SB",
  "Pacific/Guam": "GU",
  "Pacific/Honolulu": "US",
  "Pacific/Kiritimati": "KI",
  "Pacific/Kosrae": "FM",
  "Pacific/Kwajalein": "MH",
  "Pacific/Majuro": "MH",
  "Pacific/Marquesas": "PF",
  "Pacific/Midway": "UM",
  "Pacific/Nauru": "NR",
  "Pacific/Niue": "NU",
  "Pacific/Norfolk": "NF",
  "Pacific/Noumea": "NC",
  "Pacific/Pago_Pago": "AS",
  "Pacific/Palau": "PW",
  "Pacific/Pitcairn": "PN",
  "Pacific/Pohnpei": "FM",
  "Pacific/Port_Moresby": "PG",
  "Pacific/Rarotonga": "CK",
  "Pacific/Saipan": "MP",
  "Pacific/Tahiti": "PF",
  "Pacific/Tarawa": "KI",
  "Pacific/Tongatapu": "TO",
  "Pacific/Wake": "UM",
  "Pacific/Wallis": "WF",
};

export const servicesCost = {
  myais: 150,
  alert: 10,
  mybot: 25,
  insai: 10,
  subscription: 19.99,
  subscription_aiSignal: 149.99,
  yearly_discount: 30,
  referral_credit: 2,
  referral_earn_percentage: 10,
  account_create_credit: 5,
  aiSignal: 160,
};

export const servicesActivationMode = {
  // 'alert'
  // 'trsim'
  // aiSignal
  // 'insai
  // 'mybot'
  // 'myais'
  aiSignal: "myais_alert",
  tradeSimulator: "trsim",
  insights: "insai",
  bot: "mybot",
  ai: "myais",
  alert: "alert",
  mybot: "mybot",
};
