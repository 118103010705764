//
// main data types
//
export const SET_USERDATA = "SET_USERDATA";
export const SET_SYSTEM_DATA = "SET_SYSTEM_DATA";
export const SET_TOKEN = "SET_TOKEN";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_DATA_AS_LOCAL = "SET_DATA_AS_LOCAL";
export const SET_LOGOUT = "SET_LOGOUT";

export const SAVE_USER_PASS = "SAVE_USER_PASS";

export const OTP_SEND_CODE_REQUEST = "OTP_SEND_CODE_REQUEST";
export const OTP_SEND_CODE_SUCCESS = "OTP_SEND_CODE_SUCCESS";
export const OTP_SEND_CODE_ERROR = "OTP_SEND_CODE_ERROR";

export const OTP_VERIFY_CODE_REQUEST = "OTP_VERIFY_CODE_REQUEST";
export const OTP_VERIFY_CODE_SUCCESS = "OTP_VERIFY_CODE_SUCCESS";
export const OTP_VERIFY_CODE_ERROR = "OTP_VERIFY_CODE_ERROR";
