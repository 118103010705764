import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import SignUpPage from "./pages/Auth/SignUpPage";
import LoginPage from "./pages/Auth/LoginPage";
import "./asset/Fonts/Poppins-Bold.ttf";
import "./asset/Fonts/Poppins-Medium.ttf";
import "./asset/Fonts/Poppins-Regular.ttf";
import "./asset/Fonts/Poppins-SemiBold.ttf";
import "react-toastify/dist/ReactToastify.css";
import Confirm from "./pages/Auth/Confirm";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import { ToastContainer } from "react-toastify";
import React, { useEffect, useRef, useState } from "react";
import FinancialChartMultipleData from "./pages/Insight/index";
import DeveloperApi from "./pages/developer-api/Index";
import TradeAssistant from "./pages/trade-assistant";
import Help from "./pages/Help/Index";
import {
  saveTelegramAuthData,
  setLoginProcessToTelegram,
  setLogout,
  setToken,
  setUserData,
} from "./redux/main/action/main";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import PreviousEmail from "./pages/alert-system/previousEmail";
import Profile from "./pages/profile";
import { getProfileData } from "./redux/profile/actions";
import StartWizard from "./shared/StartWizard";
// import New from "./test/New";
import ExpiredPage from "./shared/ExpiredPage";
import ComingSoon from "./components/ComingSoon";
import moment from "moment";
import RatingModal from "./shared/RatingModal";
import ConnectExchange from "./shared/ConnectExchange";
import Integration from "./pages/Integration/Integration";
import PoolData from "./pages/Auth/AWSConfig/PoolData";
import BotAndAutomation from "./pages/Bot/BotAndAutomation";
import BacktestReport from "./components/bot/bot-backtest/BacktestReport";
import BotPerformanceReport from "./components/bot/bot-reports/bot-performance-report/BotPerformanceReport";
import WelcomePage from "./pages/WelcomePage";
import DashboardIndex from "./pages/dashboard/DashboardIndex";
import NewInsight from "./pages/new-insight/NewInsight";
import AlertSystem from "./pages/alert-system/AlertSystem";
import TelegramAuthentication from "./pages/TelegramAuthentication";
import TokenExpired from "./pages/TokenExpired";
import MiniDashboard from "./components/mini-app/MiniDashboard";
import MiniAISignals from "./components/mini-app/MiniAISignals";
import MiniBots from "./components/mini-app/MiniBots";
import PricingBillingIndex from "./pages/pricing-plan/PricingBillingIndex";
import MiniPricingPlan from "./components/mini-app/MiniPricingPlan";
import { getPricingPlan } from "./redux/pricing-plan/action";
import CreateBotProcess from "./components/mini-app/mini-bots/CreateBotProcess";
import { getTrustedIps } from "./redux/exchange/actions";
import MiniAppAllBots from "./components/mini-app/mini-bots/MiniAppAllBots";
import { getTelegramBotToken } from "./redux/alert-system/actions";
import "react-lazy-load-image-component/src/effects/blur.css";

function App() {
  const { profileData, updateLoading } = useSelector((state) => state?.profile);
  const { data } = useSelector((state) => state.dashboardData);
  const router = useNavigate();
  const location = useLocation();
  const {
    main: { token, userData },
    exchangeState: { trustedIps, getTrustedIpsStatus },
    alertSystem: { telegramBotToken, telegramBotTokenStatus },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [profileDataDispatched, setProfileDataDispatched] = useState(false);
  const [params] = useSearchParams();

  let routPath = location?.pathname;
  let authPath = routPath.split("/").find((item) => item === "auth") === "auth";

  const handleLogoutProcess = () => {
    if (localStorage.getItem("auth") !== "telegram") {
      dispatch(setLogout());
      const user = PoolData.getCurrentUser();
      user?.signOut();
      window.location.reload();
    } else {
      const origin = window.location.origin;
      const prevPath = window.location.href;

      window.location.href = "/refresh-token";
      const telegramData = localStorage.getItem("telegramData");
      localStorage.clear();
      localStorage.setItem("telegramData", telegramData);
      localStorage.setItem("auth", "telegram");
      localStorage.setItem("prevPath", `${origin}*${prevPath}`);
      // window.location.reload();
    }
  };

  const handleRegisteredEmail = (email) => setRegisteredEmail(email);

  let localToken = JSON.parse(
    localStorage.getItem("aws-amplify-federatedInfo"),
  )?.token;

  // TODO: NOTE: this code edited based on the telegram authentication
  useEffect(() => {
    if (
      userData?.email &&
      !profileDataDispatched &&
      !location?.pathname?.includes("telegram") &&
      !location?.pathname?.includes("expired")
    ) {
      dispatch(
        getProfileData(userData?.email, userData?.["custom:custom_username"]),
      );
      setProfileDataDispatched(true);
    }
  }, [userData, profileDataDispatched, location]);

  // TODO: NOTE: this code edited based on the telegram authentication

  useEffect(() => {
    if (
      !location?.pathname?.includes("telegram") &&
      (!localStorage.getItem("auth") ||
        localStorage.getItem("auth") !== "telegram")
    ) {
      if (localToken && !userData?.email) {
        let userData = JSON.parse(localStorage.getItem("aws-amplify-USERDATA"));
        axios.defaults.headers.common["Authorization"] = `Bearer ${localToken}`;

        dispatch(setToken(localToken));
        dispatch(setUserData(userData));
      }

      if (!localToken) {
        if (routPath !== "/") {
          if (!authPath) {
            return router(`/`);
          }
        }
      }

      if (localToken) {
        if (authPath) {
          return router("/welcome");
        }
      }
      if (!localToken) {
        if (authPath) {
          return router(routPath);
        }
        return router(`/auth/login`);
      }
      if ((token || localToken) && authPath) {
        return router("/welcome");
      }
    }

    // tokenRefresh()
  }, [token, localToken, location?.pathname]);
  // TODO: NOTE: this code added for the telegram authentication
  useEffect(() => {
    if (location?.pathname?.includes("telegram")) {
      localStorage.clear();

      const telegramAuth = params.get("tgWebAppStartParam");
      if (telegramAuth) {
        dispatch(saveTelegramAuthData(telegramAuth));
      }

      dispatch(setLoginProcessToTelegram());
      localStorage.setItem("auth", "telegram");
    }
  }, [location]);

  // TODO: NOTE: this code added for the telegram authentication
  useEffect(() => {
    if (localStorage.getItem("auth") === "telegram") {
      dispatch(setLoginProcessToTelegram());
      let userData = JSON.parse(localStorage.getItem("aws-amplify-USERDATA"));
      dispatch(setUserData(userData));
      const token =
        JSON.parse(localStorage.getItem("aws-amplify-USERDATA"))?.sub ||
        JSON.parse(localStorage.getItem("aws-amplify-federatedInfo"))?.token;
      dispatch(setToken(token));
      axios.defaults.headers.common["Authorization"] = `${token}`;
    }
  }, []);

  // Define a ref variable to store the start time
  const startTimeRef = useRef(null);

  // Define a function to get the current time in the desired format
  const getCurrentTime = () => {
    return moment().format("YYYY-MM-DD HH:mm:ss");
  };

  // Use the useEffect hook to update the start time when the location changes
  useEffect(() => {
    startTimeRef.current = getCurrentTime();
  }, [location.pathname]);

  // Use another useEffect hook to send the post request when the component unmounts
  useEffect(() => {
    // Define a cleanup function
    const cleanup = () => {
      // Get the end time
      const endTime = getCurrentTime();
      // Get the start time from the ref variable
      const startTime = startTimeRef.current;
      // Calculate the duration in seconds
      const duration = moment(endTime).diff(moment(startTime), "seconds");
      // Send the post request with the user data and the duration
      if (userData?.sub && data?.ip_address && duration > 0) {
        axios.post(
          "https://5k9w3fozlg.execute-api.ap-southeast-2.amazonaws.com/dev/api",
          {
            url: location.pathname,
            ip: data?.ip_address,
            duration: duration,
            visit_time: endTime,
            email: userData?.email,
            user: userData?.["custom:custom_username"],
          },
        );
      }
    };
    // Return the cleanup function from the useEffect hook
    return cleanup;
  }, [location.pathname, data, userData]);

  useEffect(() => {
    if (window.performance) {
      if (
        window.performance.navigation.type === "reload" ||
        window.performance.navigation.type === 1
      ) {
        localStorage.removeItem("dashboardStatus");
        localStorage.removeItem("assistantAtatus");
        localStorage.removeItem("selectedPortfolioExchange");
      }
    }
  }, []);
  useEffect(() => {
    localStorage.removeItem("dashboardStatus");
    localStorage.removeItem("assistantAtatus");
    localStorage.removeItem("selectedPortfolioExchange");
  }, []);
  // TODO: NOTE: this code edited based on the telegram authentication
  useEffect(() => {
    if (userData && userData.email) {
      // Set up an interval to execute a function every 30 minutes
      const intervalId = setInterval(
        async () => {
          await axios
            .post(
              "https://r7bphhzu5c.execute-api.us-east-1.amazonaws.com/dev/api",
              { platform_mode: "check_expiration" },
            )
            .then((res) => {
              if (res?.data?.has_expired !== "No") {
                handleLogoutProcess();
              }
            })
            .catch((err) => {
              handleLogoutProcess();
            });
        },
        30 * 60 * 1000,
      );

      // Clear the interval when the component unmounts or when userData changes
      return () => clearInterval(intervalId);
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if (
      location?.pathname?.includes("telegram") &&
      localStorage.getItem("telegram-auth-data")
    ) {
      const telegramAuth = JSON.parse(
        localStorage.getItem("telegram-auth-data"),
      );
      dispatch(saveTelegramAuthData(telegramAuth));
    }
  }, []);

  useEffect(() => {
    if (profileData.user) {
      if (
        !profileData?.suggested_features &&
        !updateLoading &&
        localStorage.getItem("auth") !== "telegram"
      ) {
        router("/start-wizard");
      }
    }
  }, [profileData]);

  useEffect(() => {
    dispatch(getPricingPlan());
  }, []);

  useEffect(() => {
    if (
      userData &&
      Object.keys(userData)?.length &&
      !trustedIps.length &&
      getTrustedIpsStatus !== "error" &&
      getTrustedIpsStatus !== "loading" &&
      token
    ) {
      dispatch(getTrustedIps());
    }
  }, [dispatch, trustedIps, userData, getTrustedIpsStatus, token]);

  useEffect(() => {
    if (
      userData?.email &&
      !telegramBotToken &&
      telegramBotTokenStatus !== "error" &&
      telegramBotTokenStatus !== "loading"
    ) {
      dispatch(
        getTelegramBotToken(
          userData?.email,
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [dispatch, telegramBotToken, telegramBotTokenStatus, userData]);

  useEffect(() => {
    if (localStorage.getItem("auth") == "telegram") {
      const tg = window?.Telegram?.WebApp;
      tg?.setHeaderColor("#0C359E");
      const goBack = () => {
        router(-1);
      };
      tg.BackButton.show();
      tg.BackButton.onClick(goBack);
    }
  }, []);

  //State to handle showing rate modal
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [visitedPage, setVisitedPage] = useState("");
  return (
    <>
      <Routes>
        {/*MINI APP ROUTES*/}
        <Route path={"/mini-dashboard"} element={<MiniDashboard />} />
        <Route path={"/mini-ai-signals"} element={<MiniAISignals />} />
        <Route path={"/mini-create-bot"} element={<CreateBotProcess />} />
        <Route path={"/mini-all-bots"} element={<MiniAppAllBots />} />
        <Route path={"/mini-bots"} element={<MiniBots />} />
        {/*<Route  path={"/mini-pricing-plan/referrals"} element={<MiniReferralCodes/>}/>*/}
        <Route path={"/mini-pricing-plan"} element={<MiniPricingPlan />}>
          <Route path={":section"} element={<MiniPricingPlan />} />
        </Route>
        {/*MINI APP ROUTES*/}
        <Route path={"/refresh-token"} element={<TokenExpired />} />
        {/*TELEGRAM AUTHENTICATION*/}
        <Route
          path={"/telegram-authentication"}
          element={<TelegramAuthentication />}
        >
          {/*<Route path="login" element={<MiniAppLoginPage />} />*/}
        </Route>
        <Route path="/welcome" element={<WelcomePage />} />
        {/* EXCHANGES ROUTE */}
        <Route path="/integrate-exchange/:id" element={<ConnectExchange />} />
        <Route path="/integration/:section" element={<Integration />} />
        <Route path="/integration" element={<Integration />} />
        {/* EXPIRATION ROUTE */}
        <Route path="/expired-account" element={<ExpiredPage />} />
        <Route path="/start-wizard" element={<StartWizard />} />
        {/*dashboard*/}
        <Route path="/dashboard" element={<DashboardIndex />} />
        {/*<Route path="/fastdashboard" element={<DashboardIndex />} />*/}
        {/*insight*/}
        <Route
          path="/Insight"
          element={
            <FinancialChartMultipleData
              showRatingModal={setShowRatingModal}
              setVisitedPage={setVisitedPage}
            />
          }
        />

        <Route
          path="/Insightv2"
          element={
            <NewInsight
              showRatingModal={setShowRatingModal}
              setVisitedPage={setVisitedPage}
            />
          }
        />

        {/*BOT SECTION*/}
        <Route path={"/bot-automation"} element={<BotAndAutomation />} />
        <Route
          path={"/bot-automation/:section"}
          element={<BotAndAutomation />}
        />
        <Route
          path={"/bot-automation/bot-performance/report"}
          element={<BotPerformanceReport />}
        />
        <Route
          path={"/bot-automation/bot-performance/report/:id"}
          element={<BotPerformanceReport />}
        />
        <Route
          path={"/bot-automation/backtest/report"}
          element={<BacktestReport />}
        />
        <Route
          path={"/bot-automation/backtest/report/:id"}
          element={<BacktestReport />}
        />
        {/*{expirationDate ? (*/}
        {/*  <Route*/}
        {/*    path="/Insight"*/}
        {/*    element={*/}
        {/*      expirationDate.getTime() <= new Date().getTime() ? (*/}
        {/*        <Navigate to="/expired-account" />*/}
        {/*      ) : (*/}
        {/*        <FinancialChartMultipleData*/}
        {/*          showRatingModal={setShowRatingModal}*/}
        {/*          setVisitedPage={setVisitedPage}*/}
        {/*        />*/}
        {/*      )*/}
        {/*    }*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  ""*/}
        {/*)}*/}

        {/*new pricing plan*/}
        {/*<Route path={"/pricing-billing/:section"} element={<PricingBillingIndex/>}/>*/}
        <Route path={"/pricing-billing"} element={<PricingBillingIndex />}>
          <Route path=":section" element={<PricingBillingIndex />} />
        </Route>

        {/*pricing-plan*/}
        {/*<Route path="/pricing-billing/:section" element={<PricingPlan />} />*/}
        {/*<Route path="/pricing-billing" element={<PricingPlan />} />*/}
        {/*<Route path="/pricing-billing/plans" element={<Plans />} />*/}
        {/*developer-api*/}
        <Route path="/developer-api" element={<DeveloperApi />} />
        {/*trade assistant*/}

        <Route
          path={"/ai-assistant"}
          element={
            <TradeAssistant
              showRatingModal={setShowRatingModal}
              setVisitedPage={setVisitedPage}
            />
          }
        />
        <Route
          path="/ai-assistant/:section"
          element={
            <TradeAssistant
              showRatingModal={setShowRatingModal}
              setVisitedPage={setVisitedPage}
            />
          }
        />

        {/*trade simulator*/}
        {/* <Route path="/trade-simulator" element={<TradeSimulator />} /> */}
        <Route path="/trade-simulator" element={<ComingSoon />} />
        {/*alert system*/}
        {/*<Route path="/alert-system" element={<AlertSystem />} />*/}
        <Route path="/alert-system/:section" element={<AlertSystem />} />
        <Route path="/alert-system" element={<AlertSystem />} />
        {/*<Route path="/alert-system-test" element={<AlertSystemTest />} />*/}
        <Route
          path="/alert-system/previous-email/:id"
          element={<PreviousEmail />}
        />
        {/*help & feedback*/}
        <Route path="/faq/:section" element={<Help />} />
        {/*profile page*/}
        <Route path="/profile/:section" element={<Profile />} />

        {/*auth*/}
        <Route
          path="/auth/signup/:code"
          element={<SignUpPage emailHandler={handleRegisteredEmail} />}
        />
        <Route
          path="/auth/signup"
          element={<SignUpPage emailHandler={handleRegisteredEmail} />}
        />
        <Route path="/auth/login" element={<LoginPage />} />
        <Route path="/auth/*" element={<LoginPage />} />
        <Route
          path="/auth/confirm"
          element={<Confirm registeredEmail={registeredEmail} />}
        />
        <Route path="/auth/forgotPassword" element={<ForgotPassword />} />
        <Route path="/" element={<Navigate replace to="/auth/login" />} />
      </Routes>
      {!authPath && (
        <RatingModal
          show={showRatingModal}
          handleShow={setShowRatingModal}
          page={visitedPage}
        />
      )}
      <ToastContainer position="bottom-center" />
      {/* <Navbar /> */}
    </>
  );
}

export default App;
