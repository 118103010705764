import {combineReducers} from "redux";
import main from "./main/reducer/main";
import {getDashboardAllDataReducer} from "./dashboard/reducer";
import {insightReducer} from "./insight/reducer";
import {
    generateApiKeyReducer,
    getDeveloperApiDataReducer,
} from "./developer-api/reducer";
import {tradingAssistantReducer} from "./trading-assistant/reducer";
import {pricingPlanReducer} from "./pricing-plan/reducer";
import {alertSystemReducer} from "./alert-system/reducer";
import {profileDateReducer} from "./profile/reducer";
import {tradeSimulatorReducer} from "./trade-simulator/reducer";
import {exchangeReducer} from "./exchange/reducer";
import {tradeReducer} from "./trade/reducer";
import {botReducer} from "./bot/reducer";
import {newInsightReducer} from "./new-insight/reducer";
import {minimalAppReducer} from "./minimal-app/reducer";

const rootReducer = combineReducers({
    main,
    dashboardData: getDashboardAllDataReducer,
    insightState: insightReducer,
    developerApi: getDeveloperApiDataReducer,
    generateApiKey: generateApiKeyReducer,
    tradingAssistant: tradingAssistantReducer,
    pricingPlan: pricingPlanReducer,
    alertSystem: alertSystemReducer,
    profile: profileDateReducer,
    tradeSimulator: tradeSimulatorReducer,
    exchangeState: exchangeReducer,
    tradeState: tradeReducer,
    botState: botReducer,
    newInsightState: newInsightReducer,
    minimalAppState: minimalAppReducer
});

export default rootReducer;
